import Axios from 'axios';
const IS_DEV = false;
import store from '../store/index';
import { setLang, setLocaleMessages } from './i18n-setup';
import * as mutations from '../store/mutation-types';
const formatResponse = (res) => res.data;
Axios.interceptors.response.use(formatResponse);

let fbId = undefined;
const _init = async (...args) => {
  const action = args.find((arg) => typeof arg === 'string');
  const params = args.find((arg) => typeof arg === 'object');
  const { bot_api, i18n, story_id, rgba, inline, userData, host, landing } = params;
  let { target_div } = params;
  window.bcw_host = (host === undefined) ? process.env.VUE_APP_API_ENDPOINT : host;
  store.commit(mutations.SET_BOT_KEY, bot_api);
  if (inline == 'true' || inline === true) {
    _setupStylesheet();
    target_div = window.document.getElementById(target_div) ? target_div : 'botsify-embed-div';
    store.commit(mutations.SET_INLINE_TARGET, target_div);
    if(story_id && story_id !== '') {
      store.commit(mutations.SET_STORY_ID, story_id);
    }
    
    if(rgba) {
      store.commit(mutations.SET_BOT_COLOR, decodeURI(rgba));
    }
    window.document.getElementById(target_div).style.position = 'relative';
    window.document.getElementById(target_div).style.backgroundColor = decodeURI(rgba);
    
    if(window.document.getElementById(target_div).offsetHeight == 0) {
      window.document.getElementById(target_div).style.height = '100vh';
    }
    if(window.document.getElementById(target_div).offsetWidth == 0) {
      window.document.getElementById(target_div).style.width = '100vw';
    }
    const widget = document.createElement('bcw-widget');
    window.document.getElementById(target_div).appendChild(widget);
  } else {
    const existingWidgets = window.document.getElementsByTagName('bcw-widget');
    if (!existingWidgets.length) {
      _setupWidget();
      _setupStylesheet();
    }
  }
  
  if(userData) {
    fbId = (userData.uid) ? userData.uid : undefined;
  } else if(!bcw_widget.bcwlocalstore.get('fbId')){
    fbId = makefbId();
    bcw_widget.bcwlocalstore.set('fbId', fbId);
  } else {
    fbId = bcw_widget.bcwlocalstore.get('fbId');
  }
  store.commit('SET_NEW_FB_ID', fbId);
  const botConfigs = await store.dispatch('FETCH_BOT_CONFIG', landing);

  if(userData) {
    let payload = {
      fields : {
        name: (userData.user_name) ? userData.user_name : undefined,
        email: (userData.user_email) ? userData.user_email : undefined
      },
      data: (userData.data && userData.data.length) ? userData.data : []
    };
    await store.dispatch('SET_EMBED_USER_PARAMS', payload);    
  }

  if (i18n) {
    if (i18n.messages) {
      Object.keys(i18n.messages).forEach((lang) => {
        setLocaleMessages(lang, i18n.messages[lang]);
      });
    }
    if (i18n.lang) {
      setLang(i18n.lang);
    }
  } else if(userData && userData.language) {
    setLang(userData.language);
  } else if (botConfigs.configs['website-chatbot-preferred-language']) {
    setLang(botConfigs.configs['website-chatbot-preferred-language']);
  } else {
    setLang('english');
  }


  
}


const BCWSound = (source, volume, loop) => {

  var soundFile = document.createElement("audio");
  soundFile.preload = "auto";

  //Load the sound file (using a source element for expandability)
  var src = document.createElement("source");
  src.src = source;
  soundFile.appendChild(src);

  //Load the audio tag
  //It auto plays as a fallback
  soundFile.load();
  soundFile.volume = 1.000000;
  soundFile.play().catch((e)=>{
    console.log("Cant play popup sound");
  });

  //Plays the sound
  function play() {
    //Set the current time for the audio file to the beginning
    soundFile.currentTime = 0.01;
    soundFile.volume = volume;

    //Due to a bug in Firefox, the audio needs to be played after a delay
    setTimeout(function(){
      try{
        soundFile.play().catch((e)=>{
          console.log("Cant play popup sound");
        });
      }catch(e) {}
    },1);
  }
}

const makefbId = () => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 13; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

const _setupWidget = () => {
  const widget = document.createElement('bcw-widget');
  window.document.body.appendChild(widget);
};

const _setupStylesheet = () => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = 'https://fonts.googleapis.com/css2?family=Open+Sans&family=Manrope&family=Ubuntu&family=Roboto';
  window.document.head.appendChild(link);
};



const _handleAction = (action) => {
  if (action === 'show') {
    store.commit(mutations.CHATBOT_LOADED, true);
  }

  if (action === 'hide') {
    store.commit(mutations.CHATBOT_LOADED, false);
  }
}


const bcwlocalstore = {
  localStoreSupport: function() {
      try {
          return 'localStorage' in window && window['localStorage'] !== null;
      } catch (e) {
          return false;
      }
  },
  set: function(name,value,days) {
      if (days) {
          var date = new Date();
          date.setTime(date.getTime()+(days*24*60*60*1000));
          var expires = "; expires="+date.toGMTString();
      }
      else {
          var expires = "";
      }
      if( this.localStoreSupport() ) {
          localStorage.setItem(name, value);
      }
      else {
          document.cookie = name+"="+value+expires+"; path=/";
      }
  },
  get: function(name) {
      if( this.localStoreSupport() ) {
          var ret = localStorage.getItem(name);
          switch (ret) {
            case 'true': 
                return true;
            case 'false':
                return false;
            default:
                return ret;
          }
      }
      else {
          // cookie fallback
          /*
           * after adding a cookie like
           * >> document.cookie = "bar=test; expires=Thu, 14 Jun 2018 13:05:38 GMT; path=/"
           * the value of document.cookie may look like
           * >> "foo=value; bar=test"
           */
          var nameEQ = name + "=";  // what we are looking for
          var ca = document.cookie.split(';');  // split into separate cookies
          for(var i=0;i < ca.length;i++) {
              var c = ca[i];  // the current cookie
              while (c.charAt(0)==' ') c = c.substring(1,c.length);  // remove leading spaces
              if (c.indexOf(nameEQ) == 0) {  // if it is the searched cookie
                  var ret = c.substring(nameEQ.length,c.length);
                  // making "true" and "false" a boolean again.
                  switch (ret) {
                    case 'true':
                        return true;
                    case 'false':
                        return false;
                    default:
                        return ret;
                  }
              }
          }
          return null; // no cookie found
      }
  },
  del: function(name) {
      if( this.localStoreSupport() ) {
          localStorage.removeItem(name);
      }
      else {
          this.set(name,"",-1);
      }
  }
}


export default () => {
  window.bcw_widget = window.bcw_widget || {};
  window.bcw_widget.init = _init;
  window.bcw_widget.bcwlocalstore = bcwlocalstore;
  window.bcw_widget.BCWSound = BCWSound;
  window.bcw_widget.show = () => _handleAction('show');
  window.bcw_widget.hide = () => _handleAction('hide');
};

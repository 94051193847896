<template>
    <div class="h-full overflow-y-scroll	" :class="{ 'rounded-md': roundedDisabler }">
      <div class="relative z-10">
          <div class="block p-3 m-3 bg-white shadow-md max-w-screen" :class="{ 'rounded-3xl': roundedDisabler }">
            <form class="grid grid-flow-row" id="wpic-stripe-dynamic-form">
              <h3 class="text-l block text-left text-gray-900">{{message.attachment.payload.data.name}}</h3>
              <div class="mt-3">
                <div class="grid grid-cols-2 gap-2 bcw-stripe-subtype">
                    <div >
                        <input v-model="stripeFormData.subType" type="radio" checked="checked" class="hidden" id="bcw-stripe-one-time" name="bcw-stripe-dynamic-subtype" value="1" />
                        <label :style="[(stripeFormData.subType == 1)? {background: botConfigs.configs['website-chatbot-secondary-color'], color:'white'}: {background:'#ccc'}]" for="bcw-stripe-one-time" class="text-center w-full block p-1 rounded-sm cursor-pointer z-90">One-time</label>
                    </div>
                    <div>
                        <input  v-model="stripeFormData.subType" id="bcw-stripe-recurring" class="hidden" type="radio" name="bcw-stripe-dynamic-subtype" value="2" />
                        <label :style="[(stripeFormData.subType == 2)? {background: botConfigs.configs['website-chatbot-secondary-color'], color:'white'}: {background:'#ccc'}]" for="bcw-stripe-recurring"  class="text-center  w-full block  p-1 rounded-sm cursor-pointer z-90">Recurring</label>
                    </div>
                </div>
              </div>
              <div class="mt-2">
                <label class="block">Amount ({{message.attachment.payload.data.currency?.symbol}})</label>
                <div class="grid grid-cols-4 gap-4">
                    <div v-for="amount_suggestion in message.attachment.payload.data.amount_suggestions.split(',')" :style="[(stripeFormData.invoice_amount == amount_suggestion)? {background: botConfigs.configs['website-chatbot-secondary-color'], color:'white'}: {background:'#ccc'}]" class="text-center p-1 rounded cursor-pointer" @click="stripeFormData.invoice_amount = Number(amount_suggestion)">{{message.attachment.payload.data.currency?.symbol}}{{amount_suggestion}}</div>
                </div>
              </div>
              <input type="number"  v-model="stripeFormData.invoice_amount" class="px-2 my-1 bg-gray-100 border-none h-9 focus:outline-none"
                :class="{ 'rounded-md': roundedDisabler }" :placeholder="$t('Other amount')" name="bcw-stripe-dynamic-amount" />
              <div class="mt-1 block" v-if="stripeFormData.subType == 2">
                <label class="block">Frequency</label>
                <select  v-model="stripeFormData.frequency" class="block w-full px-2 my-1 bg-gray-100 border-none h-9 focus:outline-none"
                    :class="{ 'rounded-md': roundedDisabler }"   name="bcw-stripe-dynamic-frequency" >
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                </select>
              </div>
              <div class="mt-1 mb-1 block"  v-if="stripeFormData.subType == 2">
                <label>End date</label>
                <div class="block w-full text-gray-600 text-white rounded">
                    <input v-model="stripeFormData.endDate"  :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-gray-300"  type="date"  :min="new Date().toISOString().substring(0,10)" />
                </div>
              </div>


                <label class="block">Name on Card</label> 
                <input  v-model="stripeFormData.name" name="bcw-stripe-dynamic-name" class="w-full h-9 border-0	mb-1 bg-gray-100"
                    :class="{ 'rounded-md': roundedDisabler }" type="text" placeholder="Name on Card"
                    :required="true"  />
                <label class="block">Email address</label>
                <input  v-model="stripeFormData.email" name="bcw-stripe-dynamic-email"
                    :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-0 mb-1 bg-gray-100"
                    type="email" :required="true" placeholder="Email Address for Invoice" />
                <label class="block">Phone #</label>
                <input  v-model="stripeFormData.phone" name="bcw-stripe-dynamic-phone" class="w-full h-9 border-0 mb-1 bg-gray-100"
                    :class="{ 'rounded-md': roundedDisabler }" type="text"  :placeholder="$t('phonePlaceholder')"
                    :required="true" />
              </form>
                <label class="block">Card Details</label>
                <stripe-element-card
                    ref="stripeElemRef"
                    :hidePostalCode="true"
                    :pk="botConfigs.configs['stripe_connection']"
                    @token="stripeTokenCreated"
                    @error="onStripeError"
                />
                <div class="grid grid-cols-2 gap-2">
                    <img class="w-full" :src="AWS_URL + '/secured_stripe.png'" />
                </div>
                <span class=" inline-field-error" style="color:red;" v-if="formErrorMsg" v-text="formErrorMsg"></span>
                <div class="block py-2 my-2 px-1 text-center mb-1 text-white cursor-pointer  text-white"
                :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }">
                    <div class="block">
                        <button :disabled="beingSubmitted" class="block w-full text-center"
                            @click.prevent="beingSubmitted = true; $refs.stripeElemRef.submit();">
                            <span v-if="stripeFormData.subType == 1" v-text="'Pay '+message.attachment.payload.data.currency?.symbol + stripeFormData.invoice_amount"></span>
                            <span v-else-if="stripeFormData.subType == 2" v-text="'Pay '+message.attachment.payload.data.currency?.symbol+stripeFormData.invoice_amount + ' ' +stripeFormData.frequency"></span>
                        </button>
                    </div>
                </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  const AWS_URL = process.env.VUE_APP_S3_ENDPOINT;
  import { mapActions, mapGetters } from 'vuex';
  import { StripeElementCard } from '@vue-stripe/vue-stripe';
  import * as mutations from '@/store/mutation-types';
  export default {
    components: {
        StripeElementCard
    },
    props:['message'],
    data() {
      return {
        AWS_URL,
        stripeFormData: {
            subType:1,
            invoice_amount:10,
            frequency:'monthly',
            endDate:'',
            email:'',
            name:'',
            phone:''
        },
        beingSubmitted: false,
      }
    },
    computed: {
      ...mapGetters(['botConfigs', 'formErrorMsg'])
    },
    // mounted() {
    //   this.$store.subscribe((mutation) => {
    //     if (mutation.type === 'SET_SHOWING_WINDOW') {
    //       if (mutation.payload === true && this.botConfigs.configs['icon-as-get-started'] && this.botConfigs.configs['icon-as-get-started'] === '1') this.GETSTARTED();
    //     }
    //   })
    // },
    methods: {
        ...mapActions(["FETCH_BOT_SELECTION", "CREATE_USER", "STRIPE_CHARGE"]),
        async stripeTokenCreated(token) {
            this.beingSubmitted = true;
            this.$store.commit(mutations.SET_FORM_ERROR_MSG, null);
            token = {...token, ...this.stripeFormData};
            await this.STRIPE_CHARGE({'token': { ...token, ...this.message.attachment.payload.data,'bot_name': this.botConfigs.configs['website-chatbot-bot-name'], 'stripe_hook': this.botConfigs.configs['stripe_webhook']??'', 'stripe_connection': this.botConfigs.configs['stripe_connection']??''} }, 'stripe-dynamic-charge');
            this.beingSubmitted = false;
        },
        onStripeError() {
          this.beingSubmitted = false;
        }
    }
}
  </script>
  <style scoped>
  .bcw5611 h5,.bcw5611 h6, .bcw5611 h4, .bcw5611 h3, .bcw5611 h2, .bcw5611 h1, .bcw5611 li,.bcw5611  a{
    font-family: 'Open Sans' !important;
  }
.bcw5611 label {
  font-size:12px;
}
  </style>
  
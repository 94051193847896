import axios from 'axios';
require('dotenv').config();

//const ROUTE = window.bcw_host;
const internalAPI = process.env.VUE_APP_CHAT_INTERNAL_API

axios.defaults.headers.common['Authorization'] = `Bearer ${internalAPI}`;


export default {
  async getBotConfig(bot_key, fbId, load_messages) {
    try {
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse/get-bot-details`, {
        apikey:
        bot_key,
        load_messages,
        fbId,
        bot_key,
        source: window.location.href,
      })
      // const resp = await axios.get(`${window.bcw_host}/api/v1/converse/get-bot-details?apikey=${bot_key}`);
      return resp;
    } catch (e) {
      console.error("Botsify failed to load configuration", e);
    }
  },
  async getInit(bot_key, fbId) {
    try {
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse/get-bot-details`, {
        apikey:
        bot_key,
        fbId,
        bot_key,
        source: window.location.href,
      })
      return resp
    } catch (error) {
      console.error("Botsify failed to get messages", error)
    }
  },
  async stripeCharge(payload, bot_key, fbId, dynamic) {
    try {
      let req_url = `${window.bcw_host}/api/v1/converse/${dynamic}`;
      if(payload.token.stripe_hook !== null && payload.token.stripe_hook !== '') {
        req_url = payload.token.stripe_hook;
      }
      const resp = await axios.post(req_url, {
        apikey:
        bot_key,
        fbId,
        bot_key,
        source: window.location.href,
        ...payload
      })
      return resp
    } catch (error) {
      console.error("Botsify failed to get messages", error)
    }
  },
  async sendMessage(payload, bot_key, fbId) {
    try {
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse`, { type: "message", fbId, bot_key, ...payload });
      return resp;
    } catch (e) {
      console.error("Failed to send message", e);
    }
  },

  async sendCsatFeedback(payload, bot_key, fbId) {
    try {
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse/store-csat-response`, { fbId, bot_key, ...payload });
      return resp;
    } catch (e) {
      console.error("Failed to submit feedback", e);
    }
  },
  async pageMessageClosed(payload, bot_key, fbId) {
    try {
      const resp = await axios.post(`${window.bcw_host}/api/v1/store-webbot-page_message-sent`, { apikey:
        bot_key, fbId, bot_key, ...payload });
      return resp;
    } catch (e) {
      console.error("Failed to send message", e);
    }
  },
  async fileUpload(payload, bot_key, fbId) {
    try {
      payload.append('fbId', fbId);
      payload.append('bot_key', bot_key);
      payload.append('type', 'attachment');
      const resp = axios.post(`${window.bcw_host}/api/v1/converse`, payload, {
        onUploadProgress: function (progressEvent) {
          document.querySelector('#upload-progress-bar > div').style.width = (Math.round((progressEvent.loaded / progressEvent.total) * 100)) + "%";
        }.bind(this)
      });
      return resp;
    } catch (e) {
      console.error("Failed to send message", e);
    }
  },
  async inlineFormUpload(payload, bot_key, fbId) {
    try {
      payload.append('fbId', fbId);
      payload.append('bot_key', bot_key);
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse/full-form-data`, payload).catch((err) => {
        return err.response.data;
      });
      return resp;
    } catch (e) {
      console.error("Failed to send message", e);
    }
  },
  async sendPostback(payload, bot_key, fbId) {
    try {
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse`, { type: "postback", fbId, bot_key, ...payload });
      return resp;
    } catch (e) {
      console.error("Failed to send postback message", e);
    }
  },
  async deleteConversation(bot_key, fbId) {
    try {
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse/delete-user-conversation`, { fbId, bot_key });
      return resp;
    } catch (error) {
      console.error("Failed to delete conversation", error);
    }
  },
  async createMessengerUser(bot_key, fbId, payload) {
    try {
      const d = new Date();
      const localTimeZone = (d.getTimezoneOffset() * -1) / 60;
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse/create-messenger-user`, { fields: payload, 'timezone': localTimeZone, 'update': 'true', 'send_lead_mail': 'true', fbId, bot_key });
      return resp;
    } catch (error) {
      console.error("Failed to delete conversation", error);
    }
  },
  async setParamsCreateMessengerUser(bot_key, fbId, payload) {
    try {
      const d = new Date();
      const localTimeZone = (d.getTimezoneOffset() * -1) / 60;
      const resp = await axios.post(`${window.bcw_host}/api/v1/converse/create-messenger-user`, { ...payload, 'timezone': localTimeZone, 'update': 'true', bot_key, fbId });
      return resp;
    } catch (error) {
      console.error("Failed to delete conversation", error);
    }
  },
  async updateMessengerUser(bot_key, fbId, payload) {
    try {
      const d = new Date();
      const localTimeZone = (d.getTimezoneOffset() * -1) / 60;
      const resp = await axios.post(`${window.bcw_host}/api/v1/update-messenger-user`, { ...payload, 'timezone': localTimeZone, bot_key, fbId });
      return resp;
    } catch (error) {
      console.error("Failed to delete conversation", error);
    }
  },
  async requestHumanHelp(bot_key, fbId, payload) {
    try {
      const d = new Date();
      const localTimeZone = (d.getTimezoneOffset() * -1) / 60;
      const resp = await axios.post(`${window.bcw_host}/api/v1/actions/human-help`, { ...payload, bot_key, fbId });
      return resp;
    } catch (error) {
      console.error("Failed to delete conversation", error);
    }
  }
  

};

/* eslint-disable indent */
import * as mutations from './mutation-types';
import * as actions from './action-types';
import BotService from '../api/bot.service';
import Color from "color"
import axios from "axios"
import { i18n } from '@/helpers/i18n-setup';

function matchItem(string, data) {
  var i = 0,
    j = 0,
    html = '',
    regex,
    regexv,
    match,
    matches,
    version;

  for (i = 0; i < data.length; i += 1) {
    regex = new RegExp(data[i].value, 'i');
    match = regex.test(string);
    if (match) {
      regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
      matches = string.match(regexv);
      version = '';
      if (matches) { if (matches[1]) { matches = matches[1]; } }
      if (matches) {
        matches = matches.split(/[._]+/);
        for (j = 0; j < matches.length; j += 1) {
          if (j === 0) {
            version += matches[j] + '.';
          } else {
            version += matches[j];
          }
        }
      } else {
        version = '0';
      }
      return {
        name: data[i].name,
        version: parseFloat(version)
      };
    }
  }
  return { name: 'unknown', version: 0 };
}

const browser_details = async ({ commit, dispatch, state }) => {
  var header = [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera];
  var dataos = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ];
  var databrowser = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ];
  var agent = header.join(' '),
    os = matchItem(agent, dataos),
    browser = matchItem(agent, databrowser);
  return {'os':os.name + " " + os.version, 'browser':browser.name + " " + browser.version};
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const typingDelay = async ({ commit }, payload) => {
  commit(mutations.SET_BOT_TYPING, true);
  await timeout(payload*1000);
  commit(mutations.SET_BOT_TYPING, false);
}

const sendMessage = async ({ state, commit, dispatch }, payload) => {
  commit(mutations.SET_BOT_TYPING, true);
  dispatch('SCROLLDOWN', 'smooth');
  const platform_info = await dispatch('BROWSERDETAILS');
  payload.os = platform_info.os;
  payload.browser = platform_info.browser;
  payload.url = window.location.href;

  const reply = await BotService.sendMessage(payload, state.bot_key, state.fbId);
  commit(mutations.SET_BOT_TYPING, false);
  if (reply.messages) {
    for(let i = 0;i < reply.messages.length ; i++) {
      if(reply.messages[i].message?.send_payload?.sender_action === 'typing_on') {
        await dispatch('TYPING_DELAY', parseInt(reply.messages[i].message.wait_time));
      } else if(reply.messages[i].message) {
        commit(mutations.SET_MESSAGES, [reply.messages[i]]);
      }
      dispatch('SCROLLDOWN', 'smooth');
    }
  }
  dispatch('SCROLLDOWN', 'smooth');
}


const pageMessageClosed = async ({ state }, payload) => {
  const response = await BotService.pageMessageClosed(payload, state.bot_key, state.fbId);
  console.log("Response", response);
}


const sendPostback = async ({ state, commit, dispatch }, payload) => {
  commit(mutations.SET_BOT_TYPING, true);
  dispatch('SCROLLDOWN', 'smooth');
  const platform_info = await dispatch('BROWSERDETAILS');
  payload.os = platform_info.os;
  payload.browser = platform_info.browser;
  payload.url = window.location.href;
  const reply = await BotService.sendPostback(payload, state.bot_key, state.fbId);
  commit(mutations.SET_BOT_TYPING, false);
  for(let i = 0;i < reply.messages.length ; i++) {
    if(reply.messages[i].message?.send_payload?.sender_action === 'typing_on') {
      await dispatch('TYPING_DELAY', parseInt(reply.messages[i].message.wait_time));
    } else if(reply.messages[i].message) {
      commit(mutations.SET_MESSAGES, [reply.messages[i]]);
    }
    dispatch('SCROLLDOWN', 'smooth');
  }
  dispatch('SCROLLDOWN', 'smooth');
}

const setSenderMessage = ({ commit, dispatch }, message) => {
  commit(mutations.SET_MESSAGES, [{ created_at: new Date(), deleted: 0, direction: "from", message: { text: message } }]);
  dispatch('SCROLLDOWN', 'smooth');
}

const botSelection = ({ commit, dispatch }, selection) => {
  commit(mutations.SET_BOT_SELECTION, selection);
  dispatch('SCROLLDOWN', 'auto');
}

const deleteConversation = async ({ state, commit, dispatch }) => {
  const response = await BotService.deleteConversation(state.bot_key, state.fbId);
  if (response.status === 'success') {
    await commit(mutations.DELETE_CONVERSATION);
    await commit(mutations.UPDATE_SHOW_MENU, false);
    dispatch('FETCH_BOT_SELECTION', 0);
  }
}

const createMessengerUser = async ({ state, commit, dispatch }, payload) => {
  const response = await BotService.createMessengerUser(state.bot_key, state.fbId, payload);
  if (response.status === 'success') {
    commit(mutations.UPDATE_BOT_CONFIG, { 'messengerUser': response.messengerUser });
    dispatch('GETSTARTED');
  }
  return response;
}

const setParamsCreateMessengerUser = async ({ state, commit, dispatch }, payload) => {
  const response = await BotService.setParamsCreateMessengerUser(state.bot_key, state.fbId, payload);
  if (response.status === 'success') {
    commit(mutations.UPDATE_BOT_CONFIG, { 'messengerUser': response.messengerUser });
    // dispatch('GETSTARTED');
  }
  return response;
}

const updateMessengerUser = async ({ state, commit, dispatch }, payload) => {
  document.getElementById("human-help-form-submit").disabled = true;
  const response = await BotService.updateMessengerUser(state.bot_key, state.fbId, payload);
  if (response.status === 'success') {
    // commit(mutations.UPDATE_BOT_CONFIG, { 'messengerUser': response.messengerUser });
    payload.return_user = true;
    payload.send_lead_mail = true;
    dispatch('REQUEST_HUMAN_HELP', payload);
  }
  document.getElementById("human-help-form-submit").disabled = false;
  return response;
}

const requestHumanHelp = async ({ state, commit, dispatch }, payload) => {
  const response = await BotService.requestHumanHelp(state.bot_key, state.fbId, payload);
  commit(mutations.SET_MESSAGES, [{ message: response }]);
  return response;
}


const getInitMessages = async ({ state, commit }) => {
  let botConfig = await BotService.getInit(state.bot_key, state.fbId);
  if(botConfig.messages.length) {
    commit(mutations.SET_MESSAGES, botConfig.messages);
  }
  commit(mutations.UPDATE_BOT_CONFIG, { 'messengerUser': botConfig.messengerUser, 'messages': botConfig.messages });
  return botConfig;
}

const fetchBotConfig = async ({ state, dispatch }, landing) => {
  let botConfig = {};
  if(state.target_div !== undefined) {
    botConfig = await BotService.getBotConfig(state.bot_key, state.fbId, true);
  } else {
    botConfig = await BotService.getBotConfig(state.bot_key, state.fbId, landing==true);
  }
  if(botConfig.bot && (botConfig.bot.status == 0 || botConfig.bot.active == 0 )) {
    console.log("Chatbot is turned off");
    if((botConfig.configs['hide_chatbot'] == 1 && botConfig.bot.disable_reason == 'offline hours' )){
      return botConfig;
    }else if(state.fbId !== 'testUser' && botConfig.bot.disable_reason != 'offline hours') {
      return botConfig;
    }
  }
  if(botConfig.configs.length && botConfig.configs['excluded-ips'] && botConfig.configs['excluded-ips'].length) {
    try {
      const resp = await axios.get('https://ipinfo.io/json?token=f6c4c5be440f1a');
      if(botConfig.configs['excluded-ips'].includes(resp.ip)) {
        console.log("Chatbot is disabled on this IP address", resp.ip);
        
      } else {
        botConfig = await dispatch('LOAD_WIDGET', { botConfig, landing });
      }
    } catch (error) {
      console.log(error)
    }
  } else {
    botConfig = await dispatch('LOAD_WIDGET', { botConfig, landing });
  }
  return botConfig;
}


const loadWidget = async ({state, commit, dispatch, getters}, { botConfig, landing }) => {
  botConfig.configs['website-chatbot-bot-name'] = (botConfig.configs && botConfig.configs['website-chatbot-bot-name']) ? botConfig.configs['website-chatbot-bot-name'] : 'Jarvis';
  botConfig.configs['website-chatbot-default-launcher'] = (botConfig.configs && botConfig.configs['website-chatbot-default-launcher']) ? botConfig.configs['website-chatbot-default-launcher'] : '1';
  botConfig.configs['website-chatbot-bot-image'] = (botConfig.configs && botConfig.configs['website-chatbot-bot-image']) ? botConfig.configs['website-chatbot-bot-image'] : 'https://livechat.osam.one/theme/images/jarvis_logo.png';
  botConfig.configs['website-chatbot-icon-type'] = (botConfig.configs && botConfig.configs['website-chatbot-icon-type']) ? botConfig.configs['website-chatbot-icon-type'] : 'default';
  botConfig.configs["website-chatbot-primary-color"] = (botConfig.configs && botConfig.configs['website-chatbot-primary-color']) ? botConfig.configs['website-chatbot-primary-color'] : '#1F8CEB';
  botConfig.configs["website-chatbot-secondary-color"] = (botConfig.configs && botConfig.configs['website-chatbot-secondary-color']) ? botConfig.configs['website-chatbot-secondary-color'] : '#999999';
  
  const accentColor = Color(botConfig.configs["website-chatbot-primary-color"])
  const secondaryAccentColor = Color(botConfig.configs["website-chatbot-secondary-color"])
  const lightness = accentColor.lightness();
  botConfig.configs["website-chatbot-accent-color"] = accentColor.isLight() ? accentColor.hsl().lightness(lightness - 25).hex() : accentColor.hsl().lightness(lightness + 25).saturate(0.2).hex()
  botConfig.configs["website-chatbot-primary-color-scheme"] = accentColor.isLight()
  botConfig.configs["website-chatbot-secondary-color-scheme"] = secondaryAccentColor.isLight()
  if(botConfig.configs['website-chatbot-header-solid'] == '1') {
    botConfig.configs["website-chatbot-gradient"] = botConfig.configs["website-chatbot-primary-color"];
    botConfig.configs["website-chatbot-gradient-reverse"] = botConfig.configs["website-chatbot-accent-color"];
  } else{
    botConfig.configs["website-chatbot-gradient"] = `transparent linear-gradient(180deg, ${botConfig.configs["website-chatbot-primary-color"]} 0%, ${botConfig.configs["website-chatbot-accent-color"]} 100%) 0% 0% no-repeat padding-box`
    botConfig.configs["website-chatbot-gradient-reverse"] = `transparent linear-gradient(180deg, ${botConfig.configs["website-chatbot-accent-color"]} 0%, ${botConfig.configs["website-chatbot-primary-color"]} 100%) 0% 0% no-repeat padding-box`
  }
  if(landing == 'true' || landing === true) {
    botConfig.configs["website-chatbot-landing"] = '1';
  }
  commit(mutations.SET_BOT_CONFIG, botConfig);
  commit(mutations.CHATBOT_LOADED, true);
  if(botConfig.messages.length) {
    commit(mutations.SET_MESSAGES, botConfig.messages);
  }
  commit(mutations.UPDATE_BOT_CONFIG, { 'messengerUser': botConfig.messengerUser, 'messages': botConfig.messages });
  dispatch('SCROLLDOWN', 'smooth');
  if (getters.inlineTarget && botConfig.messages.length === 0 ) {
    await dispatch('SEND_POSTBACK', { 'payload': (state.story_id)?state.story_id:state.botConfigs.getStarted, 'text': i18n.t('startConversation') });
  }
  return botConfig;
}

const getStartedMessage = async ({ state, dispatch, commit }) => {
  dispatch('FETCH_BOT_SELECTION', 1);
  dispatch('SET_SENDER_MESSAGE', i18n.t('startConversation'));
  dispatch('SCROLLDOWN', 'smooth');
  await dispatch('SEND_POSTBACK', { 'payload': state.botConfigs.getStarted, 'text': i18n.t('startConversation'), 'is_getStarted': true });
}

const getLanguage = ({ state, getters }, lang = 'english', message) => {
  if (!Object.keys(state.botConfigs).length && !Object.keys(state.localConfig).length && message) return;
  return getters.language_array[lang][message]
}

const scrollDown = ({ state, getters }, behavior) => {
  setTimeout(() => {
    const chatArea = document.getElementById('bcw-chat-area');
    if (chatArea) {
      chatArea.scroll({ top: chatArea.scrollHeight, behavior });
    }
  }, 100);
}


const fileUpload = async ({ state, commit, dispatch }, payload) => {
  dispatch('SCROLLDOWN', 'smooth');
  const reply = await BotService.fileUpload(payload, state.bot_key, state.fbId);
  commit(mutations.SET_MESSAGES, reply.messages);
  dispatch('SCROLLDOWN', 'smooth');
  return reply;
}

const inlineFormUpload = async ({ state, commit, dispatch }, payload) => {
  const ErrorElems = document.querySelectorAll('.inline-field-error');
  for (let i = 0; i < ErrorElems.length; i++) {
    ErrorElems[i].innerHTML = '';
  }
  dispatch('SCROLLDOWN', 'smooth');
  document.getElementById("inline-form-submit").disabled = true;
  const reply = await BotService.inlineFormUpload(payload, state.bot_key, state.fbId);
  document.getElementById("inline-form-submit").disabled = false;
  if (reply.message === 'remove_errors' && typeof reply.response !== 'string') {
    Object.keys(reply.response).forEach(key => {
      if(document.getElementById("inline-form").elements[`${key}`]) {
        document.getElementById("inline-form").elements[`${key}`].nextElementSibling.innerHTML = reply.response[key];
      } else if (key.includes("MultipleOptions")) {
        document.getElementById("inline-form").elements[`${key}[]`].nextElementSibling.innerHTML = reply.response[key];
      }
    });
  } else if (reply.message === 'remove_errors' && typeof reply.e === 'string') {
    document.querySelector(".inline-form-error").innerHTML = reply.e;
  } else if (reply.status === 'success') {
    if (reply.form_response && reply.form_response.length > 1) {
      // commit(mutations.SET_MESSAGES, reply.form_response)
      for(let x = 0; x < reply.form_response.length; x++) {
        commit(mutations.SET_MESSAGES, [{ created_at: new Date(), deleted: 0, direction: "to", message: reply.form_response[x] }]);
      }
    }
    else {
      commit(mutations.SET_MESSAGES, [{ created_at: new Date(), deleted: 0, direction: "to", message: { text: 'Form Submitted' } }]);
    }
  }
  dispatch('SCROLLDOWN', 'smooth');
  return reply;
}

const showingWindow = ({ commit }, payload) => {
  commit('SET_SHOWING_WINDOW', payload)
}

const stripeCharge = async ({ state, dispatch, commit }, payload) => {
  commit(mutations.SET_BOT_TYPING, true);
  payload.token.subType = (payload.token.charge_type === 'One Time') ? 1 : 2;
  payload.token.frequency = (payload.token?.charge_type && payload.token.charge_type !== 'One Time') ? payload.token.charge_type.toLowerCase() : payload.token.frequency;
  const dynamicUrl =  (payload.token?.dynamic_widget) ? 'stripe-dynamic-charge' : 'stripe-dynamic-charge';
  let chargeDetails = await BotService.stripeCharge(payload, state.bot_key, state.fbId, dynamicUrl);
  if(chargeDetails.status === 'failed') {
    commit(mutations.SET_BOT_TYPING, false);
    commit(mutations.SET_FORM_ERROR_MSG, chargeDetails.msg);
  } else {
    //Pushing GTM Data
    var stripePaymentData = {
        'event': "botstripecollection", // Event name
        'amount': payload.token.amount,
        'program': payload.token.description,
        'user': payload.token.email      
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(stripePaymentData);
    await dispatch('SEND_POSTBACK', chargeDetails);
  }
}

const updateCsat = async ({ state, commit, dispatch }, payload) => {
  commit(mutations.SET_CSAT, payload);
  if(payload) {
    commit(mutations.SET_BOT_SELECTION, 2);
  } else {
    commit(mutations.SET_BOT_SELECTION, 1);
    await dispatch('SCROLLDOWN', 'smooth');
  }
}

const submitFeedback = async ({ state, commit, dispatch }) => {
  await BotService.sendCsatFeedback(state.CsatForm, state.bot_key, state.fbId);
  commit(mutations.SET_CSAT_SUBMITTED, true);
  dispatch('SCROLLDOWN', 'smooth');
}


export default {
  [actions.FETCH_BOT_CONFIG]: fetchBotConfig,
  [actions.FETCH_BOT_SELECTION]: botSelection,
  [actions.FETCH_MESSAGE]: getLanguage,
  [actions.SEND_MESSAGE]: sendMessage,
  [actions.SET_SENDER_MESSAGE]: setSenderMessage,
  [actions.SEND_POSTBACK]: sendPostback,
  [actions.DELETE_CONVERSATION]: deleteConversation,
  [actions.FETCH_INIT_MESSAGES]: getInitMessages,
  [actions.CREATE_USER]: createMessengerUser,
  [actions.GETSTARTED]: getStartedMessage,
  [actions.SCROLLDOWN]: scrollDown,
  [actions.STRIPE_CHARGE]: stripeCharge,
  [actions.FILEUPLOAD]: fileUpload,
  ['BROWSERDETAILS']: browser_details,
  [actions.INLINE_FORM_UPLOAD]: inlineFormUpload,
  [actions.SHOWING_WINDOW]: showingWindow,
  [actions.SET_EMBED_USER_PARAMS]: setParamsCreateMessengerUser,
  [actions.UPDATE_MESSENGER_USER]: updateMessengerUser,
  [actions.REQUEST_HUMAN_HELP]: requestHumanHelp,
  [actions.TYPING_DELAY]: typingDelay,
  [actions.LOAD_WIDGET]: loadWidget,
  [actions.PAGE_MESSAGE_CLOSED]: pageMessageClosed,
  [actions.UPDATE_CSAT]: updateCsat,
  [actions.SUBMIT_CSAT_FEEDBACK]: submitFeedback
  
};

<template>
    <div class="bg-white">
        <div class="h-24 backgroundGradient" :style="{ background: botConfigs.configs['website-chatbot-gradient'] }">
            <div class="grid items-center justify-around grid-flow-col px-2 pt-8">
                <img :src="AWS_URL + '/Back.svg'" class="self-center col-span-1 cursor-pointer"
                    @click.prevent="FETCH_BOT_SELECTION(0)" />
                <div class="col-span-5 text-white justify-self-center">
                    FAQ's
                </div>
                <img :src="AWS_URL + '/Close.svg'" class="self-center col-span-1 pr-2 justify-self-end" />
            </div>
        </div>
        <div class="pl-2 overflow-y-auto faqHeight no-scrollbar">
            <div v-for="(faq, idx) in FAQs" :key="idx">
                <Card :FAQ="faq" />
            </div>
        </div>
        <div class="inline-flex px-2 mx-3 my-1 bg-gray-100 rounded-3xl" style="width: 90%;">
            <input type="text" class="w-full bg-transparent border-none h-9 focus:outline-none"
                :placeholder="$t('typeQuestion')" />
            <img class="self-center w-6 h-6" :src="AWS_URL + '/Send.svg'" />
        </div>
        <div class="h-10 backgroundGradientInverted"
            :style="{ background: botConfigs.configs['website-chatbot-gradient-reverse'] }">
        </div>
    </div>
</template>

<script>
const AWS_URL = process.env.VUE_APP_S3_ENDPOINT;
import { mapActions } from "vuex";
import Card from "./FAQ/Card.vue"

export default {
    components: {
        Card
    },
    methods: {
        ...mapActions(["FETCH_BOT_SELECTION"])
    },
    data() {
        return {
            AWS_URL,
            FAQs: [
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },
                {
                    title: "Hello world",
                    text: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                },

            ],
        }
    },
}
</script>
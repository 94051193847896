<template>
    <div class="fixed w-80 rounded-lg"
        :class="[ (botConfigs.configs['website-chatbot-move-left'] !== '1') ? 'right-4' : 'left-4' ]" @mouseover="showClose = true"
        @mouseleave="showClose = false"
        style="z-index:1000000; bottom:100px">
        <div v-if="showClose || isMobile" class="cursor-pointer flex mb-2 flex-row-reverse" @click="$emit('closePopup', {webbot_page_message_id : botConfigs.webbot_page_message.id})">
            <div class="px-5 py-1 shadow-xl rounded-xl bg-gray-400 text-white">{{$t('close')}} &#x2715</div>
        </div>
        <div class="bg-gray-100 rounded-lg shadow-xl">
            <div class="h-16 grid grid-flow-col justify-start rounded-lg"
                :style="{ background: botConfigs.configs['website-chatbot-gradient'] }">
                <div :style="'background-image: url(' + botConfigs.configs['website-chatbot-bot-image'] + ');'"
                            class="self-center object-contain h-9 w-9 rounded-full bg-cover bg-center ml-3" />
                <div class="self-center ml-3 text-white font-normal">{{botConfigs.configs['page-message-title'] || $t('messageFrom')}} <span
                        class="font-semibold">{{ botConfigs.configs['website-chatbot-bot-name'] }}</span></div>
            </div>
            <div class="mb-2 p-3 text-lg text-black" v-html="popUpMessage"></div>
        </div>
        <div v-if="popUpStory" class="grid grid-flow-col place-content-center gap-2 text-white" >
            <div class="shadow-xl rounded-lg px-5 py-1  cursor-pointer" :key="index" v-for="option,index in storyOptions" @click="sendQuickReplies(option)"
                :class="botConfigs.configs['website-chatbot-secondary-color-scheme'] ? ' text-black ' : ' text-white '"
                :style="{ background: botConfigs.configs['website-chatbot-secondary-color'] }">{{option}}</div>
        </div>
        <div class="p-5 bg-gray-100 mt-3 rounded-lg shadow-xl  cursor-pointer text-black" @click.prevent="clickedBot">{{$t('sendMessageDots')}}
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import { mapGetters } from 'vuex';
export default {
    props: {
        popUpMessage: {
            type: String,
            default: '',
            required: true,
        },
        popUpStory: {
            default: null,
            required: true
        }
    },
    data() {
        return {
            showClose: false
        }
    },
    computed: {
        ...mapGetters(['botConfigs', 'isMobile']),
        storyOptions() {
            return (this.popUpStory) ? JSON.parse(this.popUpStory.options) : [];
        },
    },
    methods: {
        ...mapActions(["FETCH_BOT_SELECTION", "SET_SENDER_MESSAGE", "SEND_MESSAGE"]),
        clickedBot() {
            this.FETCH_BOT_SELECTION((this.botConfigs.messengerUser) ? 1 : 0);
            this.$emit("toggleState")
        },
        sendQuickReplies(qreply) {
            this.FETCH_BOT_SELECTION(1)
            this.$emit("toggleState")
            setTimeout(() => {
                this.SET_SENDER_MESSAGE(qreply);
                this.SEND_MESSAGE({ 'text': qreply, 'payload': `STORY_ID-${this.popUpStory.story_id}#QUICK_PAGE_MESSAGE-${qreply}` });
            }, 1000);
        }
    }
}
</script>

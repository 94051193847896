const languageArray = {
    english: {
        get_started: "Get Started",
        send_message: "Send a Message...",
        reply_message: "Replying...",
        delete_conversation: "Delete Conversation",
        delete_conversation_message: "You want to delete this conversation?",
        delete_conversation_title: "Are you sure?",
        delete_conversation_confirm: "Yes, delete it!",
        delete_conversation_cancel: "Cancel",
        "1 second ago": "1 second ago",
        seconds: "seconds",
        "1 minute ago": "1 minute ago",
        "1 minute from now": "1 minute from now",
        minutes: "minutes",
        "1 hour ago": "1 hour ago",
        "1 hour from now": "1 hour from now",
        hours: "hours",
        Yesterday: "Yesterday",
        Tomorrow: "Tomorrow",
        days: "days",
        "Last week": "Last week",
        "Next week": "Next week",
        weeks: "weeks",
        "Last month": "Last month",
        "Next month": "Next month",
        months: "months",
        "Last year": "Last year",
        "Next year": "Next year",
        years: "years",
        "Last century": "Last century",
        "Next century": "Next century",
        centuries: "centuries",
        ago: "ago",
        "Just now": "Just now",
        "From now": "From now",
        "Your Full Name and Email Address is required.": "Please fill in the information below, so our team can contact you.",
        "Full Name": "Full Name",
        "Email Address": "Email Address",
        Submit: "Submit",
        Name: "Name",
        "Start Conversation": "Start Conversation",
        Skip: "Skip",
        Exit: "Exit",
        "Message From": "Message From",
        "Do you need any help?": "Do you need any help?",
        "Exit Form": "Exit Form",
        'Lets Chat': 'Let’s Chat!',
    },
    spanish: {
        get_started: "Empezar",
        send_message: "Enviar un mensaje...",
        reply_message: "Responder...",
        delete_conversation: "Eliminar la conversación",
        delete_conversation_message: "¿Quieres eliminar esta conversación?",
        delete_conversation_title: "¿Estás seguro?",
        delete_conversation_confirm: "Sí, ¡elimínalo!",
        delete_conversation_cancel: "Cancelar",
        "1 second ago": "Hace 1 segundos",
        "1 second from now": "1 segundo a partir de ahora",
        seconds: "segundos",
        "1 minute ago": "Hace 1 minuto",
        "1 minute from now": "1 minuto a partir de ahora",
        minutes: "minutos",
        "1 hour ago": "1 hora antes",
        "1 hour from now": "1 hora a partir de ahora",
        hours: "horas",
        Yesterday: "Ayer",
        Tomorrow: "mañana",
        days: "dias",
        "Last week": "La semana pasada",
        "Next week": "La próxima semana",
        weeks: "semanas",
        "Last month": "El mes pasado",
        "Next month": "Próximo mes",
        months: "meses",
        "Last year": "El año pasado",
        "Next year": "El próximo año",
        years: "años",
        "Last century": "Siglo pasado",
        "Next century": "Próximo siglo",
        centuries: "siglos",
        ago: "hace",
        "Just now": "Justo ahora",
        "From now": "Desde ahora",
        "Your Full Name and Email Address is required.": "Por favor complete la información a continuación, para que nuestro equipo pueda contactarlo.",
        "Full Name": "Nombre completo",
        "Email Address": "Dirección de correo electrónico",
        Submit: "Enviar",
        Name: "Nombre",
        "Start Conversation": "Iniciar Conversación",
        Skip: "Omitir",
        Exit: "Salida",
        "Message From": "Mensaje de",
        "Do you need any help?": "¿Necesitas alguna ayuda?",
        "Exit Form": "Salida",
        'Lets Chat': 'Charlemos'
    },
    danish: {
        get_started: "Kom igang",
        send_message: "Send besked...",
        reply_message: "Svarer...",
        delete_conversation: "Slet samtale",
        delete_conversation_message: "Vil du slette denne samtale?",
        delete_conversation_title: "Er du sikker?",
        delete_conversation_confirm: "Ja, slet det!",
        delete_conversation_cancel: "Afbestille",
        "1 second ago": "1 sekund siden",
        seconds: "sekunder",
        "1 minute ago": "1 minut siden",
        "1 minute from now": "1 minut fra nu",
        minutes: "minutter",
        "1 hour ago": "1 time siden",
        "1 hour from now": "1 time fra nu",
        hours: "timer",
        Yesterday: "I går",
        Tomorrow: "I morgen",
        days: "dage",
        "Last week": "Sidste uge",
        "Next week": "Næste uge",
        weeks: "uger",
        "Last month": "Sidste måned",
        "Next month": "Næste måned",
        months: "måneder",
        "Last year": "Sidste år",
        "Next year": "Næste år",
        years: "flere år",
        "Last century": "Sidste århundrede",
        "Next century": "Næste århundrede",
        centuries: "århundreder",
        ago: "siden",
        "Just now": "Lige nu",
        "From now": "Fra nu",
        "Your Full Name and Email Address is required.": "Udfyld venligst nedenstående oplysninger, så vores team kan kontakte dig.",
        "Full Name": "Fulde navn",
        "Email Address": "Email adresse",
        Submit: "Indsend",
        Name: "Navn",
        "Start Conversation": "Start samtale",
        Skip: "Springe",
        Exit: "Afslut",
        "Message From": "Meddelelse fra",
        "Do you need any help?": "Har du brug for hjælp?",
        "Exit Form": "Afslut",
        'Lets Chat': 'Lad os snakke'
    },
    german: {
        get_started: "Loslegen",
        send_message: "Nachricht senden...",
        reply_message: "Antworten...",
        delete_conversation: "Konversation löschen",
        delete_conversation_message: "Sie möchten dieses Gespräch löschen?",
        delete_conversation_title: "Bist du sicher?",
        delete_conversation_confirm: "Ja, löschen Sie es!",
        delete_conversation_cancel: "Stornieren",
        seconds: "sekunden",
        "1 minute ago": "vor 1 minute",
        "1 minute from now": "1 minute ab jetzt",
        minutes: "protokoll",
        "1 hour ago": "vor 1 stunde",
        "1 hour from now": "1 stunde ab jetzt",
        hours: "std",
        Yesterday: "Gestern",
        Tomorrow: "Morgen",
        days: "Tage",
        "Last week": "Letzte Woche",
        "Next week": "Næste uge",
        weeks: "uger",
        "Last month": "Sidste måned",
        "Next month": "Nächsten Monat",
        months: "Monate",
        "Last year": "Vergangenes Jahr",
        "Next year": "Nächstes Jahr",
        years: "Jahre",
        "Last century": "Letztes Jahrhundert",
        "Next century": "Nächstes Jahrhundert",
        centuries: "Jahrhunderte",
        ago: "vor",
        "Just now": "Gerade jetzt",
        "From now": "In",
        "Your Full Name and Email Address is required.": "Bitte geben Sie die folgenden Informationen ein, damit unser Team Sie kontaktieren kann.",
        "Full Name": "Vollständiger Name",
        "Email Address": "E-Mail-Addresse",
        Submit: "Einreichen",
        Name: "Name",
        "Start Conversation": "Starten Sie die Unterhaltung",
        Skip: "Überspringen",
        Exit: "Ausgang",
        "Message From": "Nachricht von",
        "Do you need any help?": "Brauchen Sie Hilfe?",
        "Exit Form": "Ausgang",
        'Lets Chat': 'Lass uns schreiben'
    },
    french: {
        get_started: "Commencer",
        send_message: "Envoyer le message...",
        reply_message: "Réponse...",
        delete_conversation: "Supprimer la conversation",
        delete_conversation_message: "Vous voulez supprimer cette conversation?",
        delete_conversation_title: "Êtes-vous sûr?",
        delete_conversation_confirm: "Oui, supprimez-le!",
        delete_conversation_cancel: "Annuler",
        "1 second ago": "Il y a 1 seconde",
        seconds: "secondes",
        "1 minute ago": "il y a 1 minute",
        "1 minute from now": "Dans 1 minute",
        minutes: "minutes",
        "1 hour ago": "Il ya 1 heure",
        "1 hour from now": "Dans 1 heure",
        hours: "heures",
        Yesterday: "Hier",
        Tomorrow: "Demain",
        days: "journées",
        "Last week": "La semaine dernière",
        "Next week": "Prochaine semaine",
        weeks: "semaines",
        "Last month": "Le mois dernier",
        "Next month": "Le mois prochain",
        months: "mois",
        "Last year": "L'année dernière",
        "Next year": "L'année prochaine",
        years: "années",
        "Last century": "Le siècle dernier",
        "Next century": "Siècle prochain",
        centuries: "des siècles",
        ago: "depuis",
        "Just now": "Juste maintenant",
        "From now": "À partir de maintenant",
        "Your Full Name and Email Address is required.": "Veuillez renseigner les informations ci-dessous afin que notre équipe puisse vous contacter.",
        "Full Name": "Nom complet",
        "Email Address": "Adresse électronique",
        Submit: "Soumettre",
        Name: "Prénom",
        "Start Conversation": "Lancer la conversation",
        Skip: "Sauter",
        Exit: "Sortie",
        "Message From": "Message de",
        "Do you need any help?": "As tu besoin d'aide?",
        "Exit Form": "Sortie",
        'Lets Chat': 'Parlons'
    },
    portuguese: {
        get_started: "Iniciar",
        send_message: "Enviar mensagem...",
        reply_message: "Respondendo...",
        delete_conversation: "Apagar conversa",
        delete_conversation_message: "Você quer apagar esta conversa?",
        delete_conversation_title: "Você tem certeza?",
        delete_conversation_confirm: "Sim, exclua!",
        delete_conversation_cancel: "Cancelar",
        "1 second ago": "1 segundo atrás",
        seconds: "segundos",
        "1 minute ago": "1 minuto atrás",
        "1 minute from now": "Daqui a 1 minuto",
        minutes: "minutos",
        "1 hour ago": "1 hora atrás",
        "1 hour from now": "Daqui a 1 hora",
        hours: "horas",
        Yesterday: "Ontem",
        Tomorrow: "Amanhã",
        days: "dias",
        "Last week": "Semana passada",
        "Next week": "Semana que vem",
        weeks: "semanas",
        "Last month": "Mês passado",
        "Next month": "Próximo mês",
        months: "meses",
        "Last year": "Ano passado",
        "Next year": "Próximo ano",
        years: "anos",
        "Last century": "Século passado",
        "Next century": "Próximo século",
        centuries: "séculos",
        ago: "atrás",
        "Just now": "Agora mesmo",
        "From now": "A partir de agora",
        "Your Full Name and Email Address is required.": "Por favor, preencha as informações abaixo, para que nossa equipe possa entrar em contato com você.",
        "Full Name": "Nome completo",
        "Email Address": "Endereço de e-mail",
        Submit: "Enviar",
        Name: "Nome",
        "Start Conversation": "Iniciar conversa",
        Skip: "Pular",
        Exit: "Saída",
        "Message From": "Mensagem de",
        "Do you need any help?": "Você precisa de alguma ajuda?",
        "Exit Form": "Saída",
        'Lets Chat': 'Vamos conversar'
    },
    italian: {
        get_started: "Iniziare",
        send_message: "Mandare un messaggio...",
        reply_message: "Rispondendo...",
        delete_conversation: "Cancella la conversazione",
        delete_conversation_message: "Vuoi eliminare questa conversazione?",
        delete_conversation_title: "Sei sicuro?",
        delete_conversation_confirm: "Sì, cancellalo!",
        delete_conversation_cancel: "Annulla",
        "1 second ago": "1 secondo fa",
        seconds: "secondi",
        "1 minute ago": "1 minuto fa",
        "1 minute from now": "1 minuto da ora",
        minutes: "minuti",
        "1 hour ago": "1 ora fa",
        "1 hour from now": "1 ora da ora",
        hours: "ore",
        Yesterday: "Ieri",
        Tomorrow: "Domani",
        days: "giorni",
        "Last week": "La settimana scorsa",
        "Next week": "La prossima settimana",
        weeks: "settimane",
        "Last month": "Lo scorso mese",
        "Next month": "Il prossimo mese",
        months: "mesi",
        "Last year": "L'anno scorso",
        "Next year": "L'anno prossimo",
        years: "anni",
        "Last century": "L'ultimo secolo",
        "Next century": "Il prossimo secolo",
        centuries: "secoli",
        ago: "fa",
        "Just now": "Proprio adesso",
        "From now": "Da adesso",
        "Your Full Name and Email Address is required.": "Inserisci le informazioni di seguito, in modo che il nostro team possa contattarti.",
        "Full Name": "Nome e cognome",
        "Email Address": "Indirizzo email",
        Submit: "Sottoscrivi",
        Name: "Nome",
        "Start Conversation": "Inizia conversazione",
        Skip: "Salta",
        Exit: "Uscita",
        "Message From": "Messaggio da",
        "Do you need any help?": "Avete bisogno di aiuto?",
        "Exit Form": "Uscita",
        'Lets Chat': 'Parliamo'
    },
    dutch: {
        get_started: "Begin",
        send_message: "Stuur een bericht...",
        reply_message: "Reageren...",
        delete_conversation: "Verwijder gesprek",
        delete_conversation_message: "Wil je dit gesprek verwijderen?",
        delete_conversation_title: "Weet je het zeker?",
        delete_conversation_confirm: "Ja, verwijder het!",
        delete_conversation_cancel: "annuleren",
        "1 second ago": "1 second geleden",
        seconds: "seconden",
        "1 minute ago": "1 minuut geleden",
        "1 minute from now": "Over 1 minuut",
        minutes: "minuten",
        "1 hour ago": "1 uur geleden",
        "1 hour from now": "Over 1 uur",
        hours: "uur",
        Yesterday: "Gisteren",
        Tomorrow: "Morgen",
        days: "dagen",
        "Last week": "Vorige week",
        "Next week": "Volgende week",
        weeks: "weken",
        "Last month": "Vorige maand",
        "Next month": "Volgende maand",
        months: "maanden",
        "Last year": "Vorig jaar",
        "Next year": "Volgend jaar",
        years: "jaar",
        "Last century": "Laatste eeuw",
        "Next century": "Volgende eeuw",
        centuries: "eeuwen",
        ago: "geleden",
        "Just now": "Net nu",
        "From now": "Vanaf nu",
        "Your Full Name and Email Address is required.": "Vul de onderstaande informatie in, zodat ons team contact met u kan opnemen.",
        "Full Name": "Voor-en achternaam",
        "Email Address": "E-mailadres",
        Submit: "verzenden",
        Name: "Naam",
        "Start Conversation": "Begin een gesprek",
        Skip: "Overspringen",
        Exit: "Uitgang",
        "Message From": "Bericht van",
        "Do you need any help?": "Heb je hulp nodig?",
        "Exit Form": "Uitgang",
        'Lets Chat': 'Laten we chatten'
    },
    russian: {
        get_started: "Начать",
        send_message: "Отправить сообщение...",
        reply_message: "Отвечаю ...",
        delete_conversation: "Удалить беседу",
        delete_conversation_message: "Вы хотите удалить этот разговор?",
        delete_conversation_title: "Уверены ли вы?",
        delete_conversation_confirm: "Да, удали это!",
        delete_conversation_cancel: "Отмена",
        "1 second ago": "1 секунду назад",
        seconds: "секунд",
        "1 minute ago": "1 минуту назад",
        "1 minute from now": "Через 1 минуту",
        minutes: "минут",
        "1 hour ago": "1 час назад",
        "1 hour from now": "Через 1 час",
        hours: "часов",
        Yesterday: "Вчера",
        Tomorrow: "Завтра",
        days: "дней",
        "Last week": "Прошлая неделя",
        "Next week": "Следующая неделя",
        weeks: "недель",
        "Last month": "Прошлый месяц",
        "Next month": "Следующий месяц",
        months: "месяцы",
        "Last year": "Прошлый год",
        "Next year": "В следующем году",
        years: "лет",
        "Last century": "Прошлый век",
        "Next century": "Следующий век",
        centuries: "века",
        ago: "тому назад",
        "Just now": "Прямо сейчас",
        "From now": "Отныне",
        "Your Full Name and Email Address is required.": "Пожалуйста, заполните информацию ниже, чтобы наша команда могла связаться с вами.",
        "Full Name": "ФИО",
        "Email Address": "Адрес электронной почты",
        Submit: "Отправить",
        Name: "название",
        "Start Conversation": "Начать разговор",
        Skip: "Пропускать",
        Exit: "Выход",
        "Message From": "Сообщение от",
        "Do you need any help?": "Вам помочь?",
        "Exit Form": "Выход",
        'Lets Chat': 'Давай поговорим'
    },
    polish: {
        get_started: "Zaczynać",
        send_message: "Wysłać wiadomość...",
        reply_message: "Odpowiadam...",
        delete_conversation: "Usunąć konwersację",
        delete_conversation_message: "Chcesz usunąć tę rozmowę?",
        delete_conversation_title: "Jesteś pewny?",
        delete_conversation_confirm: "Tak, usuń to!",
        delete_conversation_cancel: "anulować",
        "1 second ago": "1 sekundę temu",
        seconds: "sekundy",
        "1 minute ago": "1 minuta temu",
        "1 minute from now": "Za minutę",
        minutes: "minuty",
        "1 hour ago": "1 godzinę temu",
        "1 hour from now": "Za godzinę",
        hours: "godziny",
        Yesterday: "Wczoraj",
        Tomorrow: "Jutro",
        days: "dni",
        "Last week": "Zeszły tydzień",
        "Next week": "W następnym tygodniu",
        weeks: "tygodni",
        "Last month": "W zeszłym miesiącu",
        "Next month": "W następnym miesiącu",
        months: "miesięcy",
        "Last year": "Ostatni rok",
        "Next year": "Następny rok",
        years: "lat",
        "Last century": "Ostatni wiek",
        "Next century": "Następny wiek",
        centuries: "wieki",
        ago: "temu",
        "Just now": "Właśnie",
        "From now": "Od teraz",
        "Your Full Name and Email Address is required.": "Wypełnij poniższe informacje, aby nasz zespół mógł się z Tobą skontaktować.",
        "Full Name": "Pełne imię i nazwisko",
        "Email Address": "Adres e-mail",
        Submit: "Zatwierdź",
        Name: "Imię",
        "Start Conversation": "Rozpocznij rozmowę",
        Skip: "Pominąć",
        Exit: "Wyjście",
        "Message From": "Wiadomość od",
        "Do you need any help?": "Potrzebujesz pomocy?",
        "Exit Form": "Wyjście",
        'Lets Chat': 'Porozmawiajmy'
    },
    czech: {
        get_started: "Začínáme",
        send_message: "Poslat Zprávu...",
        reply_message: "Odpověď ...",
        delete_conversation_title: "Jsi si jistá?",
        delete_conversation_confirm: "Ano, smažte to!",
        delete_conversation_cancel: "zrušení",
        delete_conversation: "Odstranit Konverzaci",
        delete_conversation_message: "Jsi si jistý? Chcete smazat tento rozhovor?",
        "1 second ago": "před 1 sekundou",
        seconds: "sekund",
        "1 minute ago": "Před 1 minutou",
        "1 minute from now": "1 minutu od teď",
        minutes: "minut",
        "1 hour ago": "Před 1 hodinou",
        "1 hour from now": "1 hodinu od teď",
        hours: "hodin",
        Yesterday: "Včera",
        Tomorrow: "Zítra",
        days: "dny",
        "Last week": "Minulý týden",
        "Next week": "Příští týden",
        weeks: "týdny",
        "Last month": "Minulý měsíc",
        "Next month": "Příští měsíc",
        months: "měsíců",
        "Last year": "V loňském roce",
        "Next year": "Příští rok",
        years: "let",
        "Last century": "Minulého století",
        "Next century": "Příští století",
        centuries: "století",
        ago: "před",
        "Just now": "Právě teď",
        "From now": "Od teď",
        "Your Full Name and Email Address is required.": "Vyplňte, prosím, níže uvedené informace, takže náš tým může vás kontaktovat.",
        "Full Name": "Celé Jméno",
        "Email Address": "E-Mailovou Adresu",
        Submit: "Předložit",
        Name: "Jméno",
        "Start Conversation": "Začít Konverzaci",
        Skip: "Přeskočit",
        Exit: "Výstup",
        "Message From": "Zpráva Z",
        "Exit Form": "Ukončit formulář",
        'Lets Chat': 'Umožňuje chatovat'
    },
    estonian: {
        get_started: "Alustamine",
        send_message: "Saada Sõnum...",
        reply_message: "Vastamine...",
        delete_conversation_title: "Oled sa kindel?",
        delete_conversation_confirm: "Jah, kustuta see!",
        delete_conversation_cancel: "Tühista",
        delete_conversation: "Kustutada Vestlus",
        delete_conversation_message: "Oled sa kindel? Tahad kustutada seda vestlust?",
        "1 second ago": "1 sekund tagasi",
        seconds: "sekundit",
        "1 minute ago": "1 tund tagasi",
        "1 minute from now": "1 minuti pärast",
        minutes: "minutit",
        "1 hour ago": "1 tund tagasi",
        "1 hour from now": "1 tund nüüd",
        hours: "tundi",
        Yesterday: "Eile",
        Tomorrow: "Homme",
        days: "päeva",
        "Last week": "Viimane nädal",
        "Next week": "Järgmisel nädalal",
        weeks: "nädalat",
        "Last month": "Viimane kuu",
        "Next month": "Järgmisel kuul",
        months: "kuud",
        "Last year": "Eelmisel aastal",
        "Next year": "Järgmisel aastal",
        years: "aasta",
        "Last century": "Eelmise sajandi",
        "Next century": "Järgmisel sajandil",
        centuries: "sajandil",
        ago: "tagasi",
        "Just now": "Just nüüd",
        "From now": "Nüüd",
        "Your Full Name and Email Address is required.": "Palun täitke allolevat teavet, nii et meie meeskond saab teiega ühendust.",
        "Full Name": "Täielik Nimi",
        "Email Address": "E-Posti Aadress",
        Submit: "Esitada",
        Name: "Nimi",
        "Start Conversation": "Alusta Vestlus",
        Skip: "Jäta vahele",
        Exit: "Välju",
        "Message From": "Sõnum",
        "Exit Form": "Väljumise vorm",
        'Lets Chat': 'Vestleme'
    },
    finnish: {
        get_started: "Päästä Alkuun",
        send_message: "Lähetä Viesti...",
        reply_message: "Vastataan...",
        delete_conversation_title: "Oletko varma?",
        delete_conversation_confirm: "Kyllä, poista se!",
        delete_conversation_cancel: "Peruuttaa",
        delete_conversation: "Poista Keskustelu",
        delete_conversation_message: "Oletko varma? Haluat poistaa tämän keskustelun?",
        "1 second ago": "1 sekunti sitten",
        seconds: "sekuntia",
        "1 minute ago": "1 minuutti sitten",
        "1 minute from now": "1 minuutin päässä nyt",
        minutes: "minuuttia",
        "1 hour ago": "1 tunti sitten",
        "1 hour from now": "1 tunnin päässä nyt",
        hours: "tuntia",
        Yesterday: "Eilen",
        Tomorrow: "Huomenna",
        days: "päivää",
        "Last week": "Viime viikolla",
        "Next week": "Ensi viikolla",
        weeks: "viikkoa",
        "Last month": "Viime kuussa",
        "Next month": "Ensi kuussa",
        months: "kuukautta",
        "Last year": "Viime vuonna",
        "Next year": "Ensi vuonna",
        years: "vuotta",
        "Last century": "Viime vuosisadalla",
        "Next century": "Ensi vuosisadalla",
        centuries: "vuosisatojen",
        ago: "sitten",
        "Just now": "Juuri nyt",
        "From now": "Nyt",
        "Your Full Name and Email Address is required.": "Ole hyvä ja täytä alla olevat tiedot, niin meidän joukkue voi ottaa sinuun yhteyttä.",
        "Full Name": "Koko Nimi",
        "Email Address": "Sähköpostiosoite",
        Submit: "Lähetä",
        Name: "Nimi",
        "Start Conversation": "Aloita Keskustelu",
        Skip: "Skip",
        Exit: "Poistu",
        "Message From": "Viesti",
        "Exit Form": "Poistu lomakkeesta",
        'Lets Chat': 'Jutellaan'
    },
    hungarian: {
        get_started: "Az Induláshoz",
        send_message: "Küldj egy Üzenetet...",
        reply_message: "Válasz...",
        delete_conversation_title: "biztos vagy ebben?",
        delete_conversation_confirm: "Igen, törölje!",
        delete_conversation_cancel: "Megszünteti",
        delete_conversation: "Beszélgetés Törlése",
        delete_conversation_message: "Biztos vagy benne? Akarod törölni ezt a beszélgetést?",
        "1 second ago": "1 másodperccel ezelőtt",
        seconds: "másodperc",
        "1 minute ago": "1 perccel ezelőtt",
        "1 minute from now": "1 perc múlva",
        minutes: "perc",
        "1 hour ago": "1 óra ezelőtt",
        "1 hour from now": "1 óra múlva",
        hours: "óra",
        Yesterday: "Tegnap",
        Tomorrow: "Holnap",
        days: "nap",
        "Last week": "Múlt héten",
        "Next week": "A jövő héten",
        weeks: "hét",
        "Last month": "Múlt hónapban",
        "Next month": "Következő hónap",
        months: "hónap",
        "Last year": "Tavaly",
        "Next year": "A következő évben",
        years: "év",
        "Last century": "A múlt században",
        "Next century": "A következő században",
        centuries: "évszázadokkal",
        ago: "ezelőtt",
        "Just now": "Csak most",
        "From now": "A most",
        "Your Full Name and Email Address is required.": "Kérjük, töltse ki az alábbi adatokat, így a csapat felveszi önnel a kapcsolatot.",
        "Full Name": "Teljes Név",
        "Email Address": "E-Mail Cím",
        Submit: "Benyújtaniuk",
        Name: "Név",
        "Start Conversation": "Kezdje A Beszélgetést,",
        Skip: "Ugrás",
        Exit: "Kilépés",
        "Message From": "Üzenet",
        "Exit Form": "Kilépés",
        'Lets Chat': 'Csevegjünk'
    },
    irish: {
        get_started: "Tús A Chur",
        send_message: "Teachtaireacht a sheoladh...",
        reply_message: "Ag freagairt ...",
        delete_conversation_title: "An bhfuil tú cinnte?",
        delete_conversation_confirm: "Sea, scrios é!",
        delete_conversation_cancel: "Cealaigh",
        delete_conversation: "Deireadh A Chur Le Comhrá",
        delete_conversation_message: "An bhfuil tú cinnte? Ba mhaith leat a scrios an comhrá?",
        "1 second ago": "1 dara ó shin",
        seconds: "soicind",
        "1 minute ago": "1 nóiméad ó shin",
        "1 minute from now": "1 nóiméad ó anois",
        minutes: "nóiméad",
        "1 hour ago": "1 uair an chloig ó shin",
        "1 hour from now": "1 uair an chloig ó anois",
        hours: "uair an chloig",
        Yesterday: "Inné",
        Tomorrow: "Amárach",
        days: "lá",
        "Last week": "An tseachtain seo caite",
        "Next week": "An tseachtain seo chugainn",
        weeks: "seachtaine",
        "Last month": "An mhí seo caite",
        "Next month": "An mhí seo chugainn",
        months: "mhí",
        "Last year": "An bhliain seo caite",
        "Next year": "An bhliain seo chugainn",
        years: "bliain",
        "Last century": "Chéid seo caite",
        "Next century": "Chéid seo chugainn",
        centuries: "céadta bliain",
        ago: "ó shin",
        "Just now": "Díreach anois",
        "From now": "Ó anois",
        "Your Full Name and Email Address is required.": "Le do thoil líon isteach an fhaisnéis thíos, mar sin ar ár bhfoireann is féidir teagmháil a dhéanamh leat.",
        "Full Name": "Ainm Iomlán",
        "Email Address": "Seoladh Ríomhphoist",
        Submit: "A chur isteach",
        Name: "Ainm",
        "Start Conversation": "Tosaigh Comhrá",
        Skip: "Skip",
        Exit: "Scoir",
        "Message From": "Teachtaireacht Ó",
        "Exit Form": "Scoir",
        'Lets Chat': 'Lig Comhrá'
    },
    latvian: {
        get_started: "Darba Sākšana",
        send_message: "Nosūtīt Ziņu...",
        reply_message: "Atbild...",
        delete_conversation_title: "Vai tu esi pārliecināts?",
        delete_conversation_confirm: "Jā, izdzēsiet to!",
        delete_conversation_cancel: "Atcelt",
        delete_conversation: "Dzēst Saruna",
        delete_conversation_message: "Vai esat pārliecināts? Vēlaties dzēst šo sarunu?",
        "1 second ago": "pirms 1 sekundes",
        seconds: "sekundes",
        "1 minute ago": "1 minūte atpakaļ",
        "1 minute from now": "1 minūtes no tagad",
        minutes: "minūtes",
        "1 hour ago": "1 stunda atpakaļ",
        "1 hour from now": "1 stunda no tagad",
        hours: "stundas",
        Yesterday: "Vakar",
        Tomorrow: "Rīt",
        days: "dienas",
        "Last week": "Pēdējā nedēļā",
        "Next week": "Nākamajā nedēļā",
        weeks: "nedēļas",
        "Last month": "Pēdējā mēnesī",
        "Next month": "Nākamajā mēnesī",
        months: "mēneši",
        "Last year": "Pēdējo gadu",
        "Next year": "Nākamā gada",
        years: "gadi",
        "Last century": "Pagājušā gadsimta",
        "Next century": "Nākamajā gadsimtā",
        centuries: "gadsimtiem",
        ago: "atpakaļ",
        "Just now": "Tikai tagad",
        "From now": "No tagad",
        "Your Full Name and Email Address is required.": "Lūdzu, aizpildiet informāciju zemāk, lai mūsu komanda varētu ar jums sazināties.",
        "Full Name": "Pilns Nosaukums",
        "Email Address": "E-Pasta Adrese",
        Submit: "Iesniegt",
        Name: "Nosaukums",
        "Start Conversation": "Sākt Sarunu",
        Skip: "Izlaist",
        Exit: "Izejas",
        "Message From": "Ziņa No",
        "Exit Form": "Izejas",
        'Lets Chat': 'Patērzēsim'
    },
    lithuanian: {
        get_started: "Paruošimas",
        send_message: "Išsiųsti Pranešimą...",
        reply_message: "Atsakoma...",
        delete_conversation_title: "Ar tu tuo tikras?",
        delete_conversation_confirm: "Taip, ištrinkite!",
        delete_conversation_cancel: "Atšaukti",
        delete_conversation: "Ištrinti Pokalbį",
        delete_conversation_message: "Ar tikrai? Norite ištrinti šį pokalbį?",
        "1 second ago": "prieš 1 sekundę",
        seconds: "sekundžių",
        "1 minute ago": "1 minutė prieš",
        "1 minute from now": "1 minutę nuo dabar",
        minutes: "minutes",
        "1 hour ago": "1 valanda prieš",
        "1 hour from now": "1 valanda nuo dabar",
        hours: "valandos",
        Yesterday: "Vakar",
        Tomorrow: "Rytoj",
        days: "dienų",
        "Last week": "Praeitą savaitę",
        "Next week": "Kitą savaitę",
        weeks: "savaites",
        "Last month": "Praeitą mėnesį",
        "Next month": "Kitą mėnesį",
        months: "mėnesių",
        "Last year": "Pernai",
        "Next year": "Kitais metais",
        years: "metų",
        "Last century": "Praėjusio šimtmečio",
        "Next century": "Kitame amžiuje",
        centuries: "amžius",
        ago: "prieš",
        "Just now": "Tiesiog dabar",
        "From now": "Nuo dabar",
        "Your Full Name and Email Address is required.": "Prašome užpildyti žemiau pateikta informacija, kad mūsų komanda gali susisiekti su jumis.",
        "Full Name": "Pilnas Vardas",
        "Email Address": "El. Pašto Adresas",
        Submit: "Pateikti",
        Name: "Pavadinimas",
        "Start Conversation": "Pradėti Pokalbį",
        Skip: "Praleisti",
        Exit: "Uždaryti",
        "Message From": "Pranešimas Iš",
        "Exit Form": "Uždaryti",
        'Lets Chat': 'Pasikalbėkime'
    },
    arabic: {
        get_started: "البدء",
        send_message: "...ارسل رسالة",
        reply_message: "...الرد",
        delete_conversation: "مسح المحادثة",
        delete_conversation_message: "هل تريد حذف هذه المحادثة؟",
        delete_conversation_title: "هل أنت واثق؟",
        delete_conversation_confirm: "نعم ، احذفها!",
        delete_conversation_cancel: "يلغي",
        "1 second ago": "قبل ثانية واحدة",
        seconds: "ثواني",
        "1 minute ago": "منذ 1 دقيقة",
        "1 minute from now": "دقيقة واحدة من الآن",
        minutes: "الدقائق",
        "1 hour ago": "منذ 1 ساعة",
        "1 hour from now": "1 ساعة من الآن",
        hours: "ساعات",
        Yesterday: "أمس",
        Tomorrow: "غدا",
        days: "أيام",
        "Last week": "الاسبوع الماضى",
        "Next week": "الاسبوع المقبل",
        weeks: "أسابيع",
        "Last month": "الشهر الماضي",
        "Next month": "الشهر القادم",
        months: "الشهور",
        "Last year": "العام الماضي",
        "Next year": "العام القادم",
        years: "سنوات",
        "Last century": "القرن الماضي",
        "Next century": "القرن القادم",
        centuries: "قرون",
        ago: "منذ",
        "Just now": "في هذة اللحظة",
        "From now": "من الان",
        "Your Full Name and Email Address is required.": "يرجى ملء المعلومات أدناه ، حتى يتمكن فريقنا من الاتصال بك.",
        "Full Name": "الاسم بالكامل",
        "Email Address": "عنوان البريد الإلكتروني",
        Submit: "إرسال",
        Name: "اسم",
        "Start Conversation": "بدء المحادثة",
        Skip: "يتخطى",
        Exit: "مخرج",
        "Message From": "رسالة من",
        "Do you need any help?": "هل تحتاج أي مساعدة؟",
        "Exit Form": "نموذج الخروج",
        "Lets Chat": "لندردش"
    },
    chinese: {
        get_started: "开始使用",
        send_message: "发送信息...",
        reply_message: "正在回复...",
        delete_conversation: "删除对话",
        delete_conversation_message: "您要删除此对话吗？",
        delete_conversation_title: "你确定吗？",
        delete_conversation_confirm: "是的，删除它！",
        delete_conversation_cancel: "取消",
        "1 second ago": "1秒前",
        seconds: "秒",
        "1 minute ago": "1分钟前",
        "1 minute from now": "从现在开始1分钟",
        minutes: "分钟",
        "1 hour ago": "1小时前",
        "1 hour from now": "从现在开始1小时",
        hours: "小时",
        Yesterday: "昨天",
        Tomorrow: "明天",
        days: "天",
        "Last week": "上个星期",
        "Next week": "下周",
        weeks: "周",
        "Last month": "上个月",
        "Next month": "下个月",
        months: "月",
        "Last year": "去年",
        "Next year": "明年",
        years: "年",
        "Last century": "上世纪",
        "Next century": "下个世纪",
        centuries: "世纪",
        ago: "前",
        "Just now": "现在",
        "From now": "现在起",
        "Your Full Name and Email Address is required.": "请填写以下信息，以便我们的团队可以与您联系",
        "Full Name": "全名",
        "Email Address": "电子邮件地址",
        Submit: "提交",
        Name: "名称",
        "Start Conversation": "开始对话",
        Skip: "跳过",
        Exit: "出口",
        "Message From": "邮件发件人",
        "Do you need any help?": "你需要帮助吗？",
        "Exit Form": "退出表格",
        "Lets Chat": "让我们聊天"
    },
    norwegian: {
        get_started: "Kom I Gang",
        send_message: "Send en Melding...",
        reply_message: "Svar...",
        delete_conversation_title: "Er du sikker?",
        delete_conversation_confirm: "Ja, slett den!",
        delete_conversation_cancel: "Avbryt",
        delete_conversation: "Slett Samtale",
        delete_conversation_message: "Er du sikker? Du ønsker å slette denne samtalen?",
        "1 second ago": "1 second ago",
        seconds: "sekunder",
        "1 minute ago": "1 minutt siden",
        "1 minute from now": "1 minutt fra nå",
        minutes: "minutter",
        "1 hour ago": "1 time siden",
        "1 hour from now": "1 time fra nå",
        hours: "timer",
        Yesterday: "I går",
        Tomorrow: "I morgen",
        days: "dager",
        "Last week": "Siste uken",
        "Next week": "Neste uke",
        weeks: "uker",
        "Last month": "Siste måned",
        "Next month": "Neste måned",
        months: "måneder",
        "Last year": "Siste år",
        "Next year": "Neste år",
        years: "år",
        "Last century": "Siste århundre",
        "Next century": "Neste århundre",
        centuries: "århundrer",
        ago: "siden",
        "Just now": "Akkurat nå",
        "From now": "Fra nå",
        "Your Full Name and Email Address is required.": "Fyll ut informasjonen under, slik at våre ansatte kan kontakte deg.",
        "Full Name": "Fullt Navn",
        "Email Address": "E-Postadresse",
        Submit: "Send inn",
        Name: "Navn",
        "Start Conversation": "Start Samtalen",
        Skip: "Hopp",
        Exit: "Avslutt",
        "Message From": "Melding Fra",
        "Exit Form": "Avslutt",
        'Lets Chat': 'La oss snakke'
    },
    slovak: {
        get_started: "Začíname",
        send_message: "Poslať Správu...",
        reply_message: "Odpovedám...",
        delete_conversation_title: "Si si istý?",
        delete_conversation_confirm: "Zrušiť",
        delete_conversation_cancel: "Cancel",
        delete_conversation: "Odstránenie Konverzácie",
        delete_conversation_message: "Ste si istý? Chcete odstrániť tento rozhovor?",
        "1 second ago": "pred 1 sekundou",
        seconds: "sekúnd",
        "1 minute ago": "1 minute ago",
        "1 minute from now": "1 minútu od teraz",
        minutes: "minút",
        "1 hour ago": "1 hodinu pred",
        "1 hour from now": "1 hodinu od teraz",
        hours: "hodín",
        Yesterday: "Včera",
        Tomorrow: "Zajtra",
        days: "dní",
        "Last week": "Minulý týždeň",
        "Next week": "Budúci týždeň",
        weeks: "týždňov",
        "Last month": "Posledný mesiac",
        "Next month": "Budúci mesiac",
        months: "mesiacov",
        "Last year": "Minulý rok",
        "Next year": "Budúci rok",
        years: "rokov",
        "Last century": "V minulom storočí",
        "Next century": "Ďalej storočia",
        centuries: "storočí",
        ago: "pred",
        "Just now": "Práve teraz",
        "From now": "Od teraz",
        "Your Full Name and Email Address is required.": "Prosím, vyplňte nižšie uvedené informácie, takže náš tím vás mohli kontaktovať.",
        "Full Name": "Meno A Priezvisko",
        "Email Address": "E-Mailová Adresa",
        Submit: "Odoslať",
        Name: "Názov",
        "Start Conversation": "Začať Rozhovor",
        Skip: "Preskočiť",
        Exit: "Ukončenie",
        "Message From": "Správy Z",
        "Exit Form": "Ukončenie",
        'Lets Chat': 'Poďme si písať'
    },
    romanian: {
        get_started: "Începe",
        send_message: "Trimite mesaj...",
        reply_message: "Răspuns...",
        delete_conversation_title: "șterge titlul conversației",
        delete_conversation_confirm: "a confirma",
        delete_conversation_cancel: "Anulare",
        delete_conversation: "Șterge conversația",
        delete_conversation_message: "Esti sigur? Doriți să ștergeți această conversație?",
        "1 second ago": "Acum 1 secundă",
        "1 second from now": "1 secundă de acum",
        seconds: "secunde",
        "1 minute ago": "1 minute ago",
        "1 minute from now": "Peste 1 minut",
        minutes: "minutes",
        "1 hour ago": "1 oră în urmă",
        "1 hour from now": "Peste 1 oră de acum",
        hours: "ore",
        Yesterday: "Ieri",
        Tomorrow: "Mâine",
        days: "zile",
        "Last week": "Săptămâna trecută",
        "Next week": "Saptamana viitoare",
        weeks: "săptămâni",
        "Last month": "Luna trecuta",
        "Next month": "Luna viitoare",
        months: "luni",
        "Last year": "Anul trecut",
        "Next year": "Anul urmator",
        years: "ani",
        "Last century": "Ultimul secol",
        "Next century": "Secolul următor",
        centuries: "secole",
        ago: "în urmă",
        "Just now": "Chiar acum",
        "From now": "De acum",
        "Your Full Name and Email Address is required.": "Numele dvs. complet și adresa de e-mail sunt necesare.",
        "Full Name": "Numele complet",
        "Email Address": "Email Address",
        Submit: "Trimite",
        Name: "Nume",
        "Start Conversation": "Porniți conversația",
        Skip: "Ocolire",
        Exit: "ÎNCHIDE",
        "Message From": "Mesaj de la",
        "Exit Form": "ÎNCHIDE",
        'Lets Chat': 'Hai sa vorbim'
    },
    swedish: {
        get_started: "Komma Igång",
        send_message: "Skicka ett Meddelande...",
        reply_message: "Svarar...",
        delete_conversation_title: "Är du säker?",
        delete_conversation_confirm: "Ja, radera det!",
        delete_conversation_cancel: "Annullera",
        delete_conversation: "Radera En Konversation",
        delete_conversation_message: "Är du säker? Du vill ta bort det här samtalet?",
        "1 second ago": " sekund sedan",
        seconds: "sekunder",
        "1 minute ago": "1 minut sedan",
        "1 minute from now": "1 minut från och med nu",
        minutes: "minuter",
        "1 hour ago": "1 timme sedan",
        "1 hour from now": "1 timme från och med nu",
        hours: "timmar",
        Yesterday: "Igår",
        Tomorrow: "I morgon",
        days: "dagar",
        "Last week": "Förra veckan",
        "Next week": "Nästa vecka",
        weeks: "veckor",
        "Last month": "Senaste månaden",
        "Next month": "Nästa månad",
        months: "månader",
        "Last year": "Förra året",
        "Next year": "Nästa år",
        years: "år",
        "Last century": "Förra seklet",
        "Next century": "Nästa århundrade",
        centuries: "århundraden",
        ago: "sedan",
        "Just now": "Just nu",
        "From now": "Från och med nu",
        "Your Full Name and Email Address is required.": "Vänligen fyll i uppgifterna nedan, så vårt team kan kontakta dig.",
        "Full Name": "Fullständigt Namn",
        "Email Address": "E-Postadress",
        Submit: "Skicka",
        Name: "Namn",
        "Start Conversation": "Startar Konversation",
        Skip: "Hoppa över",
        Exit: "Avsluta",
        "Message From": "Meddelande Från",
        "Exit Form": "Avsluta",
        'Lets Chat': 'Låt oss snacka'
    },
    bulgarian: {
        get_started: "Започнете",
        send_message: "Изпратете съобщение...",
        reply_message: "Отговор...",
        delete_conversation_title: "Изтриване на разговор",
        delete_conversation_confirm: "Искате ли да изтриете този разговор?",
        delete_conversation_cancel: "Сигурен ли си?",
        delete_conversation: "Да, изтрийте го!",
        delete_conversation_message: "Отказ",
        "1 second ago": "1 втори преди",
        seconds: "секунди",
        "1 minute ago": "1 преди минута",
        "1 minute from now": "1 минута от сега",
        minutes: "минути",
        "1 hour ago": "1 преди час",
        "1 hour from now": "1 час от сега",
        hours: "часове",
        Yesterday: "Вчера",
        Tomorrow: "Утре",
        days: "дни",
        "Last week": "Миналата седмица",
        "Next week": "Следващата седмица",
        weeks: "седмици",
        "Last month": "Миналия месец",
        "Next month": "Следващ месец",
        months: "месеци",
        "Last year": "Миналата година",
        "Next year": "Следващата година",
        years: "години",
        "Last century": "Миналия век",
        "Next century": "Следващ век",
        centuries: "векове",
        ago: "Аго",
        "Just now": "Точно сега",
        "From now": "Отсега",
        "Your Full Name and Email Address is required.": "Моля, попълнете информацията по-долу, за да може екипът ни да се свърже с вас.",
        "Full Name": "Пълно име",
        "Email Address": "Адрес по имейл",
        Submit: "Изпрати",
        Name: "Име",
        "Start Conversation": "Започнете разговор",
        Skip: "Пропуснете",
        Exit: "Изход",
        "Message From": "Имате ли нужда от помощ?",
        "Exit Form": "Формуляр за излизане",
        'Lets Chat': 'Нека да чатим!'
    },
    ukrainian: {
        get_started: "Почніть",
        send_message: "Надішліть повідомлення...",
        reply_message: "Відповідь...",
        delete_conversation: "Видалити розмову",
        delete_conversation_message: "Ви хочете видалити цю розмову?",
        delete_conversation_title: "Ви впевнені?",
        delete_conversation_confirm: "Так, видаліть!",
        delete_conversation_cancel: "Скасувати",
        "1 second ago": "1 другий тому",
        seconds: "секунди",
        "1 minute ago": "1 хвилину тому",
        "1 minute from now": "1 хвилина відтепер",
        minutes: "хвилини",
        "1 hour ago": "1 годину тому",
        "1 hour from now": "1 година відтепер",
        hours: "години",
        Yesterday: "Вчора",
        Tomorrow: "Завтра",
        days: "дні",
        "Last week": "Минулого тижня",
        "Next week": "Наступний тиждень",
        weeks: "тижні",
        "Last month": "Останній місяць",
        "Next month": "Наступний місяць",
        months: "місяці",
        "Last year": "Минулого року",
        "Next year": "Наступний рік",
        years: "роки",
        "Last century": "Минуле століття",
        "Next century": "Наступне століття",
        centuries: "століття",
        ago: "аго",
        "Just now": "Тільки зараз",
        "From now": "Відтепер",
        "Your Full Name and Email Address is required.": "Будь ласка, заповніть інформацію нижче, щоб наша команда могла зв’язатися з вами.",
        "Full Name": "Повна назва",
        "Email Address": "Адреса електронної пошти",
        Submit: "Надіслати",
        Name: "Ім'я",
        "Start Conversation": "Початкова розмова",
        Skip: "Пропустити",
        Exit: "Вихід",
        "Message From": "Повідомлення від",
        "Do you need any help?": "Вам потрібна допомога?",
        "Exit Form": "Вихідна форма",
        'Lets Chat': 'Давай поговоримо!',
    },
    georgian: {
        get_started: "დაიწყე",
        send_message: "გაგზავნეთ მესიჯი...",
        reply_message: "პასუხი...",
        delete_conversation: "წაშლა საუბარი",
        delete_conversation_message: "გინდა ამ საუბრის წაშლა?",
        delete_conversation_title: "Დარწმუნებული ხარ?",
        delete_conversation_confirm: "დიახ, წაშალე იგი!",
        delete_conversation_cancel: "კანცელი",
        "1 second ago": "1 მეორე წინ",
        seconds: "წამი",
        "1 minute ago": "1 წუთის წინ",
        "1 minute from now": "1 წუთიდან",
        minutes: "წუთი",
        "1 hour ago": "1 საათის წინ",
        "1 hour from now": "1 საათი დღეიდან",
        hours: "საათები",
        Yesterday: "Გუშინ",
        Tomorrow: "ხვალ",
        days: "დღეები",
        "Last week": "ბოლო კვირა",
        "Next week": "შემდეგი კვირა",
        weeks: "კვირა",
        "Last month": "ბოლო თვე",
        "Next month": "შემდეგ თვეში",
        months: "თვეები",
        "Last year": "ბოლო წელი",
        "Next year": "შემდეგ წელს",
        years: "წლები",
        "Last century": "ბოლო საუკუნე",
        "Next century": "Შემდეგ საუკუნეში",
        centuries: "ცენტრი",
        ago: "აგო",
        "Just now": "ახლა",
        "From now": "ამიერიდან",
        "Your Full Name and Email Address is required.": "გთხოვთ, შეავსოთ ქვემოთ მოცემული ინფორმაცია, ასე რომ ჩვენს გუნდს შეუძლია დაგიკავშირდეთ",
        "Full Name": "სრული სახელი",
        "Email Address": "ელ.ფოსტის მისამართი",
        Submit: "წარუდგინე",
        Name: "სახელი",
        "Start Conversation": "დაწყების საუბარი",
        Skip: "სკიპი",
        Exit: "გასვლა",
        "Message From": "შეტყობინება",
        "Do you need any help?": "Დახმარება გჭირდება",
        "Exit Form": "გასვლის ფორმა",
        'Lets Chat': 'მოდით, ჩატი!',
    },
    malay: {
        get_started: "Bermula",
        send_message: "Hantar satu mesej...",
        reply_message: "Membalas ...",
        delete_conversation: "Padamkan Perbualan",
        delete_conversation_message: "Anda mahu menghapus perbualan ini?",
        delete_conversation_title: "Adakah anda pasti?",
        delete_conversation_confirm: "Ya, padamkan!",
        delete_conversation_cancel: "Cancel",
        "1 second ago": "1 saat yang lalu",
        seconds: "saat",
        second: "kedua",
        "1 minute ago": "1 minit yang lalu",
        "1 minute from now": "1 minit dari sekarang",
        minutes: "minit",
        minute: "minit",
        "1 hour ago": "1 jam yang lalu",
        "1 hour from now": "1 jam dari sekarang",
        hours: "jam",
        Yesterday: "Kemarin",
        Tomorrow: "Esok",
        days: " hari",
        "Last week": "Minggu lalu",
        "Next week": "Minggu depan",
        weeks: "minggu",
        "Last month": "Bulan lalu",
        "Next month": "Bulan depan",
        months: "bulan",
        "Last year": "Tahun lalu",
        "Next year": "Tahun depan",
        years: "tahun",
        "Last century": "Abad terakhir",
        "Next century": "Abad seterusnya",
        centuries: "berabad-abad",
        ago: "lalu",
        "Just now": "Baru sekarang",
        "From now": "Dari sekarang",
        "Your Full Name and Email Address is required.": "Sila isi maklumat di bawah, supaya pasukan kami dapat menghubungi anda.",
        "Full Name": "Nama Penuh",
        "Email Address": "Alamat E-mel",
        Submit: "Kirim",
        Name: "Nama",
        "Start Conversation": "Mulakan Perbualan",
        Skip: "Langkau",
        Exit: "Keluar",
        "Message From": "Mesej Dari",
        "Do you need any help?": "Adakah anda memerlukan bantuan?",
        "Exit Form": "Borang Keluar",
        'Lets Chat': 'Mari berbual!'
    },
    shona: {
        get_started: "Tanga",
        send_message: "Tumira Meseji ...",
        reply_message: "Kupindura ...",
        delete_conversation: "Delete Conversation",
        Dele_conversation_message: "Unoda kudzima hurukuro iyi?",
        Dele_conversation_title: "Uri iwe chokwadi ",
        delete_conversation_confirm: "?Hongu, kuidzima ",
        delete_conversation_cancel: "!Cancel",
        "1 second ago": " 1 wechipiri kare ",
        seconds: " mumasekonzi",
        "1 minute ago": "1 miniti kare",
        "1 minute from now": "1 miniti kubva ikozvino",
        minutes: "maminitsi",
        "1 hour ago": "1 awa rapfuura",
        "1 hour from now": "1 awa kubva ikozvino",
        hours: " maawa ",
        Yesterday: " Nezuro ",
        Tomorrow: " Mangwana ",
        days: " mazuva ",
        "Last week": "Vhiki rapfuura ",
        "Next week": "Vhiki rinotevera ",
        weeks: " vhiki ",
        "Last month ": " Mwedzi wapera ",
        "Next month": " Mwedzi unouya ",
        months: " mwedzi ",
        "Last year": " Gore rakapera ",
        "Next year": " Gore rinotevera ",
        years: " makore ",
        "Last century": "Senturi yadarika",
        "Next century": " Inotevera zana ",
        centuries: " Izvozvi",
        ago: "mazana emakore ",
        "Just now": " apfuura izvozvi ",
        " From now ": " Kubva zvino ",
        "Your Full Name and Email Address is required.": " Ndapota zadza ruzivo pazasi, saka chikwata yedu vanogona kutaura newe Name.",
        "Full Name": " Full Name",
        "Email Address ": " Email Address ",
        Submit: " Submit ",
        Name: "Zita",
        "Start Conversation": "Tanga Kukurukurirana",
        Skip: "Skip",
        Exit: "Buda",
        "Message From": "Meseji Kubva",
        "Do you need any help?": "Do unoda chero rubatsiro? ",
        "Exit Form": " Buda Fomu ",
        'Lets Chat': 'Ngatitaurei!'
    },
    korean: {
        get_started: "시작하다",
        send_message: "메세지를 보내다...",
        reply_message: "답장...",
        delete_conversation: "대화 삭제",
        delete_conversation_message: "이 대화를 삭제하시겠습니까?",
        delete_conversation_title: "확실합니까?",
        delete_conversation_confirm: "예, 삭제!",
        delete_conversation_cancel: "취소",
        "1 second ago": "1초 전",
        seconds: "초",
        "1 minute ago": "1분 전",
        "1 minute from now": "지금부터 1분",
        minutes: "분",
        "1 hour ago": "1 시간 전",
        "1 hour from now": "지금부터 1시간",
        hours: "시간",
        Yesterday: "어제",
        Tomorrow: "내일",
        days: "날",
        "Last week": "지난주",
        "Next week": "다음주",
        weeks: "주",
        "Last month": "지난 달",
        "Next month": "다음 달",
        months: "개월",
        "Last year": "작년",
        "Next year": "내년",
        years: "연령",
        "Last century": "지난 세기",
        "Next century": "다음 세기",
        centuries: "수세기",
        ago: "~ 전에",
        "Just now": "방금",
        "From now": "지금부터",
        "Your Full Name and Email Address is required.": "아래 정보를 입력하시면 저희 팀에서 연락을 드릴 수 있습니다.",
        "Full Name": "성명",
        "Email Address": "이메일 주소",
        Submit: "제출하다",
        Name: "이름",
        "Start Conversation": "대화 시작",
        Skip: "건너 뛰다",
        Exit: "출구",
        "Message From": "메시지 보낸 사람",
        "Do you need any help?": "도움이 필요한가요?",
        "Exit Form": "종료 양식",
        'Lets Chat': '채팅하자!',
    }
}

export default languageArray
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import LocalForage from 'localforage';

import mutations from './mutations';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import * as getters from './getters';
import { getDefaultState } from './shared';

Vue.use(Vuex);

const vuexDB = new VuexPersist({
  key: 'vuex',
  storage: LocalForage, // localForage will fall back to localstorage for old browsers
  asyncStorage: true,
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: () => ({}),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  plugins: [vuexDB.plugin],
  state: {
    ...getDefaultState(),
  },
  actions: {
    ...actions,
  },
  mutations: {
    ...mutations,
  },
  getters: {
    ...getters,
  },
});

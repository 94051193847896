<template>
    <div class="emoji_picker bottom-12" :class="botConfigs.configs['website-chatbot-move-left'] === '1' ? 'left' : 'left'">
        <div class="absolute top-2 right-0 z-10 cursor-pointer" @click.prevent="$emit('closePicker')">
            <svg class="h-4 w-4 mx-2" xmlns="http://www.w3.org/2000/svg" width="16.413" height="17.413" stroke="#000"
                viewBox="0 0 16.413 17.413">
                <g id="line" transform="translate(0.587 1.061)">
                    <line id="Line_7" data-name="Line 7" x2="15" y2="16" transform="translate(0.12 -0.354)"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <line id="Line_8" data-name="Line 8" x1="15" y2="16" transform="translate(0.12 -0.354)"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </g>
            </svg>
        </div>
        <div class="picker_container no-scrollbar">
            <div class="category" v-for="category in categories" :key="`category_${category}`">
                <span>{{ category }}</span>
                <div class="emojis_container">
                    <button @click="handleEmojiClick($event, emoji)" v-for="(emoji, index) in category_emojis(category)"
                        :key="`emoji_${index}`">
                        {{ emoji }}
                    </button>
                </div>
            </div>
        </div>
        <div class="bottom_arrow" :class="botConfigs.configs['website-chatbot-move-left'] === '1' ? 'left':'left'"
            v-if="show_arrow"></div>
    </div>
</template>

<script>

/**
 * Emoji Picker
 * Load emojis and  categories from the json file 'emojis-data.json'
 * Events:
 *  - 'emoji_click' event is fires when the user clicks on an emoji. The emoji is sent as event payload.
 * Props:
 * 	- 'show_arrow' boolean to show or not the arrow at the bottom of the picker. True by default.
 */

import data from './emojis-data.json';

export default {
    props:
    {
        show_arrow:
        {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed:
    {
        categories() {
            return Object.keys(data);
        },

        category_emojis: () => (category) => {
            return Object.values(data[category]);
        }
    },
    methods:
    {
        handleEmojiClick(e, emoji) {
            e.preventDefault();
            this.$emit('emoji_click', emoji);
            this.$emit('closePicker');
        }
    }
}
</script>

<style lang="scss" scoped>
.emoji_picker {
    position: absolute;
    bottom: 8%;
    display: flex;
    flex-direction: column;
    width: 20rem;
    height: 20rem;
    max-width: 100%;

    &.left {
        left: 5%;
    }

    &.right {
        left: -35%;
    }
}

.emoji_picker,
.bottom_arrow {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .0975);
}

.emoji_picker,
.picker_container {
    border-radius: 0.5rem;
    background: white;
}

.picker_container {
    position: relative;
    padding: 1rem;
    overflow: auto;
    z-index: 1;
}

.category {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    color: rgb(169, 169, 169);
}

.emojis_container {
    display: flex;
    flex-wrap: wrap;
}

.category button {
    margin: 0.5rem;
    margin-left: 0;
    background: inherit;
    border: none;
    font-size: 1.75rem;
    padding: 0;
}

.bottom_arrow {
    position: absolute;
    bottom: 0;
    width: 0.75rem;
    height: 0.75rem;
    background: white;

    &.left {
        left: 0%;
        transform: translate(50%, 50%) rotate(45deg);
    }

    &.right {
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
    }
}
</style>

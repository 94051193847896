<template>
    <div v-bind:id="(inlineTarget) ? 'botsify-inline-widget-div' : 'bcw5611_ID'" :class="[(inlineTarget) ? '' : 'bg-white']" 
    :style="{direction: (botConfigs.configs['website-chatbot-preferred-language'] === 'arabic')?'rtl':'ltr',
    backgroundColor: inlineBGColor,
    'overflow': 'visible'
    }">
        <transition name="slide">
            <Chat :selector="selector" key="2" />
        </transition>
    </div>
</template>

<script>
import Chat from './Chat.vue';
import { mapGetters } from 'vuex';

export default {
    components: { Chat },
    computed: {
        ...mapGetters(['selector', 'botConfigs', 'inlineTarget', 'inlineBGColor']),
    },
    data() {
        return {
            selection: 0,
        };
    },

}
</script>

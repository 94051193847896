export const FETCH_BOT_CONFIG = 'FETCH_BOT_CONFIG'
export const FETCH_BOT_SELECTION = 'FETCH_BOT_SELECTION'
export const FETCH_MESSAGE = 'FETCH_MESSAGE'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SET_SENDER_MESSAGE = 'SET_SENDER_MESSAGE'
export const SEND_POSTBACK = 'SEND_POSTBACK'
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION'
export const FETCH_INIT_MESSAGES = 'FETCH_INIT_MESSAGES'
export const CREATE_USER = 'CREATE_USER';
export const GETSTARTED = 'GETSTARTED';
export const SCROLLDOWN = 'SCROLLDOWN';
export const FILEUPLOAD = 'FILEUPLOAD';
export const INLINE_FORM_UPLOAD = 'INLINE_FORM_UPLOAD';
export const SHOWING_WINDOW = 'SHOWING_WINDOW';
export const SET_EMBED_USER_PARAMS = 'SET_EMBED_USER_PARAMS';
export const UPDATE_MESSENGER_USER = 'UPDATE_MESSENGER_USER';
export const REQUEST_HUMAN_HELP = 'REQUEST_HUMAN_HELP';
export const TYPING_DELAY = 'TYPING_DELAY';
export const LOAD_WIDGET = 'LOAD_WIDGET';
export const STRIPE_CHARGE = 'STRIPE_CHARGE';
export const PAGE_MESSAGE_CLOSED = 'PAGE_MESSAGE_CLOSED';
export const UPDATE_CSAT = 'UPDATE_CSAT';
export const SUBMIT_CSAT_FEEDBACK = 'SUBMIT_CSAT_FEEDBACK';
export const SET_BOT_CONFIG = 'SET_BOT_CONFIG';
export const RESET_STATE = 'RESET_STATE';
export const SET_BOT_SELECTION = 'SET_BOT_SELECTION';
export const SET_NEW_FB_ID = 'SET_NEW_FB_ID';
export const SET_BOT_KEY = 'SET_BOT_KEY';
export const SET_MESSAGES = 'SET_MESSAGES';
export const UPDATE_SHOW_MENU = 'UPDATE_SHOW_MENU';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export const UPDATE_BOT_CONFIG = 'UPDATE_BOT_CONFIG';
export const SET_BOT_TYPING = 'SET_BOT_TYPING';
export const SET_PUSHER_CHANNEL = 'SET_PUSHER_CHANNEL';
export const SET_SHOWING_WINDOW = 'SET_SHOWING_WINDOW';
export const SET_STORY_ID = 'SET_STORY_ID';
export const SET_BOT_COLOR = 'SET_BOT_COLOR';
export const SET_INLINE_TARGET = 'SET_INLINE_TARGET';
export const CHATBOT_LOADED = 'CHATBOT_LOADED';
export const SET_FORM_ERROR_MSG = 'SET_FORM_ERROR_MSG';
export const SET_CSAT = 'SET_CSAT';
export const RESET_CSAT = 'RESET_CSAT';
export const UPDATE_CSAT = 'UPDATE_CSAT';
export const SET_CSAT_SUBMITTED = 'SET_CSAT_SUBMITTED';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { messages } from '@/lang/all';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true, // process.env.NODE_ENV === 'production',
  messages,
});

export const setLocaleMessages = (locale, newMessages) => {
  const mergedMessages = {
    ...i18n.getLocaleMessage(locale),
    ...newMessages,
  };
  i18n.setLocaleMessage(locale, mergedMessages);
};

export const setLang = (lang) => {
  i18n.locale = lang;
};

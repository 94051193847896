<template>
  <div >
    <Popup class="cursor-auto" v-if="popUp && popUpMessage!==''" :popUpStory="popUpStory" :popUpMessage="popUpMessage" @toggleState="toggleState" @closePopup="closePopup" />
    <div class="wpic-launcher"
      :class="botConfigs.configs['website-chatbot-move-left'] === '1' ? 'wpic-left' : 'wpic-right'"
      :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }" id="wpic-launcher-child"
      @click="toggleState"
      >
      <div
        v-if="botConfigs.configs['website-chatbot-bot-image'] !== '' && botConfigs.configs['website-chatbot-default-launcher'] != 1"
        class="bg-center bg-no-repeat bg-cover wpic-launcher-open"
        :style="'background-image: url(' + botConfigs.configs['website-chatbot-bot-image'] + ');width: 54px;height: 54px;top: 3px;left: 3px;border-radius: 50%;'"
        id="wpic-launcher-open"></div>
      <div v-else-if="botConfigs.configs['website-chatbot-icon-type'] === 'default'" class="wpic-launcher-open"
        id="wpic-launcher-open" >
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 96.000000 96.000000"
          preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path
              d="M105 855 l-25 -24 0 -278 0 -277 63 62 63 62 212 0 c283 0 262 -19 262 240 0 189 0 191 -25 215 l-24 25 -251 0 -251 0 -24 -25z" />
            <path
              d="M720 569 c0 -197 -16 -209 -269 -209 l-171 0 0 -55 c0 -46 4 -60 25 -80 l24 -25 218 0 217 0 58 -57 58 -57 0 272 0 273 -25 24 c-20 21 -34 25 -80 25 l-55 0 0 -111z" />
          </g>
        </svg>
      </div>
      <div v-else-if="botConfigs.configs['website-chatbot-icon-type'] === 'bubble'" 
        class="wpic-launcher-open" id="wpic-launcher-open"><svg enable-background="new 0 0 64 64" height="34px"
          id="Layer_1" version="1.1" viewBox="0 0 64 64" width="34px" xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g>
            <path
              d="M38.501,7.69c-10.944,0-19.815,8.871-19.815,19.816c0,10.859,8.736,19.673,19.562,19.809v8.43l13.54-13.539   c4.01-3.626,6.53-8.869,6.53-14.7C58.316,16.561,49.444,7.69,38.501,7.69z" />
            <path
              d="M21.882,46.654v-2.405l-1.975-0.024c-5.638-0.07-10.224-4.714-10.224-10.353c0-4.645,3.073-8.585,7.292-9.894   c0.245-1.498,0.644-2.945,1.179-4.325C11.128,20.58,5.683,26.595,5.683,33.871c0,7.146,5.315,13.141,12.199,14.19v8.248   l9.871-9.871c-1.181-0.676-2.292-1.456-3.323-2.332L21.882,46.654z" />
          </g>
        </svg></div>
      <div v-else-if="botConfigs.configs['website-chatbot-icon-type'] === 'reverse'" 
        class="wpic-launcher-open" id="wpic-launcher-open"><svg height="34" viewBox="0 0 24 24" width="34"
          xmlns="http://www.w3.org/2000/svg">
          <path class="heroicon-ui"
            d="M6 14H4a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v13a1 1 0 0 1-1.7.7L16.58 18H8a2 2 0 0 1-2-2v-2zm0-2V8c0-1.1.9-2 2-2h8V4H4v8h2zm14-4H8v8h9a1 1 0 0 1 .7.3l2.3 2.29V8z" />
        </svg></div>
      <div v-else class="wpic-launcher-open" id="wpic-launcher-open"><svg
          enable-background="new 0 0 500 500" height="34px" id="Layer_1" version="1.1" viewBox="0 0 500 500" width="34px"
          xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <path clip-rule="evenodd"
            d="M36.992,326.039c0,20.079,16.262,36.34,36.34,36.34h54.513v56.062  c0,10.087,8.181,18.168,18.172,18.168c5.092,0,9.714-2.095,12.989-5.448l68.78-68.781h199.881c20.078,0,36.34-16.261,36.34-36.34  V98.902c0-20.079-16.262-36.341-36.34-36.341H73.333c-20.079,0-36.34,16.262-36.34,36.341V326.039z M146.018,221.557  c0-12.536,10.177-22.713,22.713-22.713c12.536,0,22.713,10.177,22.713,22.713c0,12.537-10.177,22.713-22.713,22.713  C156.194,244.27,146.018,234.093,146.018,221.557z M227.787,221.557c0-12.536,10.177-22.713,22.713-22.713  c12.537,0,22.715,10.177,22.715,22.713c0,12.537-10.178,22.713-22.715,22.713C237.964,244.27,227.787,234.093,227.787,221.557z   M309.556,221.557c0-12.536,10.176-22.713,22.715-22.713c12.537,0,22.711,10.177,22.711,22.713  c0,12.537-10.174,22.713-22.711,22.713C319.731,244.27,309.556,234.093,309.556,221.557z"
            fill="#010101" fill-rule="evenodd" />
        </svg></div>
      <div class="wpic-launcher-close" >
        <svg style="position:initial;" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 17 17">
          <path
            d="M9.207 8.5l6.646 6.646-.707.707L8.5 9.207l-6.646 6.646-.707-.707L7.793 8.5 1.146 1.854l.707-.707L8.5 7.793l6.646-6.646.707.707L9.207 8.5z" />
        </svg>
      </div>
    </div>
  </div>  
</template>
<script>
const AWS_SOUND = process.env.VUE_APP_SOUND_ENDPOINT;
import { mapGetters } from 'vuex';
import Popup from "./screen/Popup.vue"
export default {
  name: 'Launcher',
  components: {
    Popup,
  },
  data() {
    return {
      AWS_SOUND,
      isPopupShown: false,
      configs: [],
      popUp: false,
      popUpStory: null,
      popUpMessage: '',
      heightSet: false,
      timeOutFunc: undefined
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'getMessages']),
  },
  mounted() {
    const that = this;
    const fireUpElement = document.querySelector('.fireup-bcw, .fireup-botsify-chat');
    if(fireUpElement) {
      const fireUpelems = document.querySelectorAll('.fireup-bcw, .fireup-botsify-chat');
      fireUpelems.forEach(function(el){
        el.addEventListener('click', function(){
          that.toggleState();
        });
      });   
    }
    if (this.botConfigs.configs['website-chatbot-landing'] && this.botConfigs.configs['website-chatbot-landing'] === '1') {
      this.toggleState();
    } else if (this.botConfigs.configs['website-chatbot-popup'] === '1') {
      if (this.isMobile && !this.botConfigs.configs['mobile-no-chatbot-popup']) {
        this.toggleState();
      } else if (!this.isMobile) {
        this.toggleState();
      }
    }
    if (this.botConfigs.webbot_page_message) {
      if(this.botConfigs.webbot_page_message.show_message_after === 'delay') {
        this.timeOutFunc = setTimeout(() => {
          this.showPopupMessage(this.botConfigs.webbot_page_message);
        }, this.botConfigs.webbot_page_message.timeout * 1000);
      } else if(this.botConfigs.webbot_page_message.show_message_after === 'scroll'){
        window.addEventListener("scroll", ()=>{
          this.handleScroll(this.botConfigs.webbot_page_message);
        });
      }
    }
  },
  methods: {
    showPopupMessage(popUpDetails){
      if (this.botConfigs.configs["website-chatbot-popup-once"] === '1') {
        if(window.bcw_widget.bcwlocalstore.get('allow-popup') === null){
          this.popUp = true;
          this.playSound();
          window.bcw_widget.bcwlocalstore.set('allow-popup', false)
        }
      } else{
        this.popUp = true;
        this.playSound();
      }
      this.popUpMessage = popUpDetails.html;
      if (this.botConfigs.popupBotStory) {
        this.popUpStory = this.botConfigs.popupBotStory;
      }
    },
    handleScroll(popUpDetails) {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var scrollPercent = (scrollTop / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      if (!this.isPopupShown && scrollPercent >= popUpDetails.timeout) {
        this.showPopupMessage(popUpDetails);
        this.isPopupShown = true;
        window.removeEventListener("scroll", this.handleScroll);
      }
    },
    playSound() {
      if(this.botConfigs.configs['page-message-sound'] == '0') {
        // window.bcw_widget.BCWSound(this.botConfigs.configs['page-message-sound'], 1, false);
      } else if(this.botConfigs.configs['page-message-sound']) {
        window.bcw_widget.BCWSound(this.botConfigs.configs['page-message-sound'], 1, false);
      } else {
        window.bcw_widget.BCWSound(this.AWS_SOUND + '/sounds/ding.mp3', 1, false);
      }
    },
    closePopup(payload) {
      if(payload !== undefined) {
        this.$store.dispatch('PAGE_MESSAGE_CLOSED', payload);
      }
      this.popUp = false
    },
    async toggleState() {
      clearTimeout(this.timeOutFunc)
      this.closePopup()
      document
        .getElementById("wpic-launcher-child")
        .classList.toggle("botsify-active");

      const widget_div = document.getElementById("bcw5611_ID");
      const isLeft = this.botConfigs.configs['website-chatbot-move-left'] === '1'
      if (this.isMobile === false) {
        widget_div.classList.toggle("widget-div-active");

        widget_div.classList.toggle(isLeft ? 'widget-div-active-left' : 'widget-div-active-right')
        widget_div.style.setProperty("width", "100%", "important");
      } else {
        widget_div.style.setProperty("width", '100vw', "important");
        widget_div.style.setProperty("max-width", '100vw', "important");
        widget_div.style.setProperty("left", '0px', "important");
        widget_div.style.setProperty("bottom", '0px', "important");
        widget_div.classList.toggle("widget-div-active");
        if (this.botConfigs.configs['mobile-chatbot-height']) {
          widget_div.style.setProperty("height", this.botConfigs.configs['mobile-chatbot-height'] + 'dvh', "important");
          widget_div.style.setProperty("max-height", this.botConfigs.configs['mobile-chatbot-height'] + 'dvh', "important");
        } else {
          //widget_div.style.setProperty("height", "100vh", "important");
          widget_div.style.setProperty("min-height", "-webkit-fill-available", "important");
        }
      }
      if (this.heightSet === false  && this.isMobile === false && this.botConfigs.configs['website-chatbot-height'] != 100) {
        const elemStyles = getComputedStyle(widget_div);
        const newHeight =  parseInt(elemStyles.height, 10)*((this.botConfigs.configs['website-chatbot-height']) / 100);
        this.$store.dispatch('SHOWING_WINDOW', !isNaN(newHeight));
        widget_div.style.setProperty("height", newHeight + 'px', "important");
        if(this.botConfigs.configs['website-chatbot-height'] < 100) {
          widget_div.style.setProperty("overflow-y",'scroll', "important");
        }
      } else {
        this.$store.dispatch('SHOWING_WINDOW', true);
      }

      if (this.heightSet === false  && this.botConfigs.configs['website-chatbot-landing'] && this.botConfigs.configs['website-chatbot-landing'] === '1') {
        widget_div.style.setProperty("max-width", "100%", "important")
        widget_div.style.setProperty("max-height", "100%", "important")
        widget_div.style.setProperty("height", "100%", "important")
        widget_div.style.setProperty("width", "100%", "important")
        widget_div.style.setProperty("right", "0px", "important")
        widget_div.style.setProperty("bottom", "0px", "important")
      }

      if(this.getMessages.length == 0 && widget_div.classList.contains("widget-div-active") && !(this.botConfigs.configs['website-chatbot-landing'] && this.botConfigs.configs['website-chatbot-landing'] === '1')) {
        this.$store.dispatch('FETCH_INIT_MESSAGES');
      }
      this.heightSet = true;
    },
  },
};
</script>

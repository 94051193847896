/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-nested-ternary */
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import setup from '@/helpers/setup';;
import App from './App.vue';
import store from './store/index';
import { i18n } from './helpers/i18n-setup';
// plugins
import './assets/tailwind.css';
import './assets/widget.scss';


//import 'document-register-element/build/document-register-element';

Vue.directive('expand', {
    inserted: function (el, binding) {
        if (binding.value !== null) {
            function calcHeight() {
                const currentState = el.getAttribute('aria-expanded')

                el.classList.add('u-no-transition')
                el.removeAttribute('aria-expanded')
                el.style.height = null
                el.style.height = el.clientHeight + 'px'
                el.setAttribute('aria-expanded', currentState)

                setTimeout(function () {
                    el.classList.remove('u-no-transition')
                })
            }

            el.classList.add('expand')
            el.setAttribute('aria-expanded', (binding.value) ? 'true' : 'false')
            calcHeight()
            window.addEventListener('resize', calcHeight)
        }
    },
    update: function (el, binding) {
        if (el.style.height && binding.value !== null) {
            el.setAttribute('aria-expanded', (binding.value) ? 'true' : 'false')
        }
    },
})

Vue.mixin({
    computed: {
        botConfigs() {
            return store.getters.botConfigs
        },
        roundedDisabler() {
            return !(this.botConfigs.configs['website-chatbot-rounded-blocks'] !== undefined && this.botConfigs.configs['website-chatbot-rounded-blocks'] === '1')
        }
    },
})


// Setup embed
setup();

// Configure Components
Vue.use(vueCustomElement);

Vue.config.productionTip = false;
// bypass initial request
App.store = store;
App.i18n = i18n;
Vue.customElement('bcw-widget', App);

<template>
    <div class="block mx-3 my-1 shadow-sm chatBox rtl message-animation"
        :style="{ background: botConfigs.configs['website-chatbot-primary-color'], fontSize: botConfigs.configs['website-chatbot-font-size'] }"
        :class="{ 'p-2': message.text, 'rounded-bl-3xl': roundedDisabler, 'rounded-t-3xl': roundedDisabler }">
        <div class="self-center break-words mx-2 font-normal text-sm" v-if="message.text"
            :class="botConfigs.configs['website-chatbot-primary-color-scheme'] ? 'text-white' : 'text-white'">
            {{ message.text }}
        </div>
        <div class="self-center" v-if="message.attachments" v-for="attachment in message.attachments" >
            
            <div v-if="attachment.type === 'image'" v-bind:style="{ backgroundImage: 'url(' + attachment.payload.url + ')' }"  class="bg-cover bg-center w-64 h-64"
                :class="{ 'rounded-bl-xl': roundedDisabler, 'rounded-t-xl': roundedDisabler }" >
            </div>

            <div v-if="attachment.type === 'file'" class="flex flex-col w-full p-1 h-auto" 
                :class="{ 'rounded-bl-xl': roundedDisabler, 'rounded-t-xl': roundedDisabler }" >
                <span class="font-semibold text-sm text-white text-left p-1">{{ $t('openFile') }}</span>
                <a :href="attachment.payload.url" class="p-1" target="_blank">
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEKElEQVR4nO2b20/TUBzHq3+A8cUXEx980xjfTHwwxkg73HpAcKd1S7ygJuINL6B4CwbQGH3QiIhcFTXe4iUmBsS7RoSIgcQoonLxCmhUQBCdqBs/c5zdjnEbG7Zd1/aTnLDCDofvp7+2p92BYUxMTCLBap03iuWFLBbhBhbhLxwSQK3mHQ83cwhXckhcwc12jmXUxMLjqSwS3qoZOrQQYYDlcZ7VKo5RPHx8gn0Ch3BvtEMHbvidxWafpqgADgm3pAHnOBdB1dWb0N3dA2pw7OQZX9i9+UVQUXUV0tI3/10NPP5uQeIcZcLzjonSQLNmO+BZS6sqwQMJIK8l7tTcA7tzsV8Ej/s5mzhZdgEsElOlQbJyd6saPpQAQkfnW3AuTKUkCA+zs7NHyiuAF7KkAQ4fPam6gBOnz0Oo8VvanoM1yUmfHBfKKwDhnGB7QA0uXbnuC5eVuyvge8rKj9MC6nUl4PWbDqDPQU+b/z0HdXX3/P7Zn/cNxic5xulGAGHD1hyfhGRHClRdufE7NM2a9Vv9VZAgOnQl4E17JyTPTQl/ksSLmboSQCCl71iwNNzJ0Q7dCSB8dbng1NkLsGrdZkjE84PsfaHRkiiO16WAMOiTLbiEKQDFVAX0MkYV4Ha7ofLyNVccEpwMw4wwnICLFZd9J8K4BHuy4QQUlx2lJ0IZpoBoVoBnEKCpG+Bup/cr2Q6X4fYtoiuAx+lRE9D3HSDvAUB6tb+RbfJ9JftqRkBx498BpFbSCIr21YSAD67AAaTW9U2ZvoTC0iP0lHhdVAS87Asd4tVnZfpqRoDrJ0BmTeAAG2sAvrmV6Us4WFJOCRDXRkUA4VZ74BC320HRvpoRQKh7B5BT5/3jc+97t8NluH01JUDCHcH1/3/70gJYHq/RhAA1MbyAguLD9FOh1YargAKtCvAMei9xkbQBt04E/PB4z+ShJjjBWuXLyAQcKDpEXwXSNCHgpyf45GaodrZVBwIIpUFucIZqj7tAHwJaPkUefldDZM8QCPmUAIsNr9KMAMK51vDDk0NmqBufmBPgGQQ48Wzo8JtqIy99n4DCMvoqsFJTAiQa3gPsrP83eEY1QHmT95nAcIkJAQRyaHf0A9S/9z73e/gRoP8H/DcxI0Ap9h8spa8CK0wBRquAvAKqAhLwclOAXLBmBeAYOQRK/B+O8sIyA1ZAibEF7DtQTC2WFFNNAXLBGr4C+Ogulg6XPXmF1CGAlyiyXH7bdvWXy4dLWsYW/0nQhm2yCZiZiCdJv5gsSyfLVrXG7epaau8LAzOSkkYzcsIhoVYaIGXpamhtexHtzNDb2weNTU9h9958iE+cS98JFskansDZ8BRilvr4WaMNP5J970twPLZrXEKF4v9Cx/GOiSwSzrAI90Q9MI/7WYSfcAgfikN4uqLBTUwYXfILp1eHqcCOj0cAAAAASUVORK5CYII=">
                </a>
            </div>

        </div>
        <div class="self-center" v-if="message.attachment && message.attachment.type === 'image'" >
            <div v-if="!message.attachment.payload" v-bind:style="{ backgroundImage: 'url(' + message.attachment.url + ')' }"  class="bg-cover bg-center w-64 h-64"
                :class="{ 'rounded-bl-xl': roundedDisabler, 'rounded-t-xl': roundedDisabler }" >
            </div>
            <div v-else-if="message.attachment.payload" v-bind:style="{ backgroundImage: 'url(' + message.attachment.payload.url + ')' }" style="border:1px solid #ccc"  class="bg-cover bg-center w-64 h-64"
                :class="{ 'rounded-bl-xl': roundedDisabler, 'rounded-t-xl': roundedDisabler }" >
            </div>
        </div>
        
        
        <div class="grid grid-cols-3" v-if="cards && cards.length">
            <div v-for="(card, idx) in cards" :key="idx" class="block p-1 m-1 bg-blue-300 text-white shadow-sm"
                :class="{ 'rounded-lg': roundedDisabler }">
                <div class="inline-flex">
                    <div>{{ card.text }}</div>
                    <div>{{ card.emoji }}</div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-3" v-if="gallery && gallery.length">
            <div v-for="(image, idx) in gallery" :key="idx" class="block p-1 m-1 shadow-sm"
                :class="{ 'rounded-lg': roundedDisabler }">
                <img :src="image" class="w-10 h-10" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: Object,
            required: true,
            default: () => { }
        },
        cards: {
            type: Array,
            required: false,
            default: () => []
        },
        gallery: {
            type: Array,
            required: false,
            default: () => []
        },
        avatarDisabled: {
            required: false,
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <div class="bg-white">
        <div class="backgroundGradient h-24">
            <div class="absolute right-0 top-2 w-24 inline-flex">
                <img :src="AWS_URL + '/Minimize.svg'" class="h-4 w-4 mx-2" />
                <img :src="AWS_URL + '/Reset.svg'" class="h-4 w-4 mx-1" />
                <img :src="AWS_URL + '/Cross.svg'" class="h-4 w-4 mx-2" />
            </div>
            <div class="grid grid-flow-col pt-8 px-2">
                <img :src="AWS_URL + '/Back.svg'" class="col-span-1 self-center cursor-pointer"
                    @click.prevent="$emit('tabSelection', 0)" />
                <div class="col-span-5 ">
                    <div class="grid grid-flow-col justify-start">
                        <img :src="AWS_URL + '/avatar.png'" class="self-center" />
                        <div class="grid grid-flow-row-dense text-white pl-3 align-middle">
                            <span class="font-semibold leading-tight ">{{$t('janetFowler')}}</span>
                            <span class="font-thin leading-tight">{{$t('onlineNow')}}}</span>
                        </div>
                    </div>
                </div>
                <img :src="AWS_URL + '/Menu.svg'" class="col-span-1 justify-self-end self-center pr-2" />
            </div>
        </div>
        <div class="chatHeight overflow-y-auto pl-2">
            <div v-for="(chat, idx) in chats" :key="idx">
                <Receiver v-if="chat.id === 1" :avatar-disabled="idx === 0 ? false : chats[idx - 1].id === 1"
                    :message="chat.message" />
                <div class="flex justify-end" v-else>
                    <Sender :message="chat.message" />
                </div>
            </div>
        </div>
        <div class="mx-3 my-1 bg-gray-100 px-2 rounded-3xl grid grid-flow-col-dense items-center">
            <img class="h-6 w-6" :src="AWS_URL + '/Smiley.svg'" />
            <input type="text" class="h-9 border-none bg-transparent focus:outline-none"
                :placeholder="$t('typeMessage')" />
            <img class="h-6 w-6" :src="AWS_URL + '/Attachements.svg'" />
            <img class="h-6 w-6" :src="AWS_URL + '/Microphone.svg'" />
            <img class="h-6 w-6" :src="AWS_URL + '/Send.svg'" />
        </div>
    </div>
</template>

<script>
const AWS_URL = process.env.VUE_APP_S3_ENDPOINT;
import Sender from "./messages/Sender.vue"
import Receiver from "./messages/Receiver.vue"

export default {
    components: {
        Sender, Receiver
    },

    data() {
        return {
            AWS_URL,
            chats: [
                {
                    id: 1,
                    message: "Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world ",
                    image: ""
                },
                {
                    id: 1,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 1,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 2,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 2,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 1,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 2,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 1,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 1,
                    message: "Hello world",
                    image: ""
                },
                {
                    id: 2,
                    message: "Hello world",
                    image: ""
                },
            ],
            loggedInUserID: 1
        }
    },
}
</script>
import * as getters from './getters';
import * as types from './mutation-types';
import { getDefaultState } from './shared';

const setBotSelection = (state, payload) => {
  state.selection = payload
}

const setFormErrorMsg = (state, payload) => {
  state.formErrorMsg = payload
}

const setBotKey = (state, payload) => {
  state.bot_key = payload;
}

const setCsat = (state, payload) => {
  state.showCsat = payload;
}

const setCsatSubmitted = (state, payload) => {
  state.csatSubmitted = payload;
}


const setStoryID = (state, payload) => {
  state.story_id = payload;
}

const setBotColor = (state, payload) => {
  state.rgba = payload;
}

const setInlineTarget = (state, payload) => {
  state.target_div = payload;
}

const updateBotConfig = (state, payload) => {
  state.botConfigs = {
    ...state.botConfigs,
    ...payload,
  }
}

const resetCSAT = (state) => {
  state.CsatForm = {
    feedback: '',
    responses: [],
    web_message_id: null,
    score: 0,
  }
}

const setNewFBID = (state, payload) => {
  state.fbId = payload
}

const setShowMenu = (state, payload) => {
  state.showMenu = payload
}


const setMessages = (state, payload) => {
  if (payload.length > 1) {
    const holder = []
    payload.forEach(element => {
      holder.push([element])
    });
    state.messages.push(...holder)
  }
  else state.messages.push(payload)
}

const deleteConversation = (state) => {
  state.messages.splice(0);
}

const setBotTyping = (state, payload) => {
  state.typing = payload;
}

const setPusherChannel = (state, payload) => {
  state.channel = payload;
}

const setShowingWindow = (state, payload) => {
  state.showingWindow = payload;
}

const updateCSAT = (state, payload) => {
  state.CsatForm = {
    ...state.CsatForm,
    ...payload,
  }
}

const chatBotLoaded = (state, payload) => {
  state.chatBotLoaded = payload;
}



export default {
  [types.RESET_STATE](state) {
    Object.assign(state, {
      ...getDefaultState(),
    });
  },
  [types.SET_BOT_CONFIG](state, payload) {
    state.botConfigs = payload;
  },
  [types.SET_BOT_SELECTION]: setBotSelection,
  [types.SET_NEW_FB_ID]: setNewFBID,
  [types.SET_BOT_KEY]: setBotKey,
  [types.SET_FORM_ERROR_MSG]: setFormErrorMsg,
  [types.SET_MESSAGES]: setMessages,
  [types.UPDATE_SHOW_MENU]: setShowMenu,
  [types.DELETE_CONVERSATION]: deleteConversation,
  [types.UPDATE_BOT_CONFIG]: updateBotConfig,
  [types.SET_BOT_TYPING]: setBotTyping,
  [types.SET_PUSHER_CHANNEL]: setPusherChannel,
  [types.SET_SHOWING_WINDOW]: setShowingWindow,
  [types.SET_STORY_ID]: setStoryID,
  [types.SET_BOT_COLOR]: setBotColor,
  [types.SET_INLINE_TARGET]: setInlineTarget,
  [types.CHATBOT_LOADED]: chatBotLoaded,
  [types.SET_CSAT]: setCsat,
  [types.RESET_CSAT]: resetCSAT,
  [types.UPDATE_CSAT]: updateCSAT,
  [types.SET_CSAT_SUBMITTED]: setCsatSubmitted,
};

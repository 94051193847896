<template>
    <div id="bcw-chatbot-menu" style="min-width:180px;z-index:100;max-height:250px;overflow-y:scroll" class="absolute grid grid-flow-row bg-white shadow-lg"
        :class="[(roundedDisabler)?'rounded-lg':'', (inlineTarget)?'bottom-16 right-4':'top-16 right-4' ]">
        <div class="w-full text-center border-gray-300 border-b-1 hover:shadow-sm" v-for="(item, idx) in menuItems"
            :key="idx">
            <div v-if="item.type == 'postback'" class="grid px-2 py-3 cursor-pointer place-content-center"
                @click.prevent="sendPostback(item.button.payload, item.title)">{{ item.title }}</div>
            <a v-if="item.type == 'web_url'" class="grid px-2 py-3 place-content-center"
                :href="item.button.general_response.response" target="_blank">
                {{ item.title }}
            </a>
        </div>
        <div v-if="languagesOption && languagesOption.length"
            class="w-full text-center-border-gray-300 border-b-1 hover:shadow-md">
            <div class="grid grid-flow-col px-2 py-3 cursor-pointer place-content-center"
                @click.prevent="showLanguages = !showLanguages"> {{$t('languages')}} <img class="w-6 h-6"
                    :class="{ 'languageActive': showLanguages, 'languageInActive': !showLanguages }"
                    :src="`${AWS_URL}/chevron.svg`" alt="...">
            </div>
        </div>
        <div v-if="languagesOption && languagesOption.length" v-expand="showLanguages"
            class="w-full text-center expand ">
            <div v-for="lang in languagesOption" :key="lang" class="border-gray-300 border-b-1 hover:shadow-sm">
                <div class="grid grid-flow-col px-2 py-3 cursor-pointer place-content-center"
                    @click.prevent="setLocale(lang)"> <img class="w-6 h-6 mr-2"
                        :src="`${AWS_URL}/Flags/${flags[lang]}.svg`" >
                    {{ lang }}
                </div>
            </div>
        </div>
        <div v-if="!deleteConversation"
            class="flex items-center justify-center w-full h-10 text-white bg-red-600 cursor-pointer hover:shadow-sm"
            :class="{ 'rounded-t-lg': !menuItems.length, 'rounded-b-lg': roundedDisabler }"
            @click.prevent="deleteConversationInit()">
            {{ $t('delete_conversation') }}
        </div>
        <div v-else
            class="flex items-center justify-center w-full h-10 text-white bg-red-600 cursor-pointer hover:shadow-sm"
            :class="{ 'rounded-t-lg': !menuItems.length, 'rounded-b-lg': roundedDisabler }"
            @click.prevent="deleteConversationInit()">
            {{ $t('delete_conversation_title') }}
        </div>

    </div>
</template>

<script>
const AWS_URL = process.env.VUE_APP_S3_ENDPOINT_MENU;
import { mapActions } from "vuex";
import { mapGetters } from "vuex"
import * as mutations from '@/store/mutation-types';
import { botConfigs } from "@/store/getters";
import { i18n } from '@/helpers/i18n-setup';

export default {
    props: {
        menuItems: {
            type: Array,
            default: () => [],
            required: false
        },
    },
    data() {
        return {
            AWS_URL,
            deleteConversation: false,
            showLanguages: false,
            flags: {
                English: 'gb',
                Turkish: 'tr',
                Spanish: 'es',
                French: 'fr',
                German: 'de',
            }
        }
    },
    computed: {
        ...mapGetters(['inlineTarget', 'getStoryId', 'botConfigs']),
        languagesOption() {
            if (!this.botConfigs.configs['website-chatbot-menu-languages']) return []
            return this.botConfigs.configs['website-chatbot-menu-languages'].split(',')
        }
    },
    methods: {
        ...mapActions(['DELETE_CONVERSATION', "SET_SENDER_MESSAGE", "SEND_POSTBACK"]),
        async sendPostback(payload, title) {
            if (!payload) return;
            try {
                this.SET_SENDER_MESSAGE(title);
                this.$store.commit(mutations.UPDATE_SHOW_MENU, false);
                await this.SEND_POSTBACK({ 'payload': payload, 'text': title, 'is_getStarted': false });

            } catch (error) {
                console.error(error)
            }
        },
        deleteConversationInit() {
            if (!this.deleteConversation) {
                this.deleteConversation = true;
            } else {
                this.DELETE_CONVERSATION();
                if(this.inlineTarget) {
                    this.$store.dispatch('SEND_POSTBACK', { 'payload': (this.getStoryId)?this.getStoryId:this.botConfigs.getStarted, 'text': i18n.t('startConversation') });
                    //await this.SEND_POSTBACK({ 'payload': payload, 'text': title, 'is_getStarted': false });
                }
            }
        },
        setLocale(lang) {
            this.$i18n.locale = lang.toLowerCase()
            this.$store.commit(mutations.UPDATE_SHOW_MENU, false);
        }
    },
}
</script>
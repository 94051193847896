export const messages = {
  // English
  english: {
    invalidFileUpload: "Invalid file type. Only images, videos and documents are allowed.",
    botActivationAlert: "You haven't replied in a while. You chat will close in {0} minute(s)",
    continueConversation: 'Continue Conversation',
    document: 'Document',
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    image: 'Image',
    delete_conversation: "Delete Conversation",
    delete_conversation_title: "Are you sure?",
    typeMessage: "Type your message",
    replyInTen: "Typically reply in 10 seconds.",
    replyInTenSmall: "Reply in 10 seconds.",
    startMessage: "Let's start a conversation",
    submit: 'Submit',
    exit: 'Exit',
    skip: 'Skip',
    browserNotSupportAudio: 'Your browser does not support the audio element.',
    openFile: 'Open File',
    browserNotSupportVideo: 'Your browser does not support the video tag.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Online now',
    close: 'close',
    messageFrom: 'Message from',
    registerConfirmationText: 'Do you want to register yourself to get a free demo? Looking forward to hearing from you',
    yes: 'Yes',
    noThanks: 'No Thanks',
    sendMessageDots: 'Send a message...',
    collectInformationText: 'We need to collect some information in case we lose you',
    startConversation: 'Start Conversation',
    sendUsMessage: 'Send us a message',
    replySoonText: 'Pick up where you left off',
    uploadingDots: 'Uploading...',
    languages: 'Languages',
    selectDateTime: 'Select DateTime',
    selectDate: 'Select Date',
    enterFullName: 'Enter your full name',
    enterEmailAddress: 'Enter your email address',
    name: 'Name',
    email: 'Email',
    typeQuestion: 'Type your question',
    fillInInformationBelow: 'Please fill in the information below, so our team can contact you',
    csatFormTitle: "Rate your experience!"
  },
  // hebrew
  hebrew: {
    invalidFileUpload: "סוג קובץ לא תקין. רק תמונות ומסמכי PDF מותרים.",
    botActivationAlert: "מזמן לא ענית. הצ'אט שלך ייסגר בעוד {0} דקות",
    continueConversation: 'המשך שיחה',
    document: 'מסמך',
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '5 דולר או 5 דולר',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    image: 'תמונה',
    delete_conversation: "מחק שיחה",
    delete_conversation_title: "האם אתה בטוח?",
    typeMessage: "הקלד את ההודעה שלך",
    replyInTen: "בדרך כלל השב תוך 10 שניות.",
    replyInTenSmall: "השב תוך 10 שניות.",
    startMessage: "בואו נתחיל בשיחה",
    submit: 'שלח',
    exit: 'יציאה',
    skip: 'דלג',
    browserNotSupportAudio: 'הדפדפן שלך לא תומך באלמנט שמע.',
    openFile: 'פתח קובץ',
    browserNotSupportVideo: 'הדפדפן שלך לא תומך בתג וידאו.',
    janetFowler: "ג'נט פאולר",
    onlineNow: 'מקוון כעת',
    close: 'סגור',
    messageFrom: 'הודעה מ-',
    registerConfirmationText: 'האם ברצונך להירשם כדי לקבל הדגמה חינם? מצפים לשמוע ממך',
    yes: 'כן',
    noThanks: 'לא תודה',
    sendMessageDots: 'שלח הודעה...',
    collectInformationText: 'אנו צריכים לאסוף מידע מסוים במקרה שנאבד אותך',
    startConversation: 'התחל שיחה',
    sendUsMessage: 'שלח לנו הודעה',
    replySoonText: 'תמשיך מהיכן שעזבת',
    uploadingDots: 'מעלה...',
    languages: 'שפות',
    selectDateTime: 'בחר תאריך ושעה',
    selectDate: 'בחר תאריך',
    enterFullName: 'הזן את שמך המלא',
    enterEmailAddress: 'הזן את כתובת האימייל שלך',
    name: 'שם',
    email: 'אימייל',
    typeQuestion: 'הקלד שאלה שלך',
    fillInInformationBelow: 'אנא מלא את המידע למטה, כך שצוותנו יוכל ליצור איתך קשר',
    csatFormTitle: "דרג את החוויה שלך!"
  },
  // Greek
  greek: {
    invalidFileUpload: "Μη έγκυρος τύπος αρχείου. Επιτρέπονται μόνο εικόνες και εγγράφα PDF.",
    botActivationAlert: "Δεν έχετε απαντήσει εδώ και κάποιο διάστημα. Η συνομιλία σας θα κλείσει σε {0} λεπτό(α)",
    continueConversation: 'Συνέχιση συνομιλίας',
    document: 'Έγγραφο',
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '5 $ ή 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    image: 'Εικόνα',
    delete_conversation: "Διαγραφή Συνομιλίας",
    delete_conversation_title: "Είστε σίγουρος/η;",
    typeMessage: "Πληκτρολογήστε το μήνυμά σας",
    replyInTen: "Συνήθως απαντάμε σε 10 δευτερόλεπτα.",
    replyInTenSmall: "Απάντηση σε 10 δευτερόλεπτα.",
    startMessage: "Ας ξεκινήσουμε τη συνομιλία",
    submit: 'Υποβολή',
    exit: 'Έξοδος',
    skip: 'Παράλειψη',
    browserNotSupportAudio: 'Ο περιηγητής σας δεν υποστηρίζει το στοιχείο ήχου.',
    openFile: 'Άνοιγμα Αρχείου',
    browserNotSupportVideo: 'Ο περιηγητής σας δεν υποστηρίζει την ετικέτα βίντεο.',
    janetFowler: 'Τζάνετ Φάουλερ',
    onlineNow: 'Σε απευθείας σύνδεση τώρα',
    close: 'κλείσιμο',
    messageFrom: 'Μήνυμα από',
    registerConfirmationText: 'Θέλετε να εγγραφείτε για να λάβετε μια δωρεάν επίδειξη; Ανυπομονούμε να ακούσουμε από εσάς',
    yes: 'Ναι',
    noThanks: 'Όχι Ευχαριστώ',
    sendMessageDots: 'Αποστολή μηνύματος...',
    collectInformationText: 'Πρέπει να συλλέξουμε μερικές πληροφορίες αν και σας χάσουμε',
    startConversation: 'Έναρξη Συνομιλίας',
    sendUsMessage: 'Στείλτε μας ένα μήνυμα',
    replySoonText: 'Συνεχίστε από το σημείο που σταματήσατε',
    uploadingDots: 'Μεταφόρτωση...',
    languages: 'Γλώσσες',
    selectDateTime: 'Επιλέξτε Ημερομηνία και Ώρα',
    selectDate: 'Επιλέξτε Ημερομηνία',
    enterFullName: 'Εισαγάγετε το πλήρες όνομά σας',
    enterEmailAddress: 'Εισαγάγετε τη διεύθυνση email σας',
    name: 'Όνομα',
    email: 'Email',
    typeQuestion: 'Πληκτρολογήστε την ερώτησή σας',
    fillInInformationBelow: 'Παρακαλώ συμπληρώστε τις πληροφορίες παρακάτω, ώστε η ομάδα μας να μπορεί να επικοινωνήσει μαζί σας',
    csatFormTitle: "Βαθμολογήστε την εμπειρία σας!"
  },
  //Shona:
  shona: {
    invalidFileUpload: "Mhando yefaira isiriyo. Mifananidzo uye zvinyorwa zvePDF chete zvinotenderwa.",
    botActivationAlert: "Wave nenguva usina kupindura. Chat inovhara mu {0} miniti(s)",
    delete_conversation: "Delete Hurukuro",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Une chokwadi here?",
    typeMessage: "Nyora meseji yako",
    replyInTen: "Kazhinji pindura mumasekonzi gumi.",
    replyInTenSmall: "Pindura mumasekonzi gumi.",
    startMessage: "Ngatitangei kukurukura",
    continueConversation: 'Ramba Uchikurukurirana',
    document: 'Gwaro',
    image: 'Image',
    submit: 'Submit',
    exit: 'Exit',
    skip: 'Skip',
    browserNotSupportAudio: 'Browser yako haitsigire chinhu chekuteerera.',
    openFile: 'Vhura faira',
    browserNotSupportVideo: 'Browser yako haitsigire vhidhiyo tag.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Online ikozvino',
    close: 'pedyo',
    messageFrom: 'Message kubva',
    registerConfirmationText: 'Iwe unoda kuzvinyoresa wega kuti uwane demo remahara? Kutarisira kunzwa kubva kwauri',
    yes: 'Ehe',
    noThanks: 'Kwete ndatenda',
    sendMessageDots: 'Tumira meseji...',
    collectInformationText: 'Isu tinofanirwa kuunganidza rumwe ruzivo kana tikakurasa',
    startConversation: 'Tanga Kukurukurirana',
    sendUsMessage: 'Titumire meseji',
    replySoonText: 'Simudza pawakasiira',
    uploadingDots: 'Uploading...',
    languages: 'Languages',
    selectDateTime: 'Sarudza DateTime',
    selectDate: 'Sarudza Zuva',
    enterFullName: 'Isa zita rako rizere',
    enterEmailAddress: 'Isa email yako',
    name: 'Zita',
    email: 'Email',
    typeQuestion: 'Nyora mubvunzo wako',
    fillInInformationBelow: 'Ndokumbira zadza ruzivo rwuri pazasi, kuti timu yedu ibate newe',
    csatFormTitle: "Ratidza chiitiko chako!"
  },
  //malay
  malay: {
    invalidFileUpload: "Jenis fail tidak sah. Hanya imej dan dokumen PDF dibenarkan.",
    botActivationAlert: "Dah lama awak tak balas. Sembang anda akan ditutup dalam {0} minit",
    delete_conversation: "Padamkan Perbualan",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Adakah anda pasti?",
    typeMessage: "Taip mesej anda",
    replyInTen: "Biasanya balas dalam 10 saat.",
    replyInTenSmall: "Balas dalam 10 saat.",
    startMessage: "Mari kita mulakan perbualan",
    continueConversation: 'Teruskan Perbualan',
    document: 'Dokumen',
    image: 'Gambar',
    submit: 'Hantar',
    exit: 'Keluar',
    skip: 'Langkau',
    browserNotSupportAudio: 'Penyemak imbas anda tidak menyokong elemen audio.',
    openFile: 'Buka fail',
    browserNotSupportVideo: 'Penyemak imbas anda tidak menyokong teg video.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Dalam talian sekarang',
    close: 'Tutup',
    messageFrom: 'Mesej daripada',
    registerConfirmationText: 'Adakah anda ingin mendaftarkan diri anda untuk mendapatkan demo percuma? Harap mendengar balasan daripada awak',
    yes: 'ya',
    noThanks: 'Tidak, Terima kasih',
    sendMessageDots: 'Hantar satu mesej...',
    collectInformationText: 'Kami perlu mengumpul beberapa maklumat sekiranya kami kehilangan anda',
    startConversation: 'Mulakan Perbualan',
    sendUsMessage: 'Hantar mesej kepada kami',
    replySoonText: 'Sambung dari tempat anda berhenti',
    uploadingDots: 'Memuat naik...',
    languages: 'Bahasa',
    selectDateTime: 'Pilih TarikhMasa',
    selectDate: 'Pilih Tarikh',
    enterFullName: 'Masukkan nama penuh anda',
    enterEmailAddress: 'Masukkan alamat emel anda',
    name: 'Nama',
    email: 'E-mel',
    typeQuestion: 'Taip soalan anda',
    fillInInformationBelow: 'Sila isi maklumat di bawah, supaya pasukan kami boleh menghubungi anda',
    csatFormTitle: "Nilaikan pengalaman anda!"
  },
  //Swedish
  swedish: {
    botActivationAlert: "Ogiltig filtyp. Endast bilder och PDF-dokument är tillåtna.",
    delete_conversation: "Radera konversation",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Är du säker?",
    typeMessage: "Skriv ditt meddelande",
    replyInTen: "Svara vanligtvis inom 10 sekunder.",
    replyInTenSmall: "Svara inom 10 sekunder.",
    startMessage: "Låt oss börja konversationen",
    continueConversation: 'Fortsätt konversation',
    document: 'Dokumentera',
    image: 'Bild',
    submit: 'Skicka in',
    exit: 'Utgång',
    skip: 'Hoppa',
    browserNotSupportAudio: 'Din webbläsare stöder inte ljudelementet.',
    openFile: 'Öppna fil',
    browserNotSupportVideo: 'Din webbläsare stöder inte videotaggen.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Online nu',
    close: 'stänga',
    messageFrom: 'meddelande från',
    registerConfirmationText: 'Vill du registrera dig för att få en gratis demo? Ser fram emot att höra från dig',
    yes: 'Ja',
    noThanks: 'Nej tack',
    sendMessageDots: 'Skicka ett meddelande...',
    collectInformationText: 'Vi måste samla in viss information om vi skulle tappa bort dig',
    startConversation: 'Starta konversation',
    sendUsMessage: 'skicka oss ett meddelande',
    replySoonText: 'Fortsätt där du slutade',
    uploadingDots: 'Laddar upp...',
    languages: 'språk',
    selectDateTime: 'Välj DateTime',
    selectDate: 'Välj Datum',
    enterFullName: 'Ange ditt fullständiga namn',
    enterEmailAddress: 'Skriv in din mailadress',
    name: 'namn',
    email: 'E-post',
    typeQuestion: 'Skriv din fråga',
    fillInInformationBelow: 'Vänligen fyll i informationen nedan så att vårt team kan kontakta dig',
    csatFormTitle: "Betygsätt din upplevelse!"
  },
  //Slovak
  slovak: {
    invalidFileUpload: "Neplatný typ súboru. Povolené sú iba obrázky a dokumenty PDF.",
    botActivationAlert: "Dlho ste neodpovedali. Váš rozhovor sa uzavrie o {0} min.",
    delete_conversation: "Vymazať konverzáciu",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Si si istý?",
    typeMessage: "Napíšte správu",
    replyInTen: "Zvyčajne odpovedzte do 10 sekúnd.",
    replyInTenSmall: "Odpovedzte do 10 sekúnd.",
    startMessage: "Začnime konverzáciu",
    continueConversation: 'Pokračovať v konverzácii',
    document: 'Dokument',
    image: 'Obrázok',
    submit: 'Predložiť',
    exit: 'VÝCHOD',
    skip: 'Preskočiť',
    browserNotSupportAudio: 'Váš prehliadač nepodporuje zvukový prvok.',
    openFile: 'Otvorený súbor',
    browserNotSupportVideo: 'Váš prehliadač nepodporuje značku videa.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Teraz online',
    close: 'Zavrieť',
    messageFrom: 'Správa od',
    registerConfirmationText: 'Chcete sa zaregistrovať a získať bezplatné demo? Teším sa na tvoju odpoveď',
    yes: 'Áno',
    noThanks: 'Nie ďakujem',
    sendMessageDots: 'Poslať správu...',
    collectInformationText: 'Potrebujeme zhromaždiť nejaké informácie pre prípad, že by sme vás stratili',
    startConversation: 'Začať konverzáciu',
    sendUsMessage: 'Pošlite nám správu',
    replySoonText: 'Pokračujte tam, kde ste skončili',
    uploadingDots: 'Nahráva sa...',
    languages: 'Jazyky',
    selectDateTime: 'Vyberte Dátum a čas',
    selectDate: 'Vyberte Dátum',
    enterFullName: 'Zadajte svoje celé meno',
    enterEmailAddress: 'Vložte svoju e-mailovú adresu',
    name: 'názov',
    email: 'Email',
    typeQuestion: 'Napíšte svoju otázku',
    fillInInformationBelow: 'Vyplňte nižšie uvedené informácie, aby vás náš tím mohol kontaktovať',
    csatFormTitle: "Ohodnoťte svoje skúsenosti!"
  },
  //Norwegian
  norwegian: {
    invalidFileUpload: "Ugyldig filtype. Kun bilder og PDF-dokumenter er tillatt.",
    botActivationAlert: "Du har ikke svart på en stund. Chatten din avsluttes om {0} minutt(er)",
    delete_conversation: "Slett samtalen",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Er du sikker?",
    typeMessage: "Skriv inn meldingen din",
    replyInTen: "Svar vanligvis innen 10 sekunder.",
    replyInTenSmall: "Svar om 10 sekunder.",
    startMessage: "La oss starte samtalen",
    continueConversation: 'Fortsett samtale',
    document: 'Dokument',
    image: 'Bilde',
    submit: 'Sende inn',
    exit: 'Exit',
    skip: 'Hopp over',
    browserNotSupportAudio: 'Nettleseren din støtter ikke lydelementet.',
    openFile: 'Åpen fil',
    browserNotSupportVideo: 'Nettleseren din støtter ikke videokoden.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'På nett nå',
    close: 'Lukk',
    messageFrom: 'melding fra',
    registerConfirmationText: 'Vil du registrere deg for å få en gratis demo? Ser fram til å høre fra deg',
    yes: 'Ja',
    noThanks: 'No Thanks',
    sendMessageDots: 'Send en melding...',
    collectInformationText: 'Vi må samle inn noe informasjon i tilfelle vi mister deg',
    startConversation: 'Start samtale',
    sendUsMessage: 'Send oss en melding',
    replySoonText: 'Fortsett der du slapp',
    uploadingDots: 'Laster opp...',
    languages: 'Språk',
    selectDateTime: 'Velg DatoTid',
    selectDate: 'Velg Dato',
    enterFullName: 'Skriv ditt fulle navn',
    enterEmailAddress: 'Fyll inn epostadressen din',
    name: 'Navn',
    email: 'E-post',
    typeQuestion: 'Skriv inn spørsmålet ditt',
    fillInInformationBelow: 'Vennligst fyll inn informasjonen nedenfor, slik at teamet vårt kan kontakte deg',
    csatFormTitle: "Vurder opplevelsen din!"
  },
  //Hungarian
  hungarian: {
    invalidFileUpload: "Érvénytelen fájltípus. Csak képek és PDF dokumentumok engedélyezettek.",
    botActivationAlert: "Egy ideje nem válaszoltál. A csevegés {0} percen belül bezárul",
    delete_conversation: "Beszélgetés törlése",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "biztos vagy ebben?",
    typeMessage: "Írja be üzenetét",
    replyInTen: "Általában 10 másodpercen belül válaszol.",
    replyInTenSmall: "Válaszoljon 10 másodpercen belül.",
    startMessage: "Kezdjük a beszélgetést",
    continueConversation: 'Beszélgetés folytatása',
    document: 'Dokumentum',
    image: 'Kép',
    submit: 'Beküldés',
    exit: 'Kijárat',
    skip: 'Kihagyás',
    browserNotSupportAudio: 'Az Ön böngészője nem támogatja az audio elemet.',
    openFile: 'Fájl megnyitása',
    browserNotSupportVideo: 'Az Ön böngészője nem támogatja a videocímkét.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Most online',
    close: 'Bezárás',
    messageFrom: 'üzenet valakitől',
    registerConfirmationText: 'Szeretné regisztrálni magát, hogy ingyenes demót kapjon? Várom válaszát',
    yes: 'Igen',
    noThanks: 'Nem köszönöm',
    sendMessageDots: 'Üzenetet küldeni...',
    collectInformationText: 'Össze kell gyűjtenünk néhány információt arra az esetre, ha elveszítenénk',
    startConversation: 'Beszélgetés indítása',
    sendUsMessage: 'Küldj nekünk üzenetet',
    replySoonText: 'Folytasd ott, ahol abbahagytad',
    uploadingDots: 'Feltöltés...',
    languages: 'Nyelvek',
    selectDateTime: 'Válassza a DateTime lehetőséget',
    selectDate: 'Válassza a Dátum lehetőséget',
    enterFullName: 'Add meg a teljes neved',
    enterEmailAddress: 'írd be az email címed',
    name: 'Név',
    email: 'Email',
    typeQuestion: 'Írja be kérdését',
    fillInInformationBelow: 'Kérjük, töltse ki az alábbi adatokat, hogy csapatunk felvehesse Önnel a kapcsolatot',
    csatFormTitle: "Értékelje tapasztalatait!"
  },
  //finnish
  finnish: {
    invalidFileUpload: "Väärä tiedostotyyppi. Vain kuvat ja PDF-dokumentit ovat sallittuja.",
    botActivationAlert: "Et ole vastannut vähään aikaan. Keskustelusi sulkeutuu {0} minuutin kuluttua",
    delete_conversation: "Poista keskustelu",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Oletko varma?",
    typeMessage: "Kirjoita viestisi",
    replyInTen: "Vastaa yleensä 10 sekunnissa.",
    replyInTenSmall: "Vastaa 10 sekunnissa.",
    startMessage: "Aloitetaan keskustelu",
    continueConversation: 'Jatka keskustelua',
    document: 'Asiakirja',
    image: 'Kuva',
    submit: 'Lähetä',
    exit: 'Poistu',
    skip: 'Ohita',
    browserNotSupportAudio: 'Selaimesi ei tue äänielementtiä.',
    openFile: 'Avaa tiedosto',
    browserNotSupportVideo: 'Selaimesi ei tue videotunnistetta.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Verkossa nyt',
    close: 'kiinni',
    messageFrom: 'Viesti joltakin',
    registerConfirmationText: 'Haluatko rekisteröityä saadaksesi ilmaisen demon? Odotan innolla kuulevamme sinusta',
    yes: 'Joo',
    noThanks: 'Ei kiitos',
    sendMessageDots: 'Lähetä viesti...',
    collectInformationText: 'Meidän on kerättävä tietoja siltä varalta, että menetämme sinut',
    startConversation: 'Aloita keskustelu',
    sendUsMessage: 'Lähetä meille viesti',
    replySoonText: 'Jatka siitä mihin jäit',
    uploadingDots: 'Lähetetään...',
    languages: 'Kieli (kielet',
    selectDateTime: 'Valitse DateTime',
    selectDate: 'Valitse Päivämäärä',
    enterFullName: 'Kirjoita koko nimesi',
    enterEmailAddress: 'Syötä sähköpostiosoitteesi',
    name: 'Nimi',
    email: 'Sähköposti',
    typeQuestion: 'Kirjoita kysymyksesi',
    fillInInformationBelow: 'Täytä alla olevat tiedot, jotta tiimimme voi ottaa sinuun yhteyttä',
    csatFormTitle: "Arvioi kokemuksesi!"
  },
  //Estonian
  estonian: {
    invalidFileUpload: "Kehtetu failitüüp. Lubatud on ainult pildid ja PDF-dokumendid.",
    botActivationAlert: "Te pole mõnda aega vastanud. Vestlus suletakse {0} minuti pärast",
    delete_conversation: "Kustuta vestlus",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Oled sa kindel?",
    typeMessage: "Sisestage oma sõnum",
    replyInTen: "Tavaliselt vastatakse 10 sekundi jooksul.",
    replyInTenSmall: "Vasta 10 sekundi pärast.",
    startMessage: "Alustame vestlust",
    continueConversation: 'Jätka vestlust',
    document: 'Dokument',
    image: 'Pilt',
    submit: 'Esita',
    exit: 'Välju',
    skip: 'Vahele jätma',
    browserNotSupportAudio: 'Teie brauser ei toeta helielementi.',
    openFile: 'Avatud fail',
    browserNotSupportVideo: 'Teie brauser ei toeta videomärgendit.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Nüüd võrgus',
    close: 'Sulge',
    messageFrom: 'Sõnum saatjalt',
    registerConfirmationText: 'Kas soovite end tasuta demo saamiseks registreerida? Jään huviga ootama',
    yes: 'Jah',
    noThanks: 'Ei aitäh',
    sendMessageDots: 'Saada sõnum...',
    collectInformationText: 'Peame koguma teavet juhuks, kui me teid kaotame',
    startConversation: 'Alusta vestlust',
    sendUsMessage: 'Saada meile sõnum',
    replySoonText: 'Jätkake sealt, kus pooleli jäite',
    uploadingDots: 'Üleslaadimine...',
    languages: 'Keeled',
    selectDateTime: 'Valige DateTime',
    selectDate: 'Valige Kuupäev',
    enterFullName: 'Sisestage oma täisnimi',
    enterEmailAddress: 'Sisesta oma emaili aadress',
    name: 'Nimi',
    email: 'Meil',
    typeQuestion: 'Sisestage oma küsimus',
    fillInInformationBelow: 'Palun täitke allolev teave, et meie meeskond saaks teiega ühendust võtta',
    csatFormTitle: "Hinda oma kogemust!"
  },
  //czech
  czech: {
    invalidFileUpload: "Neplatný typ souboru. Povoleny jsou pouze obrázky a dokumenty PDF.",
    botActivationAlert: "Dlouho jsi neodpověděl. Váš chat se uzavře za {0} min.",
    delete_conversation: "Smazat konverzaci",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Jsi si jistá?",
    typeMessage: "Napište zprávu",
    replyInTen: "Obvykle odpovězte do 10 sekund.",
    replyInTenSmall: "Odpovězte za 10 sekund.",
    startMessage: "Začněme konverzaci",
    continueConversation: 'Pokračovat v konverzaci',
    document: 'Document',
    image: 'obraz',
    submit: 'Předložit',
    exit: 'Výstup',
    skip: 'Přeskočit',
    browserNotSupportAudio: 'Váš prohlížeč nepodporuje zvukový prvek.',
    openFile: 'Otevřít soubor',
    browserNotSupportVideo: 'Váš prohlížeč nepodporuje značku videa.',
    janetFowler: 'Janet Fowlerová',
    onlineNow: 'Právě online',
    close: 'zavřít',
    messageFrom: 'Zpráva od',
    registerConfirmationText: 'Chcete se zaregistrovat a získat bezplatné demo? Těšíme se na vaši zprávu',
    yes: 'Ano',
    noThanks: 'Ne, díky',
    sendMessageDots: 'Poslat zprávu...',
    collectInformationText: 'Potřebujeme shromáždit nějaké informace pro případ, že vás ztratíme',
    startConversation: 'Zahájit konverzaci',
    sendUsMessage: 'Pošlete nám zprávu',
    replySoonText: 'Pokračujte tam, kde jste skončili',
    uploadingDots: 'Nahrávání...',
    languages: 'Jazyky',
    selectDateTime: 'Vyberte Datum a čas',
    selectDate: 'Vyberte Datum',
    enterFullName: 'Zadejte své celé jméno',
    enterEmailAddress: 'vložte svou e-mailovou adresu',
    name: 'název',
    email: 'E-mailem',
    typeQuestion: 'Napište svou otázku',
    fillInInformationBelow: 'Vyplňte prosím níže uvedené informace, aby vás náš tým mohl kontaktovat',
    csatFormTitle: "Ohodnoťte svou zkušenost!"
  },
  //Dutch
  dutch: {
    invalidFileUpload: "Ongeldig bestandstype. Alleen afbeeldingen en PDF-documenten zijn toegestaan.",
    botActivationAlert: "Je hebt al een tijdje niet gereageerd. Je chat wordt over {0} minuut(en) gesloten",
    delete_conversation: "Verwijder gesprek",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Weet je het zeker?",
    typeMessage: "Schrijf je bericht",
    replyInTen: "Antwoord meestal binnen 10 seconden.",
    replyInTenSmall: "Reageer binnen 10 seconden.",
    startMessage: "Laten we een gesprek beginnen",
    continueConversation: 'Conversatie voortzetten',
    document: 'Document',
    image: 'Afbeelding',
    submit: 'Indienen',
    exit: 'Uitgang',
    skip: 'Overslaan',
    browserNotSupportAudio: 'Uw browser ondersteunt het audio-element niet.',
    openFile: 'Open bestand',
    browserNotSupportVideo: 'Uw browser ondersteunt de videotag niet.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Nu online',
    close: 'dichtbij',
    messageFrom: 'bericht van',
    registerConfirmationText: 'Wil je jezelf registreren om een ​​gratis demo te krijgen? Ik kijk ernaar uit iets van je te vernemen',
    yes: 'Ja',
    noThanks: 'Nee bedankt',
    sendMessageDots: 'Stuur een bericht...',
    collectInformationText: 'We moeten wat informatie verzamelen voor het geval we u kwijtraken',
    startConversation: 'Gesprek starten',
    sendUsMessage: 'zend ons een bericht',
    replySoonText: 'Ga verder waar je gebleven was',
    uploadingDots: 'Uploaden...',
    languages: 'Talen',
    selectDateTime: 'Selecteer DatumTijd',
    selectDate: 'Selecteer een datum',
    enterFullName: 'Vul uw volledige naam in',
    enterEmailAddress: 'Vul je e-mailadres in',
    name: 'Naam',
    email: 'E-mail',
    typeQuestion: 'Typ uw vraag',
    fillInInformationBelow: 'Vul dan onderstaande gegevens in, zodat ons team contact met je op kan nemen',
    csatFormTitle: "Beoordeel uw ervaring!"
  },
  //Italian
  italian: {
    invalidFileUpload: "Tipo di file non valido. Sono consentiti solo immagini e documenti PDF.",
    botActivationAlert: "È da un po' che non rispondi. La chat si chiuderà tra {0} minuto/i",
    delete_conversation: "Cancella la conversazione",
    delete_conversation_title: "Sei sicuro?",
    typeMessage: "Digita il tuo messaggio",
    replyInTen: "In genere rispondi in 10 secondi.",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    replyInTenSmall: "Rispondi in 10 secondi.",
    startMessage: "Iniziamo la conversazione",
    continueConversation: 'Continua la conversazione',
    document: 'Documento',
    image: 'Immagine',
    submit: 'Invia',
    exit: 'Uscita',
    skip: 'Saltare',
    browserNotSupportAudio: 'Il tuo browser non supporta l\'elemento audio.',
    openFile: 'Apri il file',
    browserNotSupportVideo: 'Il tuo browser non supporta il tag video.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Online adesso',
    close: 'chiudere',
    messageFrom: 'messaggio da',
    registerConfirmationText: 'Vuoi registrarti per ottenere una demo gratuita? Attendiamo un vostro riscontro',
    yes: 'sì',
    noThanks: 'No grazie',
    sendMessageDots: 'Mandare un messaggio...',
    collectInformationText: 'Abbiamo bisogno di raccogliere alcune informazioni nel caso dovessimo perderti',
    startConversation: 'Avvia conversazione',
    sendUsMessage: 'Mandaci un messaggio',
    replySoonText: 'Riprendi da dove eri rimasto',
    uploadingDots: 'Caricamento in corso...',
    languages: 'Le lingue',
    selectDateTime: 'Seleziona DataOra',
    selectDate: 'Seleziona Data',
    enterFullName: 'Inserisci il tuo nome e cognome',
    enterEmailAddress: 'Inserisci il tuo indirizzo email',
    name: 'Nome',
    email: 'E-mail',
    typeQuestion: 'Digita la tua domanda',
    fillInInformationBelow: 'Si prega di compilare le informazioni di seguito, in modo che il nostro team possa contattarvi',
    csatFormTitle: "Valuta la tua esperienza!"
  },
  //portuguese
  portuguese: {
    invalidFileUpload: "Tipo de arquivo inválido. Somente imagens e documentos PDF são permitidos.",
    botActivationAlert: "Você não responde há algum tempo. Seu bate-papo será encerrado em {0} minuto(s)",
    delete_conversation: "Apagar conversa",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Tem certeza?",
    typeMessage: "Digite sua mensagem",
    replyInTen: "Normalmente responda em 10 segundos.",
    replyInTenSmall: "Responda em 10 segundos.",
    startMessage: "Vamos começar a conversa",
    continueConversation: 'Continuar conversa',
    document: 'Documento',
    image: 'Imagem',
    submit: 'Enviar',
    exit: 'Saída',
    skip: 'Pular',
    browserNotSupportAudio: 'Seu navegador não suporta o elemento de áudio.',
    openFile: 'Abrir arquivo',
    browserNotSupportVideo: 'Seu navegador não suporta a tag de vídeo.',
    janetFowler: 'Janete Fowler',
    onlineNow: 'Online agora',
    close: 'fechar',
    messageFrom: 'Mensagem de',
    registerConfirmationText: 'Deseja se registrar para obter uma demonstração gratuita? Ansioso por saber sobre você',
    yes: 'Sim',
    noThanks: 'Não, obrigado',
    sendMessageDots: 'Envie uma mensagem...',
    collectInformationText: 'Precisamos coletar algumas informações caso percamos você',
    startConversation: 'Iniciar conversa',
    sendUsMessage: 'Envie-nos uma mensagem',
    replySoonText: 'Retome de onde parou',
    uploadingDots: 'Enviando...',
    languages: 'línguas',
    selectDateTime: 'Selecione DataHora',
    selectDate: 'Selecione a data',
    enterFullName: 'Escreva seu nome completo',
    enterEmailAddress: 'Insira o seu endereço de email',
    name: 'Nome',
    email: 'E-mail',
    typeQuestion: 'Digite sua pergunta',
    fillInInformationBelow: 'Por favor, preencha as informações abaixo, para que nossa equipe possa entrar em contato com você',
    csatFormTitle: "Avalie a sua experiência!"
  },
  //French
  french: {
    invalidFileUpload: "Type de fichier invalide. Seules les images et les documents PDF sont autorisés.",
    botActivationAlert: "Vous n'avez pas répondu depuis un moment. Votre conversation se terminera dans {0} minute(s)",
    delete_conversation: "Supprimer la conversation",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Êtes-vous sûr?",
    typeMessage: "Tapez votre message",
    replyInTen: "Réponse généralement en 10 secondes.",
    replyInTenSmall: "Réponse en 10 secondes.",
    startMessage: "Commençons la conversation",
    continueConversation: 'Continuer la conversation',
    document: 'Document',
    image: 'Image',
    submit: 'Nous faire parvenir',
    exit: 'Sortir',
    skip: 'Sauter',
    browserNotSupportAudio: "Votre navigateur ne supporte pas l'élément audio.",
    openFile: 'Fichier ouvert',
    browserNotSupportVideo: 'Votre navigateur ne prend pas en charge la balise vidéo.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Maintenant en ligne',
    close: 'proche',
    messageFrom: 'message de',
    registerConfirmationText: 'Voulez-vous vous inscrire pour obtenir une démo gratuite ? Hâte de vous entendre',
    yes: 'Oui',
    noThanks: 'Non merci',
    sendMessageDots: 'Envoyer un message...',
    collectInformationText: 'Nous devons collecter des informations au cas où nous vous perdrions',
    startConversation: 'Démarrer la conversation',
    sendUsMessage: 'Envoie-nous un message',
    replySoonText: 'Reprenez là où vous vous êtes arrêté',
    uploadingDots: 'Téléchargement...',
    languages: 'Langues',
    selectDateTime: 'Sélectionnez DateHeure',
    selectDate: 'Sélectionner une date',
    enterFullName: 'Entrez votre nom complet',
    enterEmailAddress: 'Entrez votre adresse email',
    name: 'Nom',
    email: 'E-mail',
    typeQuestion: 'Tapez votre question',
    fillInInformationBelow: 'Merci de renseigner les informations ci-dessous afin que notre équipe puisse vous recontacter',
    csatFormTitle: "Évaluez votre expérience !"
  },
  //German
  german: {
    invalidFileUpload: "Ungültiger Dateityp. Es sind nur Bilder und PDF-Dokumente erlaubt.",
    botActivationAlert: "Du hast eine Weile nicht geantwortet. Ihr Chat wird in {0} Minute(n) geschlossen.",
    delete_conversation: "Konversation löschen",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Bist du sicher?",
    typeMessage: "Geben Sie Ihre Nachricht ein",
    replyInTen: "Antworte in der Regel innerhalb von 10 Sekunden.",
    replyInTenSmall: "Antworten Sie in 10 Sekunden.",
    startMessage: "Beginnen wir das Gespräch",
    continueConversation: 'Gespräch fortsetzen',
    document: 'Dokumentieren',
    image: 'Bild',
    submit: 'einreichen',
    exit: 'Ausgang',
    skip: 'Überspringen',
    browserNotSupportAudio: 'Ihr Browser unterstützt das Audio-Element nicht.',
    openFile: 'Datei öffnen',
    browserNotSupportVideo: 'Ihr Browser unterstützt das Video-Tag nicht.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Jetzt online',
    close: 'nahe',
    messageFrom: 'Nachricht von',
    registerConfirmationText: 'Möchten Sie sich registrieren, um eine kostenlose Demo zu erhalten? Wir freuen uns, bald von Ihnen zu hören',
    yes: 'Ja',
    noThanks: 'Nein Danke',
    sendMessageDots: 'Eine Nachricht schicken...',
    collectInformationText: 'Wir müssen einige Informationen sammeln, falls wir Sie verlieren',
    startConversation: 'Gespräch starten',
    sendUsMessage: 'Schick uns eine Nachricht',
    replySoonText: 'Machen Sie dort weiter, wo Sie aufgehört haben',
    uploadingDots: 'Hochladen...',
    languages: 'Sprachen',
    selectDateTime: 'Wählen Sie DatumUhrzeit aus',
    selectDate: 'Datum auswählen',
    enterFullName: 'Geben Sie Ihren vollständigen Namen ein',
    enterEmailAddress: 'Geben sie ihre E-Mailadresse ein',
    name: 'Name',
    email: 'Email',
    typeQuestion: 'Geben Sie Ihre Frage ein',
    fillInInformationBelow: 'Bitte füllen Sie die folgenden Informationen aus, damit unser Team Sie kontaktieren kann',
    csatFormTitle: "Bewerten Sie Ihre Erfahrung!"
  },
  // danish
  danish: {
    invalidFileUpload: "Ugyldig filtype. Kun billeder og PDF-dokumenter er tilladt.",
    botActivationAlert: "Du har ikke svaret i et stykke tid. Din chat lukker om {0} minutter",
    delete_conversation: "Slet samtale",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Er du sikker?",
    typeMessage: "Skriv din besked",
    replyInTen: "Svar typisk inden for 10 sekunder.",
    replyInTenSmall: "Svar om 10 sekunder.",
    startMessage: "Lad os starte samtalen",
    continueConversation: 'Fortsæt samtale',
    document: 'Dokument',
    image: 'Billede',
    submit: 'Indsend',
    exit: 'Afslut',
    skip: 'Springe',
    browserNotSupportAudio: 'Din browser understøtter ikke lydelementet.',
    openFile: 'Åben fil',
    browserNotSupportVideo: 'Din browser understøtter ikke video-tagget.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Online nu',
    close: 'tæt',
    messageFrom: 'Besked fra',
    registerConfirmationText: 'Vil du registrere dig selv for at få en gratis demo? Ser frem til at høre fra dig',
    yes: 'Ja',
    noThanks: 'Nej tak',
    sendMessageDots: 'Send en besked...',
    collectInformationText: 'Vi er nødt til at indsamle nogle oplysninger, hvis vi mister dig',
    startConversation: 'Start samtale',
    sendUsMessage: 'Send os en besked',
    replySoonText: 'Fortsæt hvor du slap',
    uploadingDots: 'Uploader...',
    languages: 'Sprog',
    selectDateTime: 'Vælg DatoTid',
    selectDate: 'Vælg dato',
    enterFullName: 'Skriv dit fulde navn',
    enterEmailAddress: 'Indtast din e-mailadresse',
    name: 'Navn',
    email: 'E-mail',
    typeQuestion: 'Indtast dit spørgsmål',
    fillInInformationBelow: 'Udfyld venligst nedenstående oplysninger, så vores team kan kontakte dig',
    csatFormTitle: "Bedøm din oplevelse!"
  },
  //spanish
  spanish: {
    invalidFileUpload: "Tipo de archivo invalido. Sólo se permiten imágenes y documentos PDF.",
    botActivationAlert: "You haven't replied in a while. You chat will close in {0} minute(s)",
    delete_conversation: "Eliminar la conversación",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Estas seguro",
    typeMessage: "Escribe tu mensaje",
    replyInTen: "Normalmente responde en 10 segundos.",
    replyInTenSmall: "Responde en 10 segundos.",
    startMessage: "empecemos conversacion",
    continueConversation: 'Continuar conversación',
    document: 'Documento',
    image: 'Imagen',
    submit: 'Enviar',
    exit: 'Salida',
    skip: 'Saltar',
    browserNotSupportAudio: 'Su navegador no es compatible con el elemento de audio.',
    openFile: 'Abrir documento',
    browserNotSupportVideo: 'Su navegador no soporta la etiqueta de vídeo.',
    janetFowler: 'janet cazador',
    onlineNow: 'En línea ahora',
    close: 'Cerrar',
    messageFrom: 'Mensaje de',
    registerConfirmationText: '¿Quieres registrarte para obtener una demostración gratuita? A la espera de saber de ti',
    yes: 'Sí',
    noThanks: 'No, gracias',
    sendMessageDots: 'Enviar un mensaje...',
    collectInformationText: 'Necesitamos recopilar cierta información en caso de que te perdamos.',
    startConversation: 'Iniciar conversación',
    sendUsMessage: 'Mandanos un mensaje',
    replySoonText: 'Retoma donde lo dejaste',
    uploadingDots: 'Cargando...',
    languages: 'Idiomas',
    selectDateTime: 'Seleccionar fecha y hora',
    selectDate: 'Seleccione fecha',
    enterFullName: 'Ingresa tu nombre completo',
    enterEmailAddress: 'Ingrese su dirección de correo electrónico',
    name: 'Nombre',
    email: 'Correo electrónico',
    typeQuestion: 'Escriba su pregunta',
    fillInInformationBelow: 'Complete la información a continuación, para que nuestro equipo pueda contactarlo',
    csatFormTitle: "¡Valora tu experiencia!"
  },
  //korean
  korean: {
    invalidFileUpload: "파일 형식이 잘못되었습니다. 이미지와 PDF 문서만 허용됩니다.",
    botActivationAlert: "당신은 한동안 응답하지 않았습니다. 채팅이 {0}분 후에 종료됩니다.",
    delete_conversation: "대화 삭제",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "확실합니까?",
    typeMessage: "메시지를 입력하세요",
    replyInTen: "일반적으로 10초 안에 응답합니다.",
    replyInTenSmall: "10초 안에 응답하십시오.",
    startMessage: "대화를 시작하자",
    continueConversation: '대화 계속',
    document: '문서',
    image: '영상',
    submit: '제출하다',
    exit: '출구',
    skip: '건너뛰다',
    browserNotSupportAudio: '귀하의 브라우저는 오디오 요소를 지원하지 않습니다.',
    openFile: '파일 열기',
    browserNotSupportVideo: '귀하의 브라우저는 비디오 태그를 지원하지 않습니다.',
    janetFowler: '자넷 파울러',
    onlineNow: '온라인 지금',
    close: '닫다',
    messageFrom: '메시지',
    registerConfirmationText: '무료 데모를 받기 위해 등록하시겠습니까? 귀하의 의견을 기다리겠습니다.',
    yes: '예',
    noThanks: '고맙지 만 사양 할게',
    sendMessageDots: '메세지를 보내다...',
    collectInformationText: '귀하를 잃을 경우를 대비하여 몇 가지 정보를 수집해야 합니다.',
    startConversation: '대화 시작',
    sendUsMessage: '메시지 보내기',
    replySoonText: '중단한 부분부터 다시 시작',
    uploadingDots: '업로드 중...',
    languages: '언어',
    selectDateTime: '날짜/시간 선택',
    selectDate: '날짜 선택',
    enterFullName: '당신의 성명을 입력하십시오',
    enterEmailAddress: '당신의 이메일 주소를 입력 해주세요',
    name: '이름',
    email: '이메일',
    typeQuestion: '질문을 입력하세요',
    fillInInformationBelow: '아래 정보를 입력해 주시면 저희 팀에서 연락드릴 수 있습니다.',
    csatFormTitle: "귀하의 경험을 평가해 주세요!"
  },
  //chinese
  chinese: {
    invalidFileUpload: "文件类型无效。仅允许图像和 PDF 文档。",
    botActivationAlert: "你已经有一段时间没有回复了。您的聊天将在 {0} 分钟后结束",
    delete_conversation: "删除对话",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "你确定吗？",
    typeMessage: "输入您的信息",
    replyInTen: "通常在 10 秒内回复。",
    replyInTenSmall: "10 秒内回复。",
    startMessage: "让我们开始对话",
    continueConversation: '继续对话',
    document: '文档',
    image: '图片',
    submit: '提交',
    exit: '出口',
    skip: '跳过',
    browserNotSupportAudio: '您的浏览器不支持音频元素.',
    openFile: '打开文件',
    browserNotSupportVideo: '您的浏览器不支持视频标签.',
    janetFowler: '珍妮特福勒',
    onlineNow: '现在在线',
    close: '关',
    messageFrom: '消息来自',
    registerConfirmationText: '您想自己注册以获得免费演示吗？期待您的回音',
    yes: '是的',
    noThanks: '不用了，谢谢',
    sendMessageDots: '发送信息...',
    collectInformationText: '我们需要收集一些信息以防我们失去你',
    startConversation: '开始对话',
    sendUsMessage: '给我们发信息',
    replySoonText: '从你离开的地方继续',
    uploadingDots: '上传中...',
    languages: '语言',
    selectDateTime: '选择日期时间',
    selectDate: '选择日期',
    enterFullName: '输入您的全名',
    enterEmailAddress: '输入你的电子邮箱地址',
    name: '姓名',
    email: '电子邮件',
    typeQuestion: '输入您的问题',
    fillInInformationBelow: '请填写以下信息，以便我们的团队与您联系',
    csatFormTitle: "对你的体验进行评分！"
  },
  //arabic
  arabic: {
    invalidFileUpload: "نوع الملف غير صالح. يُسمح فقط بالصور ومستندات PDF.",
    arabic: "أنت لم ترد منذ فترة. ستغلق الدردشة خلال {0} دقيقة (دقائق)",
    delete_conversation: "مسح المحادثة",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "هل أنت واثق؟",
    typeMessage: "اكتب رسالتك",
    replyInTen: "الرد عادة في 10 ثوان.",
    replyInTenSmall: "الرد خلال 10 ثوان.",
    startMessage: "لنبدأ المحادثة",
    continueConversation: 'تواصل المحادثة',
    document: 'وثيقة',
    image: 'صورة',
    submit: 'يُقدِّم',
    exit: 'مخرج',
    skip: 'يتخطى',
    browserNotSupportAudio: 'متصفحك لا يدعم عنصر الصوت.',
    openFile: 'افتح ملف',
    browserNotSupportVideo: 'متصفحك الحالي لا يدعم تشغيل الفيديو.',
    janetFowler: 'جانيت فاولر',
    onlineNow: 'متواجد حاليا',
    close: 'أغلق',
    messageFrom: 'رسالة من',
    registerConfirmationText: 'هل تريد أن تسجل نفسك لتحصل على عرض تجريبي مجاني؟ أتطلع للسماع منك',
    yes: 'نعم',
    noThanks: 'ًلا شكرا',
    sendMessageDots: 'ارسل رسالة...',
    collectInformationText: 'نحتاج إلى جمع بعض المعلومات في حالة فقدنا لك',
    startConversation: 'ابدأ المحادثة',
    sendUsMessage: 'أرسل لنا رسالة',
    replySoonText: 'تابع من حيث توقفت',
    uploadingDots: 'تحميل...',
    languages: 'اللغات',
    selectDateTime: 'حدد التاريخ والوقت',
    selectDate: 'حدد تاريخ',
    enterFullName: 'أدخل اسمك الكامل',
    enterEmailAddress: 'أدخل عنوان بريدك الالكتروني',
    name: 'اسم',
    email: 'بريد إلكتروني',
    typeQuestion: 'اكتب سؤالك',
    fillInInformationBelow: 'يرجى ملء المعلومات أدناه ، حتى يتمكن فريقنا من الاتصال بك',
    csatFormTitle: "قم بتقييم تجربتك!"
  },
  //lithuanian
  lithuanian: {
    invalidFileUpload: "Neteisingas failo tipas. Leidžiami tik vaizdai ir PDF dokumentai.",
    botActivationAlert: "Jau kurį laiką neatsakei. Pokalbis bus baigtas po {0} minutės (-ių)",
    delete_conversation: "Ištrinti pokalbį",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Ar tu tuo tikras?",
    typeMessage: "Įveskite savo pranešimą",
    replyInTen: "Paprastai atsakykite per 10 sekundžių.",
    replyInTenSmall: "Atsakykite per 10 sekundžių.",
    startMessage: "Pradėkime pokalbį",
    continueConversation: 'Tęsti pokalbį',
    document: 'dokumentas',
    image: 'Vaizdas',
    submit: 'Pateikti',
    exit: 'Išeiti',
    skip: 'Praleisti',
    browserNotSupportAudio: 'Jūsų naršyklė nepalaiko garso elemento.',
    openFile: 'Atidaryti failą',
    browserNotSupportVideo: 'Jūsų naršyklė nepalaiko vaizdo įrašo žymos.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Prisijungęs dabar',
    close: 'Uždaryti',
    messageFrom: 'Pranešimas nuo',
    registerConfirmationText: 'Ar norite užsiregistruoti, kad gautumėte nemokamą demonstracinę versiją? Lauksime jūsų žinučių',
    yes: 'Taip',
    noThanks: 'Ne, ačiū',
    sendMessageDots: 'Siųsti žinutę...',
    collectInformationText: 'Turėtume surinkti tam tikrą informaciją, jei jus prarastume',
    startConversation: 'Pradėti pokalbį',
    sendUsMessage: 'Atsiųskite mums žinutę',
    replySoonText: 'Tęskite ten, kur baigėte',
    uploadingDots: 'Įkeliama...',
    languages: 'Kalbos',
    selectDateTime: 'Pasirinkite DataLaikas',
    selectDate: 'Pasirinkite Data',
    enterFullName: 'Įveskite savo vardą ir pavardę',
    enterEmailAddress: 'Įveskite savo elektroninio pašto adresą',
    name: 'vardas',
    email: 'El. paštas',
    typeQuestion: 'Įveskite savo klausimą',
    fillInInformationBelow: 'Prašome užpildyti žemiau esančią informaciją, kad mūsų komanda galėtų su jumis susisiekti',
    csatFormTitle: "Įvertinkite savo patirtį!"
  },
  //georgian
  georgian: {
    invalidFileUpload: "ფაილის ტიპი არასწორია. დასაშვებია მხოლოდ სურათები და PDF დოკუმენტები.",
    botActivationAlert: "დიდი ხანია არ გიპასუხია. თქვენი ჩატი დაიხურება {0} წუთში",
    delete_conversation: "წაშალეთ საუბარი",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Დარწმუნებული ხარ?",
    typeMessage: "ჩაწერეთ თქვენი შეტყობინება",
    replyInTen: "როგორც წესი, უპასუხეთ 10 წამში.",
    replyInTenSmall: "პასუხი 10 წამში.",
    startMessage: "დავიწყოთ საუბარი",
    continueConversation: 'განაგრძეთ საუბარი',
    document: 'დოკუმენტი',
    image: 'გამოსახულება',
    submit: 'გაგზავნა',
    exit: 'გასვლა',
    skip: 'გამოტოვება',
    browserNotSupportAudio: 'თქვენს ბრაუზერს არ აქვს აუდიო ელემენტის მხარდაჭერა.',
    openFile: 'Გახსენი ფაილი',
    browserNotSupportVideo: 'თქვენს ბრაუზერს არ აქვს ვიდეო ტეგის მხარდაჭერა.',
    janetFowler: 'ჯანეტ ფაულერი',
    onlineNow: 'Ქსელშია ახლა',
    close: 'დახურვა',
    messageFrom: 'შეტყობინება',
    registerConfirmationText: 'გსურთ დარეგისტრირდეთ, რომ მიიღოთ უფასო დემო ვერსია? Ველი თქვენგან პასუხს',
    yes: 'დიახ',
    noThanks: 'Არა გმადლობთ',
    sendMessageDots: 'Გააგზავნე შეტყობინება...',
    collectInformationText: 'ჩვენ გვჭირდება გარკვეული ინფორმაციის შეგროვება, თუ დაგკარგავთ',
    startConversation: 'დაიწყეთ საუბარი',
    sendUsMessage: 'გამოგვიგზავნეთ შეტყობინება',
    replySoonText: 'გააგრძელეთ იქ, სადაც შეჩერდით',
    uploadingDots: 'ატვირთვა...',
    languages: 'ენები',
    selectDateTime: 'აირჩიეთ თარიღი დრო',
    selectDate: 'აირჩიეთ თარიღი',
    enterFullName: 'შეიყვანეთ თქვენი სრული სახელი',
    enterEmailAddress: 'შეიყვანეთ თქვენი ელ.ფოსტის მისამართი',
    name: 'სახელი',
    email: 'ელფოსტა',
    typeQuestion: 'ჩაწერეთ თქვენი შეკითხვა',
    fillInInformationBelow: 'გთხოვთ შეავსოთ ქვემოთ მოცემული ინფორმაცია, რათა ჩვენი გუნდი დაგიკავშირდეთ',
    csatFormTitle: "შეაფასეთ თქვენი გამოცდილება!"
  },
  //ukrainian
  ukrainian: {
    invalidFileUpload: "Недійсний тип файлу. Допускаються лише зображення та PDF-документи.",
    botActivationAlert: "Ви давно не відповідали. Ваш чат буде закрито через {0} хв.",
    delete_conversation: "Видалити розмову",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Ти впевнений?",
    typeMessage: "Введіть своє повідомлення",
    replyInTen: "Зазвичай відповідь триває 10 секунд.",
    replyInTenSmall: "Відповісти за 10 секунд.",
    startMessage: "Почнемо розмову",
    continueConversation: 'Продовжити розмову',
    document: 'документ',
    image: 'Зображення',
    submit: 'Надіслати',
    exit: 'Вихід',
    skip: 'Пропустити',
    browserNotSupportAudio: 'Ваш браузер не підтримує звуковий елемент.',
    openFile: 'Відкрити файл',
    browserNotSupportVideo: 'Ваш браузер не підтримує тег video.',
    janetFowler: 'Джанет Фаулер',
    onlineNow: 'Онлайн зараз',
    close: 'закрити',
    messageFrom: 'Повідомлення від',
    registerConfirmationText: 'Бажаєте зареєструватися, щоб отримати безкоштовну демонстрацію? З нетерпінням чекаю на вашу думку',
    yes: 'Так',
    noThanks: 'Ні, дякую',
    sendMessageDots: 'Надіслати повідомлення...',
    collectInformationText: 'Нам потрібно зібрати певну інформацію, якщо ми вас втратимо',
    startConversation: 'Розпочати розмову',
    sendUsMessage: 'Надішліть нам повідомлення',
    replySoonText: 'Продовжуйте з того місця, де зупинилися',
    uploadingDots: 'Завантаження...',
    languages: 'Мови',
    selectDateTime: 'Виберіть DateTime',
    selectDate: 'Виберіть Дата',
    enterFullName: 'Введіть Ваше повне ім\'я',
    enterEmailAddress: 'Введіть адресу вашої електронної пошти',
    name: 'Ім\'я',
    email: 'Електронна пошта',
    typeQuestion: 'Введіть своє запитання',
    fillInInformationBelow: 'Будь ласка, заповніть інформацію нижче, щоб наша команда могла зв’язатися з вами',
    csatFormTitle: "Оцініть свій досвід!"
  },
  //polish
  polish: {
    invalidFileUpload: "Nieprawidłowy typ pliku. Dozwolone są tylko obrazy i dokumenty PDF.",
    botActivationAlert: "Nie odpowiedziałeś przez jakiś czas. Twój czat zakończy się za {0} minut",
    delete_conversation: "Usunąć konwersację",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Jesteś pewny?",
    typeMessage: "Wpisz swoją wiadomość",
    replyInTen: "Zwykle odpowiadaj w ciągu 10 sekund.",
    replyInTenSmall: "Odpowiedz w 10 sekund.",
    startMessage: "Zacznijmy rozmowę",
    continueConversation: 'Kontynuuj rozmowę',
    document: 'Dokument',
    image: 'Obraz',
    submit: 'Składać',
    exit: 'Wyjście',
    skip: 'Pominąć',
    browserNotSupportAudio: 'Twoja przeglądarka nie obsługuje elementu audio.',
    openFile: 'Otwórz plik',
    browserNotSupportVideo: 'Twoja przeglądarka nie obsługuje tagu wideo.',
    janetFowler: 'Żaneta Fowler',
    onlineNow: 'Teraz online',
    close: 'blisko',
    messageFrom: 'Wiadomość od',
    registerConfirmationText: 'Czy chcesz się zarejestrować, aby otrzymać bezpłatną wersję demonstracyjną? Czekamy na wiadomość od Ciebie',
    yes: 'tak',
    noThanks: 'Nie, dziękuję',
    sendMessageDots: 'Wysłać wiadomość...',
    collectInformationText: 'Musimy zebrać pewne informacje na wypadek, gdybyśmy cię stracili',
    startConversation: 'Rozpocznij rozmowę',
    sendUsMessage: 'Wyślij nam wiadomość',
    replySoonText: 'Kontynuuj w miejscu, w którym skończyłeś',
    uploadingDots: 'Przesyłanie...',
    languages: 'Języki',
    selectDateTime: 'Wybierz DataGodzina',
    selectDate: 'Wybierz datę',
    enterFullName: 'Wprowadź swoje imię i nazwisko',
    enterEmailAddress: 'Wpisz swój adres e-mail',
    name: 'Nazwa',
    email: 'E-mail',
    typeQuestion: 'Wpisz swoje pytanie',
    fillInInformationBelow: 'Podaj poniższe informacje, aby nasz zespół mógł się z Tobą skontaktować',
    csatFormTitle: "Оцініть свій досвід!"
  },
  // romanian
  romanian: {
    invalidFileUpload: "Tip de fișier nevalid. Sunt permise doar imagini și documente PDF.",
    botActivationAlert: "Nu ai mai raspuns de ceva vreme. Chatul dvs. se va închide în {0} minut(e)",
    delete_conversation: "Sterge conversatia",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Esti sigur?",
    typeMessage: "Introduceți mesajul dvs",
    replyInTen: "De obicei, răspunde în 10 secunde.",
    replyInTenSmall: "Raspunde in 10 secunde.",
    startMessage: "Să începem conversația",
    continueConversation: 'Continuați conversația',
    document: 'Document',
    image: 'Imagine',
    submit: 'Trimite',
    exit: 'Ieșire',
    skip: 'Ocolire',
    browserNotSupportAudio: 'Browserul dvs. nu acceptă elementul audio.',
    openFile: 'Deschide fișierul',
    browserNotSupportVideo: 'Browserul dvs. nu acceptă eticheta video.',
    janetFowler: 'Janet Fowler',
    onlineNow: 'Online acum',
    close: 'închide',
    messageFrom: 'mesaj de la',
    registerConfirmationText: 'Doriți să vă înregistrați pentru a obține o demonstrație gratuită? Aşteptăm cu nerăbdare să auzim veşti de la dumneavoastră',
    yes: 'da',
    noThanks: 'Nu, mulțumesc',
    sendMessageDots: 'Trimite un mesaj...',
    collectInformationText: 'Trebuie să colectăm unele informații în cazul în care vă pierdem',
    startConversation: 'Începe conversația',
    sendUsMessage: 'Trimite-ne un mesaj',
    replySoonText: 'Reluați de unde ați rămas',
    uploadingDots: 'Încărcare...',
    languages: 'Limbi',
    selectDateTime: 'Selectați DateTime',
    selectDate: 'Selectați Data',
    enterFullName: 'Introduceți numele dvs. complet',
    enterEmailAddress: 'Introdu adresa ta de e-mail',
    name: 'Nume',
    email: 'E-mail',
    typeQuestion: 'Introduceți întrebarea dvs',
    fillInInformationBelow: 'Vă rugăm să completați informațiile de mai jos, astfel încât echipa noastră să vă poată contacta',
    csatFormTitle: "Evaluează-ți experiența!"
  },
  //bulgarian
  bulgarian: {
    invalidFileUpload: "Невалиден тип файл. Разрешени са само изображения и PDF документи.",
    botActivationAlert: "Не сте отговаряли от известно време. Вашият чат ще затвори след {0} минута(и)",
    delete_conversation: "Изтрий разговора",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Сигурен ли си?",
    typeMessage: "Въведете вашето съобщение",
    replyInTen: "Обикновено отговаряйте след 10 секунди.",
    replyInTenSmall: "Отговорете след 10 секунди.",
    startMessage: "Да започнем разговор",
    continueConversation: 'Продължаване на разговора',
    document: 'Документ',
    image: 'Образ',
    submit: 'Изпращане',
    exit: 'Изход',
    skip: 'Пропускане',
    browserNotSupportAudio: 'Вашият браузър не поддържа аудио елемента.',
    openFile: 'Отвори файл',
    browserNotSupportVideo: 'Вашият браузър не поддържа видео маркера.',
    janetFowler: 'Джанет Фаулър',
    onlineNow: 'Онлайн сега',
    close: 'близо',
    messageFrom: 'Съобщение от',
    registerConfirmationText: 'Искате ли да се регистрирате, за да получите безплатна демонстрация? Очаквам с нетърпение да те чуя',
    yes: 'да',
    noThanks: 'Не благодаря',
    sendMessageDots: 'Изпрати съобщение...',
    collectInformationText: 'Трябва да съберем информация, в случай че ви загубим',
    startConversation: 'Започнете разговор',
    sendUsMessage: 'Изпратете ни съобщение',
    replySoonText: 'Продължете откъдето сте спрели',
    uploadingDots: 'Качване...',
    languages: 'Езици',
    selectDateTime: 'Изберете Дата и час',
    selectDate: 'Изберете Дата',
    enterFullName: 'Въведете пълното си име',
    enterEmailAddress: 'напишете своя емайл адрес',
    name: 'Име',
    email: 'електронна поща',
    typeQuestion: 'Въведете въпроса си',
    fillInInformationBelow: 'Моля, попълнете информацията по-долу, за да може нашият екип да се свърже с вас',
    csatFormTitle: "Оценете опита си!"
  },
  // Russian
  russian: {
    invalidFileUpload: "Неверный тип файла. Разрешены только изображения и PDF-документы.",
    botActivationAlert: "Вы давно не отвечали. Ваш чат закроется через {0} мин.",
    delete_conversation: "Удалить беседу",
    phonePlaceholder: '(123) 123-1234',
    currencyPlaceholder: '$5 or 5 USD',
    websiteURLPlaceholder: 'https://xyz.com',
    geocodePlaceholder: '67.85,45.43',
    delete_conversation_title: "Уверены ли вы?",
    typeMessage: "Введите ваше сообщение",
    replyInTen: "Обычно отвечают через 10 секунд.",
    replyInTenSmall: "Ответ через 10 секунд.",
    startMessage: "Давайте начнем разговор",
    continueConversation: 'Продолжить разговор',
    document: 'Документ',
    image: 'Изображение',
    submit: 'Представлять на рассмотрение',
    exit: 'Выход',
    skip: 'Пропускать',
    browserNotSupportAudio: 'Your browser does not support the audio element.',
    openFile: 'Open File',
    browserNotSupportVideo: 'Ваш браузер не поддерживает видео тег.',
    janetFowler: 'Джанет Фаулер',
    onlineNow: 'Сейчас в сети',
    close: 'близко',
    messageFrom: 'Сообщение от',
    registerConfirmationText: 'Вы хотите зарегистрироваться, чтобы получить бесплатную демоверсию? С нетерпением жду Вашего ответа',
    yes: 'Да',
    noThanks: 'Спасибо, не надо',
    sendMessageDots: 'Отправить сообщение...',
    collectInformationText: 'Нам нужно собрать некоторую информацию на случай, если мы потеряем вас',
    startConversation: 'Начать разговор',
    sendUsMessage: 'Отправьте нам сообщение',
    replySoonText: 'Возьмите, где вы остановились',
    uploadingDots: 'Загрузка...',
    languages: 'Языки',
    selectDateTime: 'Выберите дату и время',
    selectDate: 'Выберите дату',
    enterFullName: 'Введите свое полное имя',
    enterEmailAddress: 'Введите ваш адрес электронной почты',
    name: 'Имя',
    email: 'Электронная почта',
    typeQuestion: 'Введите свой вопрос',
    fillInInformationBelow: 'Пожалуйста, заполните информацию ниже, чтобы наша команда могла связаться с вами',
    csatFormTitle: "Оцените свой опыт!"
  },
};

export const getDefaultState = () => {
  return {
    botConfigs: {},
    selection: 0,
    bot_key: '',
    story_id: undefined,
    rgba: undefined,
    localConfig: {},
    fbId: '',
    showMenu: false,
    messages: [],
    formErrorMsg: null,
    channel: null,
    typing: false,
    showingWindow: false,
    target_div: undefined,
    chatBotLoaded: undefined,
    showCsat: false,
    CsatForm: {
      feedback: '',
      responses: [],
      web_message_id: null,
      score: 0,
    },
    csatSubmitted: false,
  };
};

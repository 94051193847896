<template>
    <div class="h-full overflow-y-scroll" :class="{ 'rounded-md': roundedDisabler }">
      <div class="relative z-10">
            <div class="block p-3 m-3 bg-white shadow-md max-w-screen" :class="{ 'rounded-3xl': roundedDisabler }">
                <div class="grid grid-flow-row relative" >
                    <div class="right-0 absolute">
                        <svg @click="closeCSAT()" style="position:initial;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17">
                            <path
                            d="M9.207 8.5l6.646 6.646-.707.707L8.5 9.207l-6.646 6.646-.707-.707L7.793 8.5 1.146 1.854l.707-.707L8.5 7.793l6.646-6.646.707.707L9.207 8.5z" />
                        </svg>
                    </div>
                    <h3 class="text-xl block text-left text-gray-900 mt-2">{{ $t('csatFormTitle') }}</h3>
                    <div class="mt-3">
                        <div v-for="(index, question) in botConfigs.configs['csat_questions']" >
                            <p class="text-l">
                                {{question}}. {{index}}
                            </p>
                            <star-rating :item-size="starSize" v-model="responses[question -1]"></star-rating>
                        </div>
                    </div>
                    <div class="block py-2 my-2 px-1 text-center mb-1 text-white cursor-pointer  text-white" :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }">
                        <div class="block">
                            <button :disabled="beingSubmitted" class="block w-full text-center"
                            @click="submitCSAT()" >
                                {{ $t('submit') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  const AWS_URL = process.env.VUE_APP_S3_ENDPOINT;
  import { mapActions, mapGetters } from 'vuex';
  import * as mutations from '@/store/mutation-types';
  import {StarRating} from 'vue-rate-it';


  export default {
    props:['message'],
    components: {
        StarRating,
    },
    data() {
      return {
        AWS_URL,
        beingSubmitted: false,
        responses:[],
        starSize: 25
      }
    },
    computed: {
        ...mapGetters(['botConfigs', 'formErrorMsg', 'getMessages']),

        fixedMessages() {
            const array = [];
            this.getMessages.forEach((message) => {
                if(!(message[0].type === 'message' && message[0].message && message[0].message.text === '' && message[0].direction === 'to'))
                    array.push(...message);
            });
            return array
        },
    },
    mounted() {
      console.log(this.fixedMessages);
    },
    methods: {
        ...mapActions(["FETCH_BOT_SELECTION", "UPDATE_CSAT", 'SUBMIT_CSAT_FEEDBACK']),
        async closeCSAT() {
            this.UPDATE_CSAT(false);
        },
        submitCSAT() {
            
            this.beingSubmitted = true;
            let score = 0;
            this.responses.map((response, rate) => {
                score += response;
            });
            this.$store.commit(mutations.UPDATE_CSAT, { 
                web_message_id: this.fixedMessages[this.fixedMessages.length - 1].id,
                score: score/this.responses.length,
                responses: this.responses
            });
            this.SUBMIT_CSAT_FEEDBACK();
            this.UPDATE_CSAT(false);
            this.beingSubmitted = false;
        }
    }
}
</script>
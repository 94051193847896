<template>
    <div class="block bg-gray-100 p-3 mx-3 my-1 rounded-3xl shadow-sm chatBox">
        <div class="grid grid-flow-row">
            <div class="grid grid-flow-col justify-between">
                <span class=" font-semibold text-sm "> {{ FAQ.title }}</span>
                <img :src="AWS_URL + '/FAQ-Plus.svg'" class="h-6 w-6 mx-2 cursor-pointer closeButton"
                    :class="{ animation: revealed }" @click.prevent="revealed = !revealed" />
            </div>
            <span v-if="revealed">{{ FAQ.text }}</span>
        </div>
    </div>
</template>

<script>
const AWS_URL = process.env.VUE_APP_S3_ENDPOINT;
export default {
    props: {
        FAQ: {
            type: Object,
            default: () => { },
            required: true
        },
    },
    data() {
        return {
            AWS_URL,
            revealed: false
        }
    },
}
</script>

<style lang="scss" scoped>
.closeButton {
    transform: rotateZ(0deg);
    transition: all 0.5s;
}

.closeButton.animation {
    transform: rotateZ(45deg);
}
</style>

<template>
    <div class="emoji_picker">
        <div class="absolute top-2 right-0 z-10 cursor-pointer" @click.prevent="$emit('closePicker')">
            <svg class="h-4 w-4 mx-2" xmlns="http://www.w3.org/2000/svg" width="16.413" height="17.413" stroke="#000"
                viewBox="0 0 16.413 17.413">
                <g id="line" transform="translate(0.587 1.061)">
                    <line id="Line_7" data-name="Line 7" x2="15" y2="16" transform="translate(0.12 -0.354)"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <line id="Line_8" data-name="Line 8" x1="15" y2="16" transform="translate(0.12 -0.354)"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </g>
            </svg>
        </div>
        <div class="picker_container no-scrollbar mt-1">
            <div class="grid grid-flow-col text-white">
                <vue-dropzone :options="dropzoneOptions" :useCustomSlot="true">
                    <div class="dropzone-custom-content">
                        <div class="grid grid-flow-row gap-4 place-content-center m-1 p-3 w-28 rounded-xl cursor-pointer"
                            :style="{ background: botConfigs.configs['website-chatbot-secondary-color'] }">
                            <img class="h-6 w-6 flex m-auto" :src="AWS_URL + '/File.svg'" />
                            <h2>{{$t('document')}}</h2>
                        </div>
                    </div>
                </vue-dropzone>
                <div class="grid grid-flow-row gap-4 place-content-center m-1 p-3 w-28 rounded-xl cursor-pointer"
                    :style="{ background: botConfigs.configs['website-chatbot-secondary-color'] }">
                    <img class="h-6 w-6 flex m-auto" :src="AWS_URL + '/Image.svg'" />
                    <h2>{{$t('image')}}</h2>
                </div>
            </div>
        </div>
        <div class="bottom_arrow" v-if="show_arrow"></div>
    </div>
</template>

<script>
const AWS_URL = process.env.VUE_APP_S3_ENDPOINT;
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    props:
    {
        show_arrow:
        {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            AWS_URL,
            dropzoneOptions: {
                url: self.baseUrl + "api/v1/converse?fbId=" + self.fbId + "&bot_key=" + self.bot_key + "&payload=''&type=attachment",
                maxFilesize: 25,
                acceptedFiles: self.dropzone.extensions,
                clickable: '.wpic-composer-button-attachment',
                createImageThumbnails: false,
                previewsContainer: null,
                headers: {
                    "Authorization": "Bearer " + self.internal_api_key
                },
            }

        }
    },
}
</script>

<style lang="scss" scoped>
.emoji_picker {
    position: absolute;
    bottom: 8%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    left: 15%;
}

.emoji_picker,
.bottom_arrow {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .0975);
}

.emoji_picker,
.picker_container {
    border-radius: 0.5rem;
    background: white;
}

.picker_container {
    position: relative;
    padding: 1rem;
    overflow: auto;
    z-index: 1;
}

.category {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    color: rgb(169, 169, 169);
}

.emojis_container {
    display: flex;
    flex-wrap: wrap;
}

.category button {
    margin: 0.5rem;
    margin-left: 0;
    background: inherit;
    border: none;
    font-size: 1.75rem;
    padding: 0;
}

.bottom_arrow {
    position: absolute;
    bottom: 0;
    width: 0.75rem;
    height: 0.75rem;
    background: white;
    left: 75%;
    transform: translate(-50%, 50%) rotate(45deg);
}
</style>

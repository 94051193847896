<template>
    <div>
        <div v-if="!avatarDisabled && !messageFull.typing_indicator" class="absolute z-10" style="top:-10px; left:1px">
            <div  :style="'background-image: url(' + avatar + ');'" class="h-7 w-7 rounded-full bg-cover bg-center" style="border:1px solid #EBEFF3;" >
            </div>
        </div>
    
        <div class="block mx-3 my-1 message-animation relative"
            :class="[(messageFull.attachment && 
            (messageFull.attachment.type === 'form' || 
            messageFull.attachment.type === 'inline_conversational_form' || 
            messageFull.attachment.type === 'audio'  || 
            messageFull.attachment.type === 'iframe' ||
            messageFull.attachment.payload.template_type === 'stripe_integration')) ? '' : 'chatBox']"
            :style="{ fontSize: botConfigs.configs['website-chatbot-font-size'] + ' !important' }">
            <div v-if="hideInput && messageFull.attachment && messageFull.attachment.type === 'inline_conversational_form' && isLast">
                <div v-if="isLast">
                    <form id="inline-form" class="p-2 pt-3" style="background: #EBEFF3;" :class="{ 'rounded-md': roundedDisabler }">
                        <input type="hidden" name="render_response" :value="messageFull.rendor_response">
                        <div :key="findex" v-for="(field, findex) in messageFull.form_fields" class="mb-1 ">
                            <span class="block text-sm text-gray-600">{{ field.prompt_phrase }} <span style="color: red;" v-if="field.required">*</span>
                            </span>
                            <input v-if="field.type === 1" :name="'Email#' + field.id"
                                :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-gray-300	"
                                type="email" :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 2" :name="'Number#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" type="number"  placeholder="8 or Eight"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 4" :name="'Text#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" type="text"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 5" :name="'Phone#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" type="text"  :placeholder="$t('phonePlaceholder')"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 6" :name="'Location#' + field.id" class="w-full h-9 border-gray-300	" 
                                :class="{ 'rounded-md': roundedDisabler }" type="text"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 7" :name="'Image#' + field.id" class="w-full h-9 border-gray-300	" type="file"
                                :required="(field.required) ? true : false" accept="image/*" />
                            <input v-else-if="field.type === 8" :name="'DateTime#' + field.id" :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-gray-300" type="datetime-local"
                                :required="(field.required) ? true : false"  />
                            <input v-else-if="field.type === 14" :name="'Date#' + field.id" :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-gray-300"  type="date"
                                :required="(field.required) ? true : false"  />
                            <input v-else-if="field.type === 9" :name="'Currency#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" type="text" :placeholder="$t('currencyPlaceholder')"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 10" :name="'URL#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" type="text"  :placeholder="$t('websiteURLPlaceholder')"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 11" :name="'CustomEntities#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" type="text"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 12" :name="'Geocode#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" type="text" :placeholder="$t('geocodePlaceholder')"
                                :required="(field.required) ? true : false" />
                            <input v-else-if="field.type === 13" :name="'File#' + field.id" class="w-full h-9 border-gray-300	" type="file"
                                :required="(field.required) ? true : false" value="undefined" />
                            
                            <div v-else-if="field.type === 17" class="my-1">
                                <select :name="'MultipleOptions#' + field.id+'[]'" class="hidden w-full h-15 border-gray-300	multi-select"
                                    :class="{ 'rounded-md': roundedDisabler }" id="multiple"
                                    :required="(field.required) ? true : false" multiple>
                                    <option :value="option" v-for="option in JSON.parse(field.options)">
                                        {{ option }}
                                    </option>
                                </select>
                                <span  
                                    class="inline-block pl-3 pr-3 py-1 m-1 text-center text-white  text-sm  cursor-pointer"
                                    :class="[roundedDisabler ? 'rounded-lg' : '',botConfigs.configs['website-chatbot-secondary-color-scheme'] ? ' text-white ' : ' text-white ']"
                                    v-for="option in JSON.parse(field.options)" @click.prevent="selectMultiSelect( field.id,option)"
                                    :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                                    >
                                    {{ option }} <span v-if="multiOptionSelect[field.id] && multiOptionSelect[field.id].includes(option)">✔️</span>
                                </span>
                            </div>

                            <select v-else-if="field.type === 3" :name="'Options#' + field.id" class="w-full h-9 border-gray-300	"
                                :class="{ 'rounded-md': roundedDisabler }" :required="(field.required) ? true : false">
                                <option :value="option" v-for="option in JSON.parse(field.options)">
                                    {{ option }}
                                </option>
                            </select>
                            <span class=" inline-field-error" style="color:red;"></span>
                        </div>
                        <span class="inline-form-error" style="color:red;"></span>
                        <button id="inline-form-submit" :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                            class="block w-full p-1 mb-1 py-2 text-center text-white cursor-pointer"
                            :class="{ 'rounded-lg': roundedDisabler }"
                            @click.prevent="submitInlineForm()">{{ $t('submit') }}</button>
                        <button :style="{ background: botConfigs.configs['website-chatbot-secondary-color'] }"
                            class="block w-full p-1 mb-1 py-2 text-center text-white cursor-pointer"
                            :class="{ 'rounded-lg': roundedDisabler }" @click.prevent="exitInlineForm()"
                            v-if="messageFull.exitable">{{ $t('exit') }}</button>
                    </form>
                </div>
            </div>
            <div v-else-if="messageFull.attachment && messageFull.attachment.type === 'iframe'">
                <iframe class="w-full h-100" :src="messageFull.attachment.payload.url"></iframe>
            </div>
            <div v-else-if="hideInput && messageFull.attachment && messageFull.attachment.type === 'form' && isLast && messageFull.human_message">
                <form id="human-help-form" class="p-2" style="background: #EBEFF3;" :class="{ 'rounded-md': roundedDisabler }">
                    <span>{{$t('fillInInformationBelow')}}.</span>
                    <input type="hidden" name="stop_bot" :value="messageFull.stop_bot">
                    <input type="hidden" name="extra_stop_bot" :value="messageFull.stop_bot">
                    <input type="hidden" name="message" :value="messageFull.human_message">
                    <input type="hidden" name="extra_message" :value="messageFull.human_message">
                    <input type="hidden" name="plugin_email" :value="messageFull.email">
                    <input type="hidden" name="extra_plugin_email" :value="messageFull.email">
                    
                    <div class="mb-2 ">
                        <span class="text-gray-600 text-sm">Full name <span style="color: red;" >*</span>
                        </span>
                        <input name="full_name"
                            :class="{ 'rounded-md': roundedDisabler }" class="w-full mb-2 border-slate-200 h-9"
                            type="text" required="true" :placeholder="$t('enterFullName')" />
                        <span  class="text-gray-600 text-sm">Email <span style="color: red;" >*</span>
                        </span>
                        <input name="email"
                            :class="{ 'rounded-md': roundedDisabler }" class="w-full mb-2 border-slate-200 h-9"
                            type="email" required="true" :placeholder="$t('enterEmailAddress')" />
                        <span class="inline-field-error" style="color:red;"></span>
                    </div>
                    <span class="human-help-error" style="color:red;"></span>
                    <button id="human-help-form-submit" :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                        class="block w-full p-1 mb-1 text-center text-white cursor-pointer"
                        :class="{ 'rounded-lg': roundedDisabler }"
                        @click.prevent="submitHumanHelpForm()">{{ $t('submit') }}</button>
                </form>
            </div>
            
            <div
                v-else-if="messageFull.typing_indicator || 
                messageFull.attachment && messageFull.attachment.type === 'inline_conversational_form' && !isLast ||
                messageFull.attachment && messageFull.attachment.type === 'form' && !isLast">
            </div>
            <div class="flex flex-col w-full h-auto mt-2" v-if="attachments && attachments.type === 'audio'">
                <audio controls>
                    <source :src="attachments.payload.url" type="audio/ogg">
                    <source :src="attachments.payload.url" type="audio/mpeg">
                    {{ $t('browserNotSupportAudio') }}
                </audio>
            </div>
            <div class="flex flex-col w-full h-auto mt-2" v-if="attachments && attachments.type === 'image' && (!attachments.payload.buttons || attachments.payload.buttons.length === 0 )">
                <img :src="attachments.payload.url" style="max-height:250px;height:auto;border:1px solid #ccc"  class="w-64"
                    :class="{ 'rounded-xl': roundedDisabler }" />
            </div>
            <div v-else-if="messageFull.attachment && messageFull.attachment.type === 'form' && isLast">
            </div>
            <div class="flex flex-col w-full h-auto mt-2" v-else-if="attachments && attachments.type === 'template' && attachments.payload.template_type === 'generic'">
                <div>
                    <div class="carousel-slides" :id="'carousel-' + this.getSliderID(messageIndex)">
                        <div class="w3-display-container carousel" :class="{ 'rounded-xl': roundedDisabler }"
                            v-for="(elem, index) in attachments.payload.elements">
                            <div v-bind:style="{ backgroundImage: 'url(' + elem.image_url + ')' }"
                                class="carousel-image  w-full h-64" style="border:1px solid #eee;border-top-right-radius:10px; border-top-left-radius:10px"></div>
                            <div style="background: #EBEFF3;" class="p-1">
                                <span class="break-word block font-bold text-left text-md text-gray-600" >
                                    {{ elem.title }}
                                </span>
                                <span class="break-word block font-normal text-left text-sm text-gray-600" >
                                    {{ elem.subtitle }}
                                </span>
                            </div>
                            <div class="w-full p-1 rounded-b-xl"  v-if="elem.buttons?.length" style="background: #EBEFF3;" >
                                <div v-for="(card, idx) in elem.buttons" :key="idx"
                                    class="block py-2 px-1 text-center mb-1 text-white cursor-pointer"
                                    :class="[(roundedDisabler)?'rounded-lg':'',botConfigs.configs['website-chatbot-primary-color-scheme'] ? ' text-white ' : ' text-white ']" 
                                    :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                                    >
                                    <div class="block">
                                        <button :disabled="sendingPostback" class="block w-full"
                                            v-if="card.type === 'postback'"
                                            @click.prevent="$emit('postback', card.payload, card.title)">
                                            {{ card.title }}
                                        </button>
                                        <a :disabled="sendingPostback" class="block w-full"
                                            v-if="card.type === 'web_url'" target="_blank"
                                            :href="card.url">{{ card.title }}</a>
                                        <a :disabled="sendingPostback" class="block w-full"
                                            v-if="card.type === 'phone_number'" target="_blank"
                                            :href="'tel:'+card.payload">{{ card.title }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="backbutton text-white" :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }" @click="plusDivs(-1, messageIndex)">&#10094;</button>
                        <button class="nextbutton text-white" :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }" @click="plusDivs(1, messageIndex)">&#10095;</button>
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-full h-auto mt-2" v-else-if="attachments && attachments.type === 'template' && botConfigs.configs['stripe_connection'] && attachments.payload.template_type === 'stripe_integration' && attachments.payload.data.dynamic_widget === true">
                <StripeGateway :message="messageFull" v-if="isLast" />
            </div>
            <div class="ml-2" v-else-if="blockRender"
                :class="[roundedDisabler ? avatarDisabled ? ' rounded-br-3xl rounded-tr-3xl' : 'rounded-br-3xl rounded-t-3xl' : '', (attachments && attachments.type === 'video')?'':'p-1 bg-chat-base']">
                <div class="flex" v-if="message && message !== ''">
                    <span class="self-center p-1 font-normal  w-full text-left text-sm text-gray-600">
                        <div class="single-text-message break-words">
                            <vue-markdown :linkify="true" :source="message"></vue-markdown>
                        </div>
                    </span>
                </div>
                <div class="flex flex-col w-full h-auto" v-if="attachments && attachments.type === 'template'">
                    <div v-if="attachments.payload.template_type === 'button'" class="p-1 text-left ml-1 text-sm text-gray-600 overflow-hidden	" >
                        <vue-markdown :linkify="true" :source="attachments.payload.text"></vue-markdown>
                    </div>
                    <div v-else-if="botConfigs.configs['stripe_connection'] && attachments.payload.template_type === 'stripe_integration' && attachments.payload.data.dynamic_widget !== true">
                        <p class="self-center p-1 font-normal w-full text-left text-sm text-gray-600">{{attachments.payload.data.description}}:{{attachments.payload.data.currency?.symbol}}{{attachments.payload.data.amount}}</p>

                        <form v-if="isLast" id="inline-form" class="pt-1" :class="{ 'rounded-md': roundedDisabler }">
                            <input  name="name" class="w-full h-9 border-0	mb-1 placeholder-gray-300"
                                :class="{ 'rounded-md': roundedDisabler }" type="text" placeholder="Name on Card"
                                :required="true"  />
                            <input  name="email"
                                :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-0 mb-1	placeholder-gray-300"
                                type="email" :required="true" placeholder="Email Address for Invoice" />
                            <input  name="phone" class="w-full h-9 border-0 mb-1	placeholder-gray-300"
                                :class="{ 'rounded-md': roundedDisabler }" type="text"  :placeholder="$t('phonePlaceholder')"
                                :required="true" />
                        </form>
                        
                        <stripe-element-card
                            v-if="isLast"
                            ref="stripeElemRef"
                            :hidePostalCode="true"
                            :pk="botConfigs.configs['stripe_connection']"
                            @token="stripeTokenCreated"
                            @error="onStripeError"
                        />
                        <span class=" inline-field-error" style="color:red;" v-if="isLast && formErrorMsg" v-text="formErrorMsg"></span>
                        <div v-if="isLast" class="block py-2 my-2 px-1 text-center mb-1 text-white cursor-pointer  text-white"
                        :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }">
                            <div class="block">
                                <button :disabled="isTyping || beingSubmitted" class="block w-full text-center"
                                    @click.prevent="beingSubmitted = true; $refs.stripeElemRef.submit();">
                                    {{ $t('submit') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="flex flex-col w-full h-auto" v-if="attachments && attachments.type === 'file'">
                    <span class="font-semibold text-gray-600 text-left p-1">{{ $t('openFile') }}</span>
                    <a :href="attachments.payload.url" class="p-1" target="_blank">
                        <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEKElEQVR4nO2b20/TUBzHq3+A8cUXEx980xjfTHwwxkg73HpAcKd1S7ygJuINL6B4CwbQGH3QiIhcFTXe4iUmBsS7RoSIgcQoonLxCmhUQBCdqBs/c5zdjnEbG7Zd1/aTnLDCDofvp7+2p92BYUxMTCLBap03iuWFLBbhBhbhLxwSQK3mHQ83cwhXckhcwc12jmXUxMLjqSwS3qoZOrQQYYDlcZ7VKo5RPHx8gn0Ch3BvtEMHbvidxWafpqgADgm3pAHnOBdB1dWb0N3dA2pw7OQZX9i9+UVQUXUV0tI3/10NPP5uQeIcZcLzjonSQLNmO+BZS6sqwQMJIK8l7tTcA7tzsV8Ej/s5mzhZdgEsElOlQbJyd6saPpQAQkfnW3AuTKUkCA+zs7NHyiuAF7KkAQ4fPam6gBOnz0Oo8VvanoM1yUmfHBfKKwDhnGB7QA0uXbnuC5eVuyvge8rKj9MC6nUl4PWbDqDPQU+b/z0HdXX3/P7Zn/cNxic5xulGAGHD1hyfhGRHClRdufE7NM2a9Vv9VZAgOnQl4E17JyTPTQl/ksSLmboSQCCl71iwNNzJ0Q7dCSB8dbng1NkLsGrdZkjE84PsfaHRkiiO16WAMOiTLbiEKQDFVAX0MkYV4Ha7ofLyNVccEpwMw4wwnICLFZd9J8K4BHuy4QQUlx2lJ0IZpoBoVoBnEKCpG+Bup/cr2Q6X4fYtoiuAx+lRE9D3HSDvAUB6tb+RbfJ9JftqRkBx498BpFbSCIr21YSAD67AAaTW9U2ZvoTC0iP0lHhdVAS87Asd4tVnZfpqRoDrJ0BmTeAAG2sAvrmV6Us4WFJOCRDXRkUA4VZ74BC320HRvpoRQKh7B5BT5/3jc+97t8NluH01JUDCHcH1/3/70gJYHq/RhAA1MbyAguLD9FOh1YargAKtCvAMei9xkbQBt04E/PB4z+ShJjjBWuXLyAQcKDpEXwXSNCHgpyf45GaodrZVBwIIpUFucIZqj7tAHwJaPkUefldDZM8QCPmUAIsNr9KMAMK51vDDk0NmqBufmBPgGQQ48Wzo8JtqIy99n4DCMvoqsFJTAiQa3gPsrP83eEY1QHmT95nAcIkJAQRyaHf0A9S/9z73e/gRoP8H/DcxI0Ap9h8spa8CK0wBRquAvAKqAhLwclOAXLBmBeAYOQRK/B+O8sIyA1ZAibEF7DtQTC2WFFNNAXLBGr4C+Ogulg6XPXmF1CGAlyiyXH7bdvWXy4dLWsYW/0nQhm2yCZiZiCdJv5gsSyfLVrXG7epaau8LAzOSkkYzcsIhoVYaIGXpamhtexHtzNDb2weNTU9h9958iE+cS98JFskansDZ8BRilvr4WaMNP5J970twPLZrXEKF4v9Cx/GOiSwSzrAI90Q9MI/7WYSfcAgfikN4uqLBTUwYXfILp1eHqcCOj0cAAAAASUVORK5CYII=">
                    </a>

                </div>
                <div class="flex flex-col w-full h-auto" v-if="attachments && attachments.type === 'image'">
                    <div v-bind:style="{ backgroundImage: 'url(' + attachments.payload.url + ')' }" style="border:1px solid #ccc"  class="bg-cover bg-center w-64 h-64"
                        :class="{ 'rounded-xl': roundedDisabler }" >
                    </div>
                </div>
                <div class="flex flex-col w-full h-auto mt-2" v-if="attachments && attachments.type === 'video'">
                    <iframe v-if="attachments.payload.url.includes('youtube')" class="w-full h-100" :src="attachments.payload.url">
                    </iframe>
                    <video v-else width="100%" height="auto" class="p-1" style="max-height: 230px; min-width: 230px; border-radius: 10px;"
                        controls>
                        <source :src="attachments.payload.url" type="video/mp4">
                        <source :src="attachments.payload.url" type="video/ogg">
                        {{ $t('browserNotSupportVideo') }}
                    </video>
                </div>
                <div class="flex" 
                    v-if="attachments && attachments.type === 'datepicker'">
                    <span class="self-center font-normal w-full text-left text-sm text-gray-600 p-1" >
                        <div v-if="attachments.payload && attachments.payload.text !== ''" class="single-text-message break-words" >
                            {{ attachments.payload.text }}
                        </div>

                        <input v-if="attachments.payload.template_type === 'datetime' && isLast" @change.prevent="getDatePickerValue($event)"  :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-gray-300" type="datetime-local" />
                        <input v-if="attachments.payload.template_type === 'date' && isLast" @change.prevent="getDatePickerValue($event)"  :class="{ 'rounded-md': roundedDisabler }" class="w-full h-9 border-gray-300"  type="date"  />
                    </span>
                </div>
                <div v-if="attachments && attachments.payload && attachments.payload.buttons"
                    :class="{ 'w-full': (attachments.payload.buttons && attachments.payload.buttons.length > 1) }">
                    <div v-for="(card, idx) in attachments.payload.buttons" :key="idx"
                        class="block p-2 mt-1 text-center cursor-pointer"
                        :class="[(roundedDisabler)?'rounded-lg':'',botConfigs.configs['website-chatbot-secondary-color-scheme'] ? ' text-white ' : ' text-white ']" 
                        :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                        >
                        <div class="block">
                            <button :disabled="sendingPostback" class="block w-full text-center" v-if="card.type === 'postback'"
                                @click.prevent="$emit('postback', card.payload, card.title)">
                                {{ card.title }}
                            </button>
                            <a :disabled="sendingPostback" class="block w-full text-center" v-else-if="card.type === 'phone_number'"
                                :href="'tel:'+card.payload" target="_blank">
                                {{ card.title }}
                            </a>
                            <a :disabled="sendingPostback" class="block w-full  text-center" v-else-if="card.type === 'web_url'"
                                target="_blank" :href="card.url">{{ card.title }}</a>
                        </div>
                    </div>
                </div>
                <div v-if="messageFull.quick_replies && messageFull.quick_replies.length && isLast && messageFull.multiple">
                    <button :key="idx" v-if="qreply.content_type === 'text'"
                        class="inline pl-3 pr-3 py-1 m-1 text-center text-white  text-sm  cursor-pointer"
                        :class="[roundedDisabler ? 'rounded-lg' : '',botConfigs.configs['website-chatbot-secondary-color-scheme'] ? ' text-white ' : ' text-white ']"
                        v-for="(qreply, idx) in messageFull.quick_replies" @click.prevent="selectQuickReply(qreply)"
                        :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                        >
                        {{ (qreply.payload=='SKIP_BOTSIFY_FORM_FIELD') ? $t('skip') : (qreply.payload=='EXIT_BOTSIFY_FORM') ? $t('exit') : qreply.title }} <span v-if="multipleSelect.includes(qreply.title)">✔️</span>
                    </button>
                    <button class="inline pl-3  py-1 pr-3 m-1 text-center text-white text-sm  cursor-pointer"
                        :class="{ 'rounded-lg': roundedDisabler }"
                        :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                        @click.prevent="sendQuickReply(multipleSelect.join(','))">
                        {{ $t('submit') }}
                    </button>
                </div>
                <div
                    v-if="messageFull.quick_replies && messageFull.quick_replies.length && isLast && !messageFull.multiple">
                    <button :key="idx" v-if="qreply.content_type === 'text'"
                        class="inline pl-2 pr-2  py-1 m-1 text-left text-white cursor-pointer text-sm"
                        :class="[(roundedDisabler)?'rounded-lg':'',botConfigs.configs['website-chatbot-secondary-color-scheme'] ? ' text-white ' : ' text-white ']" 
                        v-for="(qreply, idx) in messageFull.quick_replies"
                        :style="{ background: botConfigs.configs['website-chatbot-primary-color'] }"
                        @click.prevent="sendQuickReply(qreply)">
                        {{ (qreply.payload=='SKIP_BOTSIFY_FORM_FIELD') ? $t('skip') : (qreply.payload=='EXIT_BOTSIFY_FORM') ? $t('exit') : qreply.title }}
                    </button>
                </div>
                <div v-if="!messageFull.processingForm && !isTyping && isLast && !csatSubmitted && message && message !== '' && botConfigs.configs['csat-settings-enabled'] == 1" >
                    <img @click.prevent="csat('down', messageFull)" class="cursor-pointer inline w-4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeklEQVR4nO2ZW4hNYRTHf8ckl4OYBhnNA1EiMSWXEPGqpMwTXkQeqPEml1cP8kAUNcqUFw/yQim5TJEHiqLcokQujftlNHIbffXf+prO2bvjrL3P/rJ/9dXp7NX6f2vv9a2z9jpQUFBQEMNKoAu4D/QBA4arD7gKrANKpMQ0oMd443GrO40gFgNvJdAL7AHmAmVjnUnAFuCjtNxn0ycRBXEKGE36rJXeY0unPV4QqeXtIEZKsx/Dgx2lUxZPImK+dF1BMaFLDt2ZyJLj0r0FTLRw+EAO55At64Gv0n4CTK7X4Wc5yzKtItqAO1alOKrpjWKW9J+FHsh46X8JOZASsF/6l0MO5Iy0vwGLQg7ktle1mkMOpAW4Kf1DoR/2BdJ/Hnogzd57StCBbJP+jXod/ZSjJrJlGHAE+CX9jnod9spRK9my1EupTRYOL1jdkRqZLV3XtJqwXQ4vki3jrA65XzU+yOkasmO6ND9ZOt0qp681HPhXyrrTcasNWA3ck+YJ6+btnJdiQxLsNwDfDcZBd4EJGOMcvpLAzgTbzoQB3PuY9RK4BuxIYdT0l+Wq6z8SutExyu3fwExyyj7d2afK6WoclN0xcspQ4Lo2eTLGboq6gv408tyKqUodF8zGGLvTstlNjunwDu+MKjbLZPNCTzK3dGujbmQzPOEtL+sWpybKGmkO6HBXYrOuXyHntGs44ErtqirD6HcNmlbWTKfXwrTGlOLD5JwScFabvVShhZmnaw8JgBZVJ7fhXYOujbL+ryNtVngtzELv+yVp/PuUNnu934529VrRRN2NP4OhCThfoet9lNCb5ZIRwAHgjZ7MUWBsozdVUPA/8wdFzgR0YTyMcAAAAABJRU5ErkJggg==">
                    <img @click.prevent="csat('up', messageFull)" class="cursor-pointer inline w-4" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChUlEQVR4nO2ZTYhOURjHf0MThqF3ehuNogwLRA0Ln6kxaykLspiVBQsKS8LOQiiKwmIUC6uxoCzMjJSFpKZMYaYxChn5DEO+BtOp/1untzv3feuee95zdX91Nvec/s/z3HvPOc95DuTk5NSCGcBp4D0wCpwHCmSMqcAt4F9ZGwaayBDH5PgroA1YCjzUs5NkhA7gD/AbWGs936BARsgARX0F4/Chsr5Zev6dwKkDbsjZPmBKWX+b+oYInH1y9C0wL6L/lPrPEjArgR/AX2BzRH8D8EGBrCJQZgJP5KTZN6LYpf67BMwlOTkATJ9kTGnp3U6gbJODX4Elk4xpt/aUegKkFfgsJ3fGjOvWmMMESD1wXw5ejRm3EBjX3tFMgBxXEM8rJIJnNO4iAdJupSDrYsbN1q9nluRlBEYz8Fpv+WCVG2RUM4vDx5g2CtxTmmNSG+cpyE050huRgpTTCfyKCaba9hiY6zKQPVYK0pJwAy1UaPOBLcAj2bziKghzGPok0a34Y7FsmrnmhAMS7MEvBWtOOaFHgmYn98kK2R10JfhGglHpeZpstL7IbheC4xI0RQWfTAPOWfYTJ52lpbBW7JX9B1kPpMnVpK91IGtk/2WWAykC/bJvktDMBjIg289clFxrGch12f5ZIdsOPpA64ITs3876ZC/+L6vWctl/kVToi4Qa8c8Ca8J3JRUblJCp3/qkE/hmrVqJc70LEjuCX7pkt99VFabDOh2aooIvVsuuKck6o0+i3VWc113RkMadyiJdbBrha8Ac/JVkn7oWXm8F8w44qmsC1yWbFh2kSiXZ/aRU8+11UOaptl1O+1fepLtzU3cac+z8GHAH2JFmADk5OWSTCQIHBIvH/7mJAAAAAElFTkSuQmCC">
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { i18n } from '@/helpers/i18n-setup';
import * as mutations from '@/store/mutation-types';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import StripeGateway from "../StripeGateway.vue";
import VueMarkdown from 'vue-markdown';
import { botConfigs } from "@/store/getters";


export default {
    components: {
        StripeElementCard,
        StripeGateway,
        VueMarkdown,
    },
    props: {
        message: {
            type: String,
            required: true,
            default: ""
        },
        messageIndex: {
            type: Number,
            required: true,
            default: 0,
        },
        messageFull: {
            type: Object,
            required: true,
            default: () => { }
        },
        attachments: {
            type: Object,
            required: false,
            default: () => { }
        },
        isLast: {
            type: Boolean,
            required: true,
            default: false,
        },
        avatarDisabled: {
            required: false,
            type: Boolean,
            default: false
        },
        avatar: {
            required: false,
            type: String,
            default: ""
        },
        sendingPostback: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            beingSubmitted: false,
            currentDate:this.convertDateFormat(),
            currentTime:this.convertDateTimeFormat(),
            multiOptionSelect: {},
            multipleSelect: [],
            slideIndex: 1,
        }
    },
    computed: {
        ...mapGetters(['isTyping', 'formErrorMsg', 'csatSubmitted']),
        blockRender() {
            let a = ((this.messageFull && this.messageFull.quick_replies && this.messageFull.quick_replies.length && this.isLast) || (this.attachments && this.attachments.payload && this.attachments.payload.buttons) || (this.attachments && this.attachments.type === 'datepicker') || (this.attachments && this.attachments.type === 'video') || (this.attachments && this.attachments.type === 'file') || (this.attachments && this.attachments.type === 'template') || (this.attachments && this.attachments.type === 'image') || (this.message && this.message !== ''))
            return a;
        },
        hideInput() {
            const inlineForm = (this.messageFull.attachment && this.messageFull.attachment.type === 'inline_conversational_form' && this.isLast) ? true : false;
            const humanHelpForm = (this.messageFull.attachment && this.messageFull.attachment.type === 'form' && this.isLast && this.messageFull.human_message) ? true : false;
            const typingAreaField = document.getElementById('bcw-typing-area');
            if((inlineForm === true || humanHelpForm === true) && typingAreaField) {
                typingAreaField.classList.add("hidden");
            } else if(typingAreaField) {
                typingAreaField.classList.remove("hidden");
            }
            return true;
        },
    },
    methods: {
        ...mapActions(["SET_SENDER_MESSAGE", "SEND_MESSAGE", "SEND_POSTBACK", "INLINE_FORM_UPLOAD", "UPDATE_MESSENGER_USER", "STRIPE_CHARGE", "UPDATE_CSAT"]),
        csat(feedback) {
            this.$store.commit(mutations.RESET_CSAT);
            this.$store.commit(mutations.UPDATE_CSAT, { feedback });
            this.UPDATE_CSAT(true);
        },
        convertDateFormat() {
            var today = new Date();
            return today.toISOString().slice(0, 10);
        },
        convertDateTimeFormat() {
            var today = new Date();
            // console.log('today.toISOString()', today.toISOString().slice(0, 16).replace('T',''));
            return today.toISOString().slice(0, 16).replace('T',' ');
        },
        sendQuickReply(qreply) {
            if (this.multipleSelect.length) {
                const multiText = this.multipleSelect.join(',');
                this.SET_SENDER_MESSAGE(multiText);
                this.SEND_MESSAGE({ 'text': multiText, 'payload': 'SUBMIT_MULTIPLE_VALUES' });
                this.multipleSelect = [];
            } else {
                const titleQR = (qreply.payload=='SKIP_BOTSIFY_FORM_FIELD') ? i18n.t('skip') : (qreply.payload=='EXIT_BOTSIFY_FORM') ? i18n.t('exit') : qreply.title;
                this.SET_SENDER_MESSAGE(titleQR);
                this.SEND_MESSAGE({ 'text': titleQR, 'payload': qreply.payload });
            }
        },
        convertLinksInTextToAnchorLinks(msgText) {
            let text = String(msgText);
            var urlRegex = /(www\.[^\s]+|https?:\/\/[^\s]+)/g;
            // Replace URLs with anchor tags
            const changedText = text.replace(urlRegex, function(url) {
                const urlInsider = url.replace(/^(?!https?:\/\/)(?=.)/, 'https://').replace(/^www\./, 'https://www.');
                return '<a target="_blank" href="' + urlInsider + '">' + url + '</a>';
            });
            // return changedText;
            return changedText.replace(/\n/g, "<br />");
        },
        selectMultiSelect(formKey, formVal){
            for (const option of document.querySelector(`select[name="MultipleOptions#${formKey}[]"]`)) {
                if(this.multiOptionSelect[formKey] === undefined) {
                    this.$set(this.multiOptionSelect, formKey, []);
                }
                if ([formVal].indexOf(option.value) !== -1) {
                    if(option.selected === true) {
                        option.removeAttribute('selected');
                        for (var i = this.multiOptionSelect[formKey].length - 1; i >= 0; i--) {
                            if (this.multiOptionSelect[formKey][i] === option.value) {
                                this.multiOptionSelect[formKey].splice(i, 1);
                            }
                        }
                    } else {
                        option.setAttribute('selected', 'selected');
                        this.multiOptionSelect[formKey].push(option.value);
                    }
                }
            }
        },
        selectQuickReply(qreply) {
            if (qreply.title === 'SKIP' || qreply.title === 'EXIT') { 
                this.sendQuickReply(qreply);
            } else {
                if(!this.multipleSelect.includes(qreply.title)) {
                    this.multipleSelect.push(qreply.title);
                } else {
                    for (var i = this.multipleSelect.length - 1; i >= 0; i--) {
                        if (this.multipleSelect[i] === qreply.title) {
                            this.multipleSelect.splice(i, 1);
                        }
                    }
                }
            }
        },
        getDatePickerValue(event) {
            if(event) {
                this.$emit('messageEvent', event.target.value.replace("T", " "));
            } else {
                // alert("Select date or time first then click OK");
            }
        },
        submitInlineForm() {
            var form = document.getElementById('inline-form');
            var data = new FormData(form);
            var error = false;
            for (const pair of data.entries()) {
                const inputElem = document.getElementById("inline-form").elements[`${pair[0]}`];
                if (inputElem.required && (pair[1] === '' || (inputElem.type === 'file' && inputElem.files.length === 0))) {
                    error = true;
                    document.getElementById("inline-form").elements[`${pair[0]}`].nextElementSibling.innerHTML = 'Field is required.';
                }
            }
            data.forEach(function(value, key){
                if(value instanceof File && value.name === '') {
                    data.append(key, undefined);
                }
            });
            if (!error) {
                this.INLINE_FORM_UPLOAD(data);
                document.getElementById('bcw-typing-area').classList.remove("hidden");
            }
            
        },
        exitInlineForm() {
            this.SET_SENDER_MESSAGE(i18n.t('exit'));
            this.SEND_MESSAGE({ 'text': i18n.t('exit'), 'payload': 'EXIT_BOTSIFY_FORM' });
            document.getElementById('bcw-typing-area').classList.remove("hidden");
        },
        submitHumanHelpForm() {
            var form = document.getElementById('human-help-form');
            var data = new FormData(form);
            var error = false;
            for (const pair of data.entries()) {
                const inputElem = document.getElementById("human-help-form").elements[`${pair[0]}`];
                if (inputElem.required && (pair[1] === '' || (inputElem.type === 'file' && inputElem.files.length === 0))) {
                    error = true;
                    document.getElementById("inline-form").elements[`${pair[0]}`].nextElementSibling.innerHTML = 'Field is required.';
                }
            }
            var formData = {};
            data.forEach(function(value, key){
                formData[key] = value;
                if(key === 'email') {
                    formData['extra_email'] = value;
                } else if(key === 'full_name') {
                    formData['extra_full_name'] = value;
                }
            });
            if (!error) {
                this.UPDATE_MESSENGER_USER(formData);
                document.getElementById('bcw-typing-area').classList.remove("hidden");
            }  
        },
        plusDivs(n, messageFull) {
            const btStr = this.getSliderID(messageFull);
            this.showDivs(this.slideIndex += n, btStr);
        },
        showDivs(n, btStr) {
            var i;
            var x = document.querySelectorAll("#carousel-" + btStr + " .carousel");
            if (n > x.length) { this.slideIndex = 1 }
            if (n < 1) { this.slideIndex = x.length }
            for (i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
            x[this.slideIndex - 1].style.display = "block";
        },
        getSliderID(messageFull) {
            const btStr = messageFull;
            return btStr;
        },
        async stripeTokenCreated(token) {
            this.beingSubmitted = true;
            this.$store.commit(mutations.SET_FORM_ERROR_MSG, null);
            var form = document.getElementById('inline-form');
            var formData = new FormData(form);
            for (const pair of formData.entries()) {
                token[pair[0]] = pair[1];
            }
            await this.STRIPE_CHARGE({'token': { ...token, ...this.messageFull.attachment.payload.data,'bot_name': this.botConfigs.configs['website-chatbot-bot-name'], 'stripe_hook': this.botConfigs.configs['stripe_webhook']??'', 'stripe_connection': this.botConfigs.configs['stripe_connection']??''} }, 'stripe-charge');
            this.beingSubmitted = false;
        },
        onStripeError() {
            this.beingSubmitted = false;
        }
    }
}
</script>
<style scopped>
.bcw5611 .single-text-message a {
  text-decoration: underline !important;
  cursor:pointer !important;
  color:#0000EE !important; 
}
input.v-drumroll-picker__input{
    width: 100%;
    background: transparent !important;
    box-shadow: none !important;
    position: absolute !important;
    top: 0px !important;
    left: 5px !important;
}
input.v-drumroll-picker__input:focus, input.v-drumroll-picker__input:hover{
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}
</style>
<template>
  <div v-if="chatBotLoaded" class="bcw5611" style="font-family: 'Open Sans';" >
    <Launcher v-if="!inlineTarget" />
    <Selection />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Selection from './components/chat/Selection.vue';
import Launcher from './components/Launcher.vue';

export default {
  data() {
    return {
      ip: "",
      fbId: undefined
    };
  },
  components: {
    Launcher,
    Selection
  },
  computed: {
    ...mapGetters(['isMobile', 'permission', 'botConfigs', 'chatBotLoaded' , 'getFbId', 'getPusherChannel', 'inlineTarget']),
    botConfigLoaded() {
      return Object.keys(this.$store.getters.botConfigs).length
    },
  }
};
</script>